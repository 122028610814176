import { useCuratedSetsByCategoryId } from '@bpm-web-app/swr-hooks';
import { useContext, useEffect, useMemo } from 'react';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import { CardCarousel, GhostComponent } from '../shared';
import { CuratedSetCard } from '../shared/card/curated-set-card/curated-set-card';

export interface CuratedSetBrowseBrowseProps {
    categoryId: number
    isDataLoaded?: boolean;
    title?: string;
    setIsDataLoaded?: (loaded: boolean) => void;
}

export function CuratedSetBrowse({ setIsDataLoaded, isDataLoaded, categoryId, title = 'Collections' }: CuratedSetBrowseBrowseProps) {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { data: curatedSets, isLoading } = useCuratedSetsByCategoryId(categoryId, libraryProperty);

    useEffect(() => {
        if (setIsDataLoaded) {
            if (isLoading === false) {
                setIsDataLoaded(true);
            } else {
                setIsDataLoaded(false);
            }
        }
    }, [isLoading, setIsDataLoaded]);

    const shouldDisplayGhostLoading = useMemo(() => isDataLoaded === false || isLoading, [isDataLoaded, isLoading]);

    return (
        <div>
            {shouldDisplayGhostLoading ? (
                <GhostComponent type="cards" title={title} elementsCount={8} contentType="curatedSet" />
            ) : (
                <CardCarousel title={curatedSets?.[0]?.category?.name || title} seeMorePath={`/curated-sets/category/${categoryId}`}>
                    {curatedSets &&
                        curatedSets.map((set) => (
                            <CuratedSetCard
                                key={set.id}
                                curatedSet={set}
                                curatedSets={curatedSets}
                            />
                        ))}
                </CardCarousel>
            )}
        </div>
    );
}
