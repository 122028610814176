import { useEffect, useMemo, useState } from 'react';
import {
    HLSPlayer,
    PlayerState,
    PLAYER_PREVIEW_SECONDS,
    showToast,
    State as PlayerStateEnum,
    useHubSwitch,
    useUserSettings,
    getSignupRedirectLink,
    getPlatformLinkUsingRouter,
} from '@bpm-web-app/utils';
import { User } from '@bpm-web-app/supreme-api-sdk';
import { usePlayer, usePlayerDuration } from '../player-context';
import { useSession } from '../shared';
import { useKeyboardsShortcuts } from './useKeyboardShortcuts';

export const ElapsedTimeWatcher = () => {
    const { isDownload } = useHubSwitch();
    const { isAnonymous } = useUserSettings();
    const [playerState, setPlayerState] = useState<PlayerStateEnum>(
        PlayerState.STATE,
    );
    const {
        volume,
        hasNextTrack,
        onTrackEnd,
        setElapsed,
        setStartOffset,
        currentTrack,
        useSdk,
        nextTrack,
        isPreviewTrack,
        currentDuration,
    } = usePlayer();
    const { userMemberships, platformUser } = useSession();
    const { elapsed } = usePlayerDuration();

    useEffect(() => {
        if (!isPreviewTrack) return;

        if (elapsed >= (currentDuration || PLAYER_PREVIEW_SECONDS)) {
            const hasSubscription = isDownload
                ? userMemberships.download?.has_membership
                : userMemberships.stream?.has_membership;
            let message: string;

            if (hasSubscription) {
                // User is signed in and has an active subscription
                message = 'Preview limit reached. Download for full version.';
            } else if (
                isDownload &&
                platformUser.download?.verification_status !==
                User.VerificationStatusEnum.Verified
            ) {
                // User is not signed in
                message = 'Get full access. Request a membership now.';
            } else {
                // User is signed in and does not have a subscription
                message = 'Keep listening. Subscribe now.';
            }

            showToast({
                type: 'error',
                title: message,
                buttonText: hasSubscription ? undefined : 'View Plans',
                routeOnButtonClick: hasSubscription
                    ? undefined
                    : getPlatformLinkUsingRouter('/account/plan'),
            });

            nextTrack();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elapsed, currentDuration]);

    useEffect(() => {
        PlayerState.onPlayerStateChange(setPlayerState);
        PlayerState.onProgress(setElapsed);
        PlayerState.onStartOffset(setStartOffset);
        return () => {
            PlayerState.offPlayerStateChange(setPlayerState);
            PlayerState.offProgress(setElapsed);
            PlayerState.offStartOffset(setStartOffset);
        };
    }, [setElapsed, setStartOffset]);

    useEffect(() => {
        if (playerState === PlayerStateEnum.Ended && hasNextTrack) onTrackEnd();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerState]);

    const streamUrl = useMemo<string>(() => {
        const type = currentTrack?.stream_info?.audioInfo?.type;
        const isHQ = true;
        const quality = isHQ ? 'hq' : 'lq';
        return currentTrack?.stream_info?.audioInfo?.[quality][type];
    }, [currentTrack]);

    useEffect(() => {
        if (!currentTrack) return;
        if (playerState === PlayerStateEnum.Playing) {
            HLSPlayer.stop();
        }

        HLSPlayer.load(streamUrl);
        HLSPlayer.play();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTrack, useSdk]);

    useEffect(() => {
        if (!currentTrack) return;
        HLSPlayer.setVolume(volume / 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [volume]);

    useKeyboardsShortcuts({ playerState });

    return null;
};
