import { PlayerState, State as PlayerStateEnum } from '@bpm-web-app/utils';
import { useCallback, useEffect } from 'react';
import { usePlayer } from '../player-context';

export const useKeyboardsShortcuts = ({ playerState }: { playerState: PlayerState }) => {
    const { onPlay, onPause, hasNextTrack, hasPrevTrack, nextTrack, prevTrack, currentTrack, moveBy } = usePlayer();

    const moveForward = useCallback(() => {
        moveBy(5);
    }, [moveBy]);

    const moveBackward = useCallback(() => {
        moveBy(-5);
    }, [moveBy]);

    const handleKeydown = useCallback(
        (e: KeyboardEvent) => {
            if (!currentTrack) return;

            const { key } = e;

            if (key === 'ArrowRight') {
                e.preventDefault();
                moveForward();
            }

            if (key === 'ArrowLeft') {
                e.preventDefault();
                moveBackward();
            }

            if (key === 'ArrowRight' && e.shiftKey && hasNextTrack) {
                e.preventDefault();
                nextTrack();
            }

            if (key === 'ArrowLeft' && e.shiftKey && hasPrevTrack) {
                e.preventDefault();
                prevTrack();
            }

            if (key === ' ') {
                // If the search input is focused, don't fire the play/pause action
                if (document.activeElement?.tagName === 'INPUT') {
                    return;
                }
                if (playerState === PlayerStateEnum.Playing) {
                    // Should this be controlled at context level?
                    // Should be the same for Maxi and mini
                    onPause();
                } else {
                    onPlay();
                }
                e.preventDefault();
            }
        },
        [currentTrack, hasNextTrack, hasPrevTrack, moveForward, moveBackward, nextTrack, prevTrack, playerState, onPause, onPlay]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeydown);
        return () => {
            window.removeEventListener('keydown', handleKeydown);
        };
    }, [handleKeydown]);
};
