import { Playlist } from '@bpm-web-app/download-api-sdk';
import { appendQueryParams } from '@bpm-web-app/utils';
import Card from '../card';
import CardGhostLoading from '../card-ghost-loading';
import { ForYouCommonData } from '../../../for-you/for-you-common-data';

interface ForYouCardProps {
    playlists?: ForYouCommonData[];
    playlist?: ForYouCommonData;
    isGhost?: boolean;
    onClick?: () => void;
}

export function ForYouCard({ playlist, playlists, isGhost, onClick }: ForYouCardProps) {
    if (isGhost) {
        return (
            <CardGhostLoading contentType="for-you-playlist" />
        );
    }

    return playlist ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div key={playlist.id} id={playlist.id} onClick={playlist.available ? undefined : onClick}>
            <Card
                cardSize="small"
                contentType="for-you-playlist"
                id={playlist.id}
                title={playlist.title}
                imageUrl={appendQueryParams(playlist.image_url, { key: 'dw', value: 160 })}
                imageUrl2x={appendQueryParams(playlist.image_url, { key: 'dw', value: 320 })}
                isPlayable={playlist.available}
                link={playlist.available ? `/for-you/${playlist.id}` : ''}
                descriptionProps={playlist.artist && playlist.artists ? { artist: playlist.artist, artists: playlist.artists } : undefined}
                currentPlaylist={(playlists as never as Playlist[])}
            />
        </div>
    ) : null;
}
