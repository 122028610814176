import { useCuratedSetsByCurator } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import { useHubSwitch } from '@bpm-web-app/utils';
import { PageNotFound } from '@bpm-web-app/components/pages';
import CardGrid from '../shared/card-grid/card-grid';
import SeeMoreMobile from '../see-more-mobile/see-more-mobile';
import SeeMoreSupremeLayout from '../see-more-layout/see-more-supreme-layout';
import Title from '../title/title';
import { CuratedSetCard } from '../shared/card/curated-set-card/curated-set-card';

export function CuratedSetsCurator() {
    const {
        query: { curatorName },
    } = useRouter();
    const { isDownload, hub } = useHubSwitch();

    if (!curatorName) return <PageNotFound />;

    const { data: curatorCuratedSetsData, isLoading: isCuratedSetsFromCuratorLoading } = useCuratedSetsByCurator(curatorName.toString());
    const curatorCuratedSets = curatorCuratedSetsData?.data;

    // TODO: Do you have a loading indicator/component?
    if (isCuratedSetsFromCuratorLoading || !curatorCuratedSets?.length) return <div />;

    return (
        <>
            <Title platform={hub} title="Curated Sets" />
            <SeeMoreSupremeLayout
                pageTitle={`More Curated Sets from ${curatorCuratedSets[0]?.curator}`}
                count={curatorCuratedSets?.length || 0}
                mobileChildren={<SeeMoreMobile isDownload={isDownload} data={curatorCuratedSets} contentType="curatedSet" isPlayable linkPrefix="/curated-set" />}
                desktopChildren={
                    <CardGrid cardType="card-small">
                        {curatorCuratedSets.map((set) => (
                            <CuratedSetCard
                                key={set.id}
                                curatedSet={set}
                                curatedSets={curatorCuratedSets} />
                        ))}
                    </CardGrid>

                }
            />
        </>
    );
}

export default CuratedSetsCurator;
