// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/dot-notation */
import router from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { UserPlaylistCategory, UserPlaylistWithUser } from '@bpm-web-app/download-api-sdk';
import { useUserPlaylistSets } from '@bpm-web-app/swr-hooks';
import { Analytics, getCurrentPlatformLink, useHubSwitch } from '@bpm-web-app/utils';
import { useUserPlaylistCategories } from '../shared/three-dots-sheet/useUserPlaylistCategories';
import { CustomIcon } from '../shared/custom-icon/custom-icon';
// eslint-disable-next-line import/no-cycle
import NavMyPlaylistListItem from '../nav-my-playlist-list/nav-my-playlist-list-item';
import { ContextMenuContext, ContextMenuItemProps } from '../shared';
import NavMyPlaylistsCategory from './nav-my-playlist-category';
import { LOCAL_SORT_OPTIONS, LocalSearchSort } from './utils';
import { NavContext } from '../nav/nav.context';
import Droppable, { ALL_TYPES_NO_ARTIST } from '../droppable/droppable';
import styles from './nav-my-playlist-categories.module.css';

export interface NavMyPlaylistsCategoriesProps {
    selectCategory: (id: number | null) => void;
    createNewPlaylist: (categoryId: number | null) => void;
    sort: LocalSearchSort | undefined;
    onUpdateCategory: (id: number, currentName: string) => void;
    onDeleteCategory: (id: number) => void;
    isOver?: boolean
}

export function NavMyPlaylistsCategories({ selectCategory, createNewPlaylist, sort, onUpdateCategory, onDeleteCategory, isOver }: NavMyPlaylistsCategoriesProps) {
    const { userPlaylistCategories } = useUserPlaylistCategories(true);
    const { isDownload } = useHubSwitch();
    const { data: playlists } = useUserPlaylistSets(isDownload);
    const [playlistSort, setPlaylistSort] = useState<LocalSearchSort>('most_recent');

    const [userFolders, setUserFolders] = useState<UserPlaylistCategory[]>([]);

    const [showPlaylist, setShowPlaylist] = useState(false);
    const [currentFolder, setCurrentFolder] = useState<number | null>();
    const [isFolderOpened, setFolderOpened] = useState(false);
    const [nestedPlaylistIds, setNestedPlaylistIds] = useState<string[]>([]);
    const [folderWithPlaylists, setFolderWithPlaylists] = useState<{ [folderId: string]: string[] }>({});
    const { isNavOpen, openNav } = useContext(NavContext);

    useEffect(() => {
        if (userPlaylistCategories && userPlaylistCategories.data) {
            setUserFolders(userPlaylistCategories.data);
        }
    }, [userPlaylistCategories]);

    const userPlaylistCategoriesList = useMemo(() => {
        if (sort) {
            switch (sort) {
                case 'most_recent':
                    return userFolders.sort((a, b) => {
                        if (a.slug === 'shared') {
                            return -1;
                        }
                        if (b.slug === 'shared') {
                            return 1;
                        }
                        return a.created_at < b.created_at ? 1 : -1;
                    });
                case 'title_asc':
                    return userFolders.sort((a, b) => {
                        if (a.slug === 'shared') {
                            return -1;
                        }
                        if (b.slug === 'shared') {
                            return 1;
                        }
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                    });
                case 'title_desc':
                    return userFolders.sort((a, b) => {
                        if (a.slug === 'shared') {
                            return -1;
                        }
                        if (b.slug === 'shared') {
                            return 1;
                        }
                        return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
                    });
                default:
                    return userFolders;
            }
        }

        return userFolders;
    }, [sort, userFolders]);

    const folderContainsActivePlaylist = useCallback(
        (item: UserPlaylistCategory) => {
            // Check if folder is currently the route.
            if (router.query['categoryId'] === `${item.id}`) {
                return true;
            }

            // Check if playlist in folder is currently the route.
            if (router.query['playlistId'] && folderWithPlaylists && folderWithPlaylists[item.id]) {
                if (folderWithPlaylists[item.id].indexOf(router.query['playlistId'] as string) !== -1) return true;
            }
            return false;
        },
        [folderWithPlaylists]
    );
    const handleShowSubPlaylistFolders = useCallback(() => {
        if (!isNavOpen) openNav();
        setShowPlaylist(true);
    }, [isNavOpen, openNav, setShowPlaylist]);

    const handleOpenAndCloseFolder = useCallback(
        (id: number, onlyOpen = false) => {
            if (isFolderOpened && currentFolder === id && !onlyOpen) {
                setFolderOpened(false);
                setCurrentFolder(null);
                setShowPlaylist(false);
                selectCategory(null);
            } else {
                setFolderOpened(true);
                setShowPlaylist(true);
                setCurrentFolder(id);
                selectCategory(id);
            }
        },
        [isFolderOpened, currentFolder, selectCategory]
    );

    const { openContextMenu, closeOptions, isOpen } = useContext(ContextMenuContext);

    const contextMenuOptions = useCallback(
        (item: UserPlaylistCategory) => {
            const menuOptions: ContextMenuItemProps[] = [
                {
                    leftIcon: <CustomIcon type="new-window-icon" hasIconHover />,
                    label: 'Go to Folder',
                    onClick: () => {
                        closeOptions();
                        router.push(getCurrentPlatformLink(`/my-playlists/${item.id}`));
                    },
                },
                {
                    label: 'Create Playlist',
                    leftIcon: <CustomIcon type="add-circle-icon" hasIconHover />,
                    onClick: () => {
                        closeOptions();
                        Analytics.trackClick('nav_bar', 'create_new_playlist', { location: 'nav_my_playlist' });
                        if (createNewPlaylist) createNewPlaylist(item.id);
                    },
                },
                {
                    label: 'Edit Folder Name',
                    leftIcon: <CustomIcon type="edit-pencil-icon" hasIconHover />,
                    onClick: () => {
                        closeOptions();
                        if (onUpdateCategory) onUpdateCategory(item.id, item.name);
                    },
                },
                {
                    label: 'Delete Folder',
                    leftIcon: <CustomIcon type="delete-filled-icon" hasIconHover color="delete" />,
                    onClick: () => {
                        closeOptions();
                        if (onDeleteCategory) onDeleteCategory(item.id);
                    },
                    type: 'delete-item',
                },
                {
                    label: 'Sort By…',
                    leftIcon: <CustomIcon type="sort-icon" hasIconHover />,
                    items: LOCAL_SORT_OPTIONS.map((option) => {
                        return {
                            label: option.label,
                            onClick: () => {
                                closeOptions();
                                setPlaylistSort(option.actionType);
                                handleOpenAndCloseFolder(item.id, true);
                            },
                        };
                    }),
                },
            ];
            return item.slug === 'shared' ? [menuOptions[0], menuOptions[menuOptions.length - 1]] : menuOptions;
        },
        [closeOptions, createNewPlaylist, onUpdateCategory, onDeleteCategory, handleOpenAndCloseFolder]
    );

    const handleOpenThreeDots = (e: React.MouseEvent<HTMLElement>, item: UserPlaylistCategory) => {
        const { left, top, width } = e.currentTarget.getBoundingClientRect();
        if (isOpen(item.id)) {
            closeOptions();
        } else {
            openContextMenu({ id: item.id, options: contextMenuOptions(item), left, top, renderLocationPosition: 'sidebar', align: 'right', buttonWidth: width });
        }
    };
    const isActive = useCallback((id: string | number) => isFolderOpened && currentFolder === id, [currentFolder, isFolderOpened]);

    const onUpdatePlaylistsPerCategory = useCallback(
        (folderId: number, playlistIds: string[]) => {
            let removedPlaylistIds: string[] = [];

            const tempCopyOfDict = folderWithPlaylists;
            const currentPlaylistIds = tempCopyOfDict[`${folderId}`];
            if (currentPlaylistIds?.length > playlistIds.length) {
                removedPlaylistIds = currentPlaylistIds.filter((id) => !playlistIds.includes(id));
            }
            tempCopyOfDict[`${folderId}`] = playlistIds;
            setFolderWithPlaylists(tempCopyOfDict);
            setNestedPlaylistIds((s) => [...s.filter((id) => !removedPlaylistIds.includes(id)), ...playlistIds]);
        },
        [folderWithPlaylists]
    );

    const rootLevelPlaylists = useMemo(() => {
        if (!playlists?.data) return [];
        const noFolder = playlists.data.map((playlist) => !nestedPlaylistIds.includes(playlist.id) ? playlist : null).filter((playlist) => playlist !== null);
        return noFolder as UserPlaylistWithUser[];
    }, [playlists?.data, nestedPlaylistIds]);

    return (
        <>
            {userPlaylistCategoriesList.map((item) => (
                <NavMyPlaylistsCategory
                    handleShowSubPlaylistFolders={handleShowSubPlaylistFolders}
                    showPlaylist={showPlaylist}
                    key={item.id}
                    category={item}
                    isActive={isActive(item.id)}
                    isFolderOpened={isFolderOpened}
                    currentFolder={currentFolder}
                    containsActivePlaylist={folderContainsActivePlaylist(item)}
                    playlistSort={playlistSort}
                    handleOpenAndCloseFolder={handleOpenAndCloseFolder}
                    onUpdatePlaylistsPerCategory={onUpdatePlaylistsPerCategory}
                    handleOpenThreeDots={handleOpenThreeDots}
                />

            ))}

            <Droppable
                shallow
                key="Your Playlists Drop Area"
                accept={ALL_TYPES_NO_ARTIST}
                onDrop={(type, items) => {
                    return { target: 'my-playlists', id: '' };
                }}>{(dropAreaState) => (
                    <div
                        className={classNames(styles['drop-line'], {
                            [styles['drop-line--is-visible']]: dropAreaState.isOver || isOver,
                            [styles['drop-line--is-over']]: dropAreaState.isOver
                        })}
                    />
                )}
            </Droppable>

            {rootLevelPlaylists.map((playlist) => (
                <NavMyPlaylistListItem
                    indented={false}
                    key={playlist.id}
                    item={playlist as UserPlaylistWithUser}
                    containsActiveInList={false}
                />
            )
            )}

        </>
    );
}

export default NavMyPlaylistsCategories;
