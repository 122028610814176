import { HLSPlayer, PLAYER_PREVIEW_SECONDS } from '@bpm-web-app/utils';
import { useMemo } from 'react';
import classNames from 'classnames';
import { usePlayer, usePlayerDuration } from '../../../player-context';
import { TimestampSlider } from '../../../timestamp-slider/timestamp-slider';
import { PlayerWaveCanvas, } from '../player-wave-canvas/player-wave-canvas';
import styles from './player-wave-supreme.module.css';
import { PlayerWaveVariant } from '../types';

interface PlayerWaveSupremeProps {
    variant: PlayerWaveVariant
}

export function PlayerWaveSupreme({ variant }: PlayerWaveSupremeProps) {
    const { currentTrack, isPreviewTrack, currentDuration } = usePlayer();
    const { startOffset } = usePlayerDuration();

    const durationProps = useMemo(() => {
        const estimatedDuration = Number(currentTrack?.estimated_duration);
        const duration: number = isPreviewTrack ? currentDuration - startOffset || PLAYER_PREVIEW_SECONDS : HLSPlayer.getDuration();
        const startOffsetPercentage = isPreviewTrack && startOffset ? (startOffset / estimatedDuration) * 100 : 0;
        const lockedPercentage = ((estimatedDuration - duration) / estimatedDuration) * 100;
        const firstHalfPercentage = startOffset ? (startOffset / estimatedDuration) * 100 : 0;
        const secondHalfPercentage = startOffset ? ((startOffset + duration) / estimatedDuration) * 100 : 0;

        return {
            estimatedDuration,
            duration,
            startOffsetPercentage,
            lockedPercentage,
            firstHalfPercentage,
            secondHalfPercentage
        };
    }, [currentDuration, currentTrack?.estimated_duration, isPreviewTrack, startOffset]);

    if (!currentTrack?.waves.dat) return null;

    return (
        <div
            className={classNames(styles['player-wave-supreme'], styles[`player-wave-supreme--${variant}`])}
        >
            <TimestampSlider durationProps={durationProps} variant={variant} />
            <PlayerWaveCanvas datUrl={currentTrack.waves.dat} variant={variant} />
        </div>
    );
}
