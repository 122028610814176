import { useFeaturedPlaylists } from '@bpm-web-app/swr-hooks';
import { useContext, useEffect, useMemo } from 'react';
import CardCarousel from '../shared/card-carousel/card-carousel';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import { GhostComponent } from '../shared';
import { PlaylistCard } from '../shared/card/playlist-card/playlist-card';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExclusivePlaylistsBrowseProps {
    isDataLoaded?: boolean;
    setIsDataLoaded?: (loaded: boolean) => void;
}

export function ExclusivePlaylistsBrowse({ isDataLoaded, setIsDataLoaded }: ExclusivePlaylistsBrowseProps) {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { data, isLoading } = useFeaturedPlaylists({
        library: libraryProperty,
    });
    const playlists = data?.data;

    useEffect(() => {
        if (setIsDataLoaded) {
            if (isLoading === false) {
                setIsDataLoaded(true);
            } else {
                setIsDataLoaded(false);
            }
        }
    }, [isLoading, setIsDataLoaded]);

    const shouldDisplayGhostLoading = useMemo(() => isDataLoaded === false || isLoading, [isDataLoaded, isLoading]);

    return (
        <div>
            {shouldDisplayGhostLoading ? (
                <GhostComponent type="cards" title="Exclusive Playlists" elementsCount={8} contentType="exclusive-playlist" />
            ) : (
                <CardCarousel title="Exclusive Playlists" seeMorePath="/exclusive-playlists">
                    {playlists &&
                        playlists.map((playlist) => (
                            <PlaylistCard
                                key={playlist.id}
                                playlist={playlist}
                                playlists={playlists}
                            />
                        ))}
                </CardCarousel>
            )}
        </div>
    );
}

export default ExclusivePlaylistsBrowse;
