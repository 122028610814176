import { appendQueryParams, convertToPluralIfNeeded, getInitialsFromName, showToast, useHubSwitch } from '@bpm-web-app/utils';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Media, SearchPaginatedQuery, SearchQuery } from '@bpm-web-app/api-client';
import classNames from 'classnames';
import { LibraryTabsContext } from 'libs/utils/src/lib/library-tabs.context';
import styles from './artist-tooltip.module.css';
import { AppLink } from '../app-link/app-link';
import useFollowArtist from '../three-dots-sheet/useFollowArtist';
import { FollowButton } from '../follow-button/follow-button';

export function ArtistTooltipContent({ contentObj, onHide, onReady }: { contentObj: string, onHide: () => void, onReady: (ready: boolean) => void }) {
    const { isDownload } = useHubSwitch();
    const { libraryProperty } = useContext(LibraryTabsContext);

    const [artistName, artistSlug, artistId, artistImageUrl] = contentObj.split('|');

    const { isArtistFollowed, unfollowArtist, followArtist } = useFollowArtist(parseInt(artistId, 10));

    const [tracksCount, setTracksCount] = useState<number>();

    const query = useMemo<SearchQuery & SearchPaginatedQuery>(() => {
        const queryParams: SearchQuery & SearchPaginatedQuery = {
            artist: artistSlug?.toString(),
            library: libraryProperty
        };
        return queryParams;
    }, [artistSlug, libraryProperty]);

    const handleResetTooltipValues = () => {
        onReady(false);
        setTracksCount(undefined);
    };

    useEffect(() => {
        handleResetTooltipValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [artistId]);

    useEffect(() => {
        if (artistSlug && query) {
            const searchAlbums = Media.countAlbums(isDownload, {
                ...query,
            });
            searchAlbums.then((data) => {
                if (data.data.total) {
                    setTracksCount(data.data.total);
                    onReady(true);
                }
                return data;
            }).catch(() => {
                showToast({ type: 'error', title: 'Error fetching artist tracks' });
            });
        }
    }, [artistSlug, isDownload, query, onReady]);

    const renderLoading = () => {
        return (
            <div className={styles['artist-tooltip--loading']}>
                <div className={styles['artist-tooltip-ellipsis']}>
                    <span className={styles['artist-tooltip-ellipsis-dot']} />
                    <span className={styles['artist-tooltip-ellipsis-dot']} />
                    <span className={styles['artist-tooltip-ellipsis-dot']} />
                </div>
                Tracks
            </div>
        );
    };

    return (
        <div className={styles['artist-tooltip-container']}>
            <div className={styles['artist-tooltip']}>
                <div className={styles['artist-tooltip__image-container']}>
                    <figure className={styles['artist-tooltip__image']}>
                        {
                            !artistImageUrl || artistImageUrl === null || artistImageUrl === '' || artistImageUrl === 'null' ? (
                                <div className={styles['artist-tooltip__image-initials']}>{getInitialsFromName(artistName)}</div>
                            ) : (
                                <img
                                    src={appendQueryParams(artistImageUrl, { key: 'dw', value: 192 })}
                                    srcSet={`${appendQueryParams(artistImageUrl, { key: 'dw', value: 384 })} 2x`}
                                    alt={artistName}
                                    draggable={false}
                                />
                            )
                        }
                    </figure>
                </div>
                <div className={styles['artist-tooltip__text-container']}>
                    <AppLink href={`/artist/${artistSlug}`} key={artistId}>
                        <button type="button" onClick={onHide} className={classNames('underline-link', styles['artist-tooltip__name'])}>{artistName}</button>
                    </AppLink>
                    <div className={styles['artist-tooltip__tracks-count']}>{tracksCount !== undefined ? convertToPluralIfNeeded(tracksCount, 'Track', 'Tracks') : renderLoading()}</div>
                </div>
                <div className={styles['artist-tooltip__button-container']}>
                    <FollowButton
                        isFollowed={!!isArtistFollowed}
                        type="artist"
                        fullWidth
                        filled
                        handleFollow={isArtistFollowed ? unfollowArtist : followArtist} />
                </div>
            </div>
        </div>
    );
}
