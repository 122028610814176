import { useGetDownloadedSoundPackages } from '@bpm-web-app/swr-hooks';
import { convertSoundPackageToCarouselProps, createAppRoutes, DEFAULT_SOUNDS_LIMIT } from '@bpm-web-app/utils';
import { ComponentProps, useMemo } from 'react';
import { BreakpointView } from '../../shared/ui';
import { CreateCardCarousel } from '../../create-card-carousel/create-card-carousel';
import { HorizontalTabs } from '../../shared/ui/horizontal-tabs/horizontal-tabs';

type CreateDownloadsProps = {
    mobileTabs: ComponentProps<typeof HorizontalTabs>['tabs'];
    listComponent: JSX.Element;
};

export const AccountsDownloadsCreate = ({ mobileTabs, listComponent }: CreateDownloadsProps) => {
    const { data: soundPackages, isLoading } = useGetDownloadedSoundPackages(
        {
            limit: DEFAULT_SOUNDS_LIMIT,
        },
        true
    );

    const soundPackCarouselData = useMemo(() => soundPackages?.data.map((sound) => convertSoundPackageToCarouselProps(sound)), [soundPackages]);

    return (
        <BreakpointView
            desktopChildren={
                <>
                    <CreateCardCarousel
                        contentType="pack"
                        items={soundPackages?.data || []}
                        cardSize="small"
                        carouselMorePath={createAppRoutes.accountDownloadPacks}
                        isLoading={isLoading}
                        carouselTitle="Packs"
                        carouselTitleCounter={`${soundPackCarouselData?.length}`}
                        noPadding
                    />
                    {listComponent}
                </>
            }
            mobileChildren={<HorizontalTabs tabs={mobileTabs} />}
        />
    );
};
