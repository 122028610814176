import { PageNotFound } from '@bpm-web-app/components/pages';
import { useHideSwitch, useHubSwitch } from '@bpm-web-app/utils';
import { usePlaylistsByCategoryId } from '@bpm-web-app/swr-hooks';
import router from 'next/router';
import { useContext } from 'react';
import { Playlist } from '@bpm-web-app/stream-api-sdk';
import CardGrid from '../shared/card-grid/card-grid';
import { LibraryTabsContext, useHideLibraryTabs } from '../../../../utils/src/lib/library-tabs.context';
import SeeMoreMobile from '../see-more-mobile/see-more-mobile';

import SeeMoreSupremeLayout from '../see-more-layout/see-more-supreme-layout';
import Title from '../title/title';
import { PlaylistCard } from '../shared/card/playlist-card/playlist-card';

export function PlaylistCategoriesDetail() {
    const { categoryId } = router.query;

    const { libraryProperty } = useContext(LibraryTabsContext);
    const { data, isLoading } = usePlaylistsByCategoryId(+categoryId, libraryProperty);

    const { isDownload, hub } = useHubSwitch();

    const playlists = data?.data as Playlist[];

    useHideSwitch();
    useHideLibraryTabs();

    if (Number.isNaN(+categoryId) || +categoryId === 0) return <PageNotFound />;

    if (isLoading || !data) return <div />;
    return (
        <>
            <Title platform={hub} title="Exclusive Playlists" />
            <SeeMoreSupremeLayout
                pageTitle={data?.data?.[0]?.category?.name}
                mobileChildren={<SeeMoreMobile isDownload={isDownload} isPlayable linkPrefix="/exclusive-playlist" contentType="exclusive-playlist" data={playlists} />}
                desktopChildren={
                    <CardGrid cardType="card-small">
                        {playlists.map((playlist) => (
                            <PlaylistCard
                                key={playlist.id}
                                playlist={playlist}
                                playlists={playlists}
                            />
                        ))}
                    </CardGrid>

                }
            />
        </>
    );
}
