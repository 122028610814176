import {
    QueueItem
} from '@bpm-web-app/utils';
import classNames from 'classnames';
import 'rc-slider/assets/index.css';
import { useMemo } from 'react';
import { AppLink } from '../../shared';
import styles from './track-title.module.css';

interface TrackTitleProps {
    currentTrack: QueueItem;
    setIsMaxiPlayer: (isMaxiPlayer: boolean) => void;
}

export function useSplitTitle(currentTrack: QueueItem) {
    return useMemo(() => {
        const linkedArtist = currentTrack.artist_remixer;
        const titleNoVersion = currentTrack.title.replace(`(${currentTrack.version})`, '');

        if (linkedArtist) {
            const startIndex = titleNoVersion.indexOf(linkedArtist.name);

            if (startIndex > -1) {
                const endIndex = startIndex + linkedArtist.name.length;

                // Extract parts of the title
                const title = titleNoVersion.substring(0, startIndex);
                const linkedArtistName = titleNoVersion.substring(startIndex, endIndex);
                const linkedArtistLink = `/artist/${linkedArtist.slug}`;
                const afterLink = titleNoVersion.substring(endIndex);
                const linkedArtistDataTip = `${linkedArtistName}|${linkedArtist.slug}|${linkedArtist.id}|${linkedArtist.artwork_url}`;

                return { trackTitle: title, linkedArtistName, afterLink, linkedArtistLink, linkedArtistDataTip };
            }
        }

        return { trackTitle: titleNoVersion, };
    }, [currentTrack]);
}

export function TrackTitle({ currentTrack, setIsMaxiPlayer }: TrackTitleProps) {
    const { trackTitle, linkedArtistName, afterLink, linkedArtistLink, linkedArtistDataTip } = useSplitTitle(currentTrack);

    return (
        <>
            <button type="button" onClick={() => setIsMaxiPlayer(true)} className={classNames(styles['track-title__title-mobile'])}>
                {currentTrack.title.replace(`(${currentTrack.version})`, '')}
            </button>

            <div className={classNames(styles['track-title__title'])}>
                <AppLink href={`/album/${currentTrack.album_id}`} passHref>
                    <a className="underline-link" data-tip={`${currentTrack.title}`} data-tip-show>
                        {trackTitle}
                    </a>
                </AppLink>

                {linkedArtistName && (
                    <AppLink href={linkedArtistLink} passHref>
                        <a
                            className="underline-link"
                            data-for="artist-tooltip"
                            data-tip={linkedArtistDataTip}
                            data-tip-show>
                            {linkedArtistName}
                        </a>
                    </AppLink>)
                }
                {afterLink ? <span>{afterLink}</span> : null}
            </div>

        </>
    );
}
