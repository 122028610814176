interface ChangeColorsProps {
    library: 'main' | 'latin' | 'artist';
}

export function changeLibraryColors({ library }: ChangeColorsProps) {
    switch (library) {
        case 'main':
            document.body.classList.remove('is-latin');
            document.body.classList.remove('is-white-label');
            break;
        case 'artist':
            document.body.classList.remove('is-latin');
            document.body.classList.add('is-white-label');
            break;
        case 'latin':
            document.body.classList.add('is-latin');
            document.body.classList.remove('is-white-label');
            break;
        default:
            document.body.classList.remove('is-latin');
            document.body.classList.remove('is-white-label');
    }
}
export default changeLibraryColors;
