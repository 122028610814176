import { HLSPlayer, parseSeconds } from '@bpm-web-app/utils';
import { usePlayer, usePlayerDuration } from '../../../player-context';
import { PlayerWaveSupreme } from '../../../shared/player-wave/player-wave-supreme';
import styles from './wave.module.css';

interface WaveProps {
    variant: 'maxi-desktop' | 'maxi-tablet' | 'maxi-mobile';
}
export function Wave({ variant }:WaveProps) {
    const { elapsed } = usePlayerDuration();
    const {
        currentTrack,
        isPreviewTrack,
    } = usePlayer();

    if (!currentTrack) return null;

    return (
        <div className={styles['desktop-wave']}>
            <div className={styles['desktop-wave__wave']}>
                <PlayerWaveSupreme variant={variant} />
            </div>
            <p className={styles['desktop-wave__elapsed']}>{parseSeconds(elapsed)}</p>
            <p className={styles['desktop-wave__duration']}>{parseSeconds(isPreviewTrack ? currentTrack.estimated_duration! : HLSPlayer.getDuration())}</p>
        </div>
    );
}
