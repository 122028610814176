import { useHubSwitch, Hub, DOWNLOAD_ONLY } from '@bpm-web-app/utils';
import { useCallback } from 'react';
import { usePlayer } from '../player-context';
import styles from './hub-switch.module.css';
import TextSwitch from '../shared/ui/text-switch/text-switch';

export function HubSwitch() {
    const { hub, setHub, hubCount, isSwitchVisible } = useHubSwitch();

    const { clearQueue } = usePlayer();

    const handleHubSwitch = useCallback(
        (newHub: Hub) => {
            setHub(newHub);
            clearQueue();
        },
        [clearQueue, setHub]
    );

    const { download, stream, showCount } = hubCount;

    return (isSwitchVisible && !DOWNLOAD_ONLY) ? (
        <div className={styles['hub-switch']}>
            <TextSwitch<Hub>
                value={hub}
                onChange={handleHubSwitch}
                options={[
                    {
                        label: `Download ${showCount ? ` (${download})` : ''}`,
                        value: Hub.Download,
                    },
                    {
                        label: `Stream ${showCount ? ` (${stream})` : ''}`,
                        value: Hub.Stream,
                    },
                ]}
            />
        </div>
    ) : null;
}

export default HubSwitch;
