import { useMemo } from 'react';
import { useViewport } from '@bpm-web-app/utils';
import ModalSlider from '../modal-slider/modal-slider';
import musicDesktop from '../../../assets/onboarding/desktop/navigation-music.gif';
import searchDesktop from '../../../assets/onboarding/desktop/search-all-results.gif';

import musicMobile from '../../../assets/onboarding/mobile/navigation-music.gif';
import searchMobile from '../../../assets/onboarding/mobile/search-all-results.gif';

export function OnboardingModalNavigation() {
    const { isMobile } = useViewport();
    const data = useMemo(
        () => [
            /* {
    image: downloads,
    title: 'How to Navigate Download and Streaming Libraries',
    text: 'This is an option to toggle between ‘Download’ and ‘Stream.’
    Start here to control which music libraries are viewable and explore
    thousands of songs to download or stream.',
    href: 'https://support.bpmsupreme.com/hc/en-us',
  }, */ {
                image: isMobile ? musicMobile : musicDesktop,
                title: 'How to Navigate Music Library',
                text: 'Choose the music library that suits your needs by clicking an option here on the top left.',
                href: 'https://www.youtube.com/watch?v=qHD_1j7w1VQ&list=PLrlMfzKBjbOiofesOOuZM-302p-EpYkZs',
            },
            {
                image: isMobile ? searchMobile : searchDesktop,
                title: 'Search',
                text: 'On the top of your screen is a ‘Search’ bar. Enter keywords such as artists, songs, playlists, and more.',
                href: 'https://www.youtube.com/watch?v=asvFZNU_GYs&list=PLrlMfzKBjbOiofesOOuZM-302p-EpYkZs&index=2',
            },
        ],
        [isMobile]
    );

    return <ModalSlider type="ModalNavigation" sliderItems={data} />;
}

export default OnboardingModalNavigation;
