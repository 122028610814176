import { Album } from '@bpm-web-app/download-api-sdk';
import { QueueItem, appendQueryParams, downloadAlbumWithMediaToQueueItem, streamMediaWithAlbumToQueueItem } from '@bpm-web-app/utils';
import { useCallback } from 'react';
import { MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { usePlayer } from '../../../player-context';
import Card from '../card';
import CardGhostLoading from '../card-ghost-loading';

interface AlbumCardProps {
    albumsList?: Album[] | MediaWithAlbum[];
    album?: Album | MediaWithAlbum;
    isDownload?: boolean;
    isGhost?: boolean;
}

export function AlbumCard({ album, albumsList, isDownload, isGhost }: AlbumCardProps) {
    const { setQueue } = usePlayer();

    const downloadAlbumsList = albumsList as Album[];
    const mediaAlbumsList = albumsList as MediaWithAlbum[];

    const handleCardPlay = useCallback(
        () => {
            let queueItems: QueueItem[];
            let index: number | undefined;

            if (isDownload) {
                queueItems = downloadAlbumsList ? downloadAlbumsList.map((currentMedia) => downloadAlbumWithMediaToQueueItem(currentMedia as Album)) : [];
                index = downloadAlbumsList?.findIndex((currentMedia) => currentMedia.id === album?.id);
            } else {
                queueItems = mediaAlbumsList ? mediaAlbumsList.map((media) => streamMediaWithAlbumToQueueItem(media as MediaWithAlbum)) : [];
                index = mediaAlbumsList?.findIndex((currentMedia) => currentMedia.id === album?.id);
            }

            setQueue(queueItems, index);
        }, [album?.id, downloadAlbumsList, isDownload, mediaAlbumsList, setQueue]
    );

    if (isGhost) {
        return (
            <CardGhostLoading contentType={isDownload ? 'download:album' : 'track'} />
        );
    }

    return (
        isDownload ? (
            album as Album ? (
                <Card
                    cardSize="small"
                    id={(album as Album).id}
                    title={(album as Album).title}
                    imageUrl={appendQueryParams((album as Album).cover_url, { key: 'dw', value: 160 })}
                    imageUrl2x={appendQueryParams((album as Album).cover_url, { key: 'dw', value: 320 })}
                    label={(album as Album).is_exclusive ? 'Exclusive' : undefined}
                    isPlayable
                    onCardPlay={handleCardPlay}
                    link={`/album/${(album as Album).id}`}
                    media={(album as Album).media[0]}
                    album={album as Album}
                    currentPlaylist={albumsList}
                    contentType="download:album"
                    badge={(album as Album).badge}
                    linkedArtist={(album as Album).artist_remixer}
                    descriptionProps={{
                        artists: (album as Album).artists,
                        artist: (album as Album).artist,
                        bpm: (album as Album).bpm as number,
                        actualKey: (album as Album).key as string,
                        key: (album as Album).display_key as string,
                        genre: (album as Album).genre,
                    }}
                    hasPremiumOnlyAccess={(album as Album).has_premium_only_access}
                    isPremiumOnly={(album as Album).is_premium_only}
                />
            ) : null) : (
            album as MediaWithAlbum ? (
                <Card
                    cardSize="small"
                    id={(album as MediaWithAlbum).id}
                    title={(album as MediaWithAlbum).title}
                    imageUrl={appendQueryParams((album as MediaWithAlbum).album?.cover_url, { key: 'dw', value: 160 })}
                    imageUrl2x={appendQueryParams((album as MediaWithAlbum).album?.cover_url, { key: 'dw', value: 320 })}
                    label={(album as MediaWithAlbum).album?.is_exclusive ? 'Exclusive' : undefined}
                    isPlayable
                    onCardPlay={handleCardPlay}
                    media={album as MediaWithAlbum}
                    currentPlaylist={mediaAlbumsList}
                    contentType="track"
                    descriptionProps={{
                        artists: (album as MediaWithAlbum).artists,
                        artist: (album as MediaWithAlbum).artist,
                        bpm: (album as MediaWithAlbum).bpm,
                        actualKey: (album as MediaWithAlbum).key,
                        key: (album as MediaWithAlbum).display_key,
                        genre: (album as MediaWithAlbum).album?.genre,
                    }}
                />
            ) : null
        )
    );
}
