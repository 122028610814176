import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { usePlayer } from '../../../player-context';
import styles from './overlay.module.css';

export function Overlay() {
    const { isMaxiPlayer, setIsMaxiPlayer } = usePlayer();
    const closeMaxiPlayer = () => {
        setIsMaxiPlayer(false);
    };

    return (

        <CSSTransition
            in={isMaxiPlayer}
            timeout={400}
            unmountOnExit
            classNames={{
                enter: styles['overlay--enter'],
                enterActive: styles['overlay--enter-active'],
                enterDone: styles['overlay--enter-done'],
                exit: styles['overlay--exit'],
                exitActive: styles['overlay--exit-active'],
                exitDone: styles['overlay--exit-done'],
            }}>
            <div
                aria-hidden="true"
                onClick={closeMaxiPlayer}
                // eslint-disable-next-line @typescript-eslint/dot-notation
                className={classNames(styles['overlay'])}
            />
        </CSSTransition>
    );
}
