import { useCuratedSetsByCategories, useCuratedSetsByCategoryId } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import { PageNotFound } from '@bpm-web-app/components/pages';
import { useHideSwitch, useHubSwitch } from '@bpm-web-app/utils';
import { useContext, useEffect, useState } from 'react';
import CardGrid from '../shared/card-grid/card-grid';
import { LibraryTabsContext, useHideLibraryTabs } from '../../../../utils/src/lib/library-tabs.context';
import SeeMoreMobile from '../see-more-mobile/see-more-mobile';

import SeeMoreSupremeLayout from '../see-more-layout/see-more-supreme-layout';
import Title from '../title/title';
import { CuratedSetCard } from '../shared/card/curated-set-card/curated-set-card';

export function CuratedSetsCategory() {
    const {
        query: { categoryId },
    } = useRouter();
    const { libraryProperty } = useContext(LibraryTabsContext);
    const [pageTitle, setPageTitle] = useState('');
    const { data, isLoading } = useCuratedSetsByCategoryId(+categoryId, libraryProperty);
    const { data: categories } = useCuratedSetsByCategories(libraryProperty);

    useHideSwitch();
    useHideLibraryTabs();

    useEffect(() => {
        if (!categories) return;
        setPageTitle((categories as { id: number, name: string }[]).find(({ id }) => categoryId === `${id}`)?.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories]);

    const { isDownload, hub } = useHubSwitch();

    if (Number.isNaN(+categoryId)) return <PageNotFound />;

    // TODO: Do you have a loading indicator/component?
    if (isLoading || !data) return <div />;

    return (
        <>
            <Title platform={hub} title="Curated Sets" />
            <SeeMoreSupremeLayout
                pageTitle={pageTitle}
                count={data?.length || 0}
                mobileChildren={<SeeMoreMobile isDownload={isDownload} data={data} contentType="curatedSet" isPlayable linkPrefix="/curated-set" />}
                desktopChildren={
                    <CardGrid cardType="card-small">
                        {data.map((set) => (
                            <CuratedSetCard
                                key={set.id}
                                curatedSet={set}
                                curatedSets={data} />
                        ))}
                    </CardGrid>
                }
            />
        </>
    );
}

export default CuratedSetsCategory;
