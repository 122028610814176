import useSWR from 'swr';
import { DownloadAlbum, Media } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';
import { FeaturedQuery } from 'libs/api-client/src/lib/typings/featured-query';

type StreamFeaturedOriginalsResponse = Awaited<ReturnType<typeof Media.getFeaturedBpmOriginalMedia>>;

type DownloadFeaturedOriginalsResponse = Awaited<ReturnType<typeof DownloadAlbum.getFeaturedBpmOriginalAlbums>>;

type FeaturedOriginalsResponse = StreamFeaturedOriginalsResponse | DownloadFeaturedOriginalsResponse;

export function useFeaturedBpmOriginalMedia(query: FeaturedQuery) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR<FeaturedOriginalsResponse>(
        [
            'search-featured-bpm-originals',
            {
                persist: true,
                ...query,
                isDownload,
            },
        ],
        () => (!isDownload ? Media.getFeaturedBpmOriginalMedia(query) : DownloadAlbum.getFeaturedBpmOriginalAlbums(query))
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}
