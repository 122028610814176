import classNames from 'classnames';
import { HLSPlayer, PLAYER_PREVIEW_SECONDS } from '@bpm-web-app/utils';
import { useMemo } from 'react';
import { useDrag } from '@use-gesture/react';
import styles from './seek-slider.module.css';
import { usePlayer, usePlayerDuration } from '../../player-context';

type Props = {
    className?: string;
};

const SMALL_STEP = 5; // in seconds
const BIG_STEP = 15; // in seconds

export function SeekSlider({ className }: Props) {
    const { currentTrack, onSeek, isPreviewTrack, currentDuration } = usePlayer();
    const { elapsed, startOffset } = usePlayerDuration();

    const currentTrackDuration = useMemo(() => Number(currentTrack?.estimated_duration), [currentTrack?.estimated_duration]);
    const duration: number = isPreviewTrack ? currentDuration - startOffset || PLAYER_PREVIEW_SECONDS : HLSPlayer.getDuration();

    const elapsedPercentage = isPreviewTrack ? ((elapsed - startOffset) / currentTrackDuration) * 100 : (elapsed / duration) * 100;
    const startOffsetPercentage = isPreviewTrack && startOffset ? (startOffset / currentTrackDuration) * 100 : 0;

    const seekSeconds = (toSeek: number) => {
        onSeek(Math.max(isPreviewTrack ? startOffset : 0, Math.min(currentTrackDuration, elapsed + toSeek)));
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        switch (event.key) {
            case 'ArrowLeft':
            case 'DownArrow':
                event.preventDefault();
                seekSeconds(-SMALL_STEP);
                break;
            case 'ArrowRight':
            case 'UpArrow':
                event.preventDefault();
                seekSeconds(+SMALL_STEP);
                break;
            case 'PageDown':
                event.preventDefault();
                seekSeconds(-BIG_STEP);
                break;
            case 'PageUp':
                event.preventDefault();
                seekSeconds(+BIG_STEP);
                break;
            case 'Home':
                event.preventDefault();
                seekSeconds(isPreviewTrack ? startOffset : 0);
                break;
            case 'End':
                event.preventDefault();
                onSeek(currentTrackDuration);
                break;
            default:
                break;
        }
    };

    const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation?.();
        if (!('getBoundingClientRect' in event.currentTarget)) {
            return;
        }
        const { width, left } = event.currentTarget.getBoundingClientRect();
        const offset = event.clientX - left;
        let percentage = (offset / width) * 100;
        if (percentage < 0) percentage = 0;
        if (percentage > 100) percentage = 100;
        const toSeek = (isPreviewTrack ? currentTrackDuration / 100 : duration / 100) * percentage;
        onSeek(isPreviewTrack && startOffset && toSeek < startOffset ? startOffset : toSeek);
    };

    const dragEvents = useDrag(({ event, down }) => {
        event.stopPropagation?.();
        if (!down) return;
        onClick({
            clientX: (event as any).clientX,
            currentTarget: event.currentTarget,
        } as React.MouseEvent<HTMLDivElement>);
    });

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <div
            id="seek-progress-slider"
            className={classNames(styles['seek-slider'], className)}
            onClick={onClick}
            {...(dragEvents as any)()}
            onKeyDown={onKeyDown}
            tabIndex={0}
            role="slider"
            aria-label="seek progress slider"
            aria-valuemax={1}
            aria-valuemin={0}
            aria-valuenow={elapsedPercentage}
            aria-live="assertive"
            style={{ '--seek-slider-progress': `${elapsedPercentage + startOffsetPercentage}%` } as React.CSSProperties}
        />
    );
}
