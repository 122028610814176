import { Album } from '@bpm-web-app/download-api-sdk';
import { appendQueryParams } from '@bpm-web-app/utils';
import { Artist } from '@bpm-web-app/stream-api-sdk';
import Card from '../card';
import CardGhostLoading from '../card-ghost-loading';
import styles from './crate-card.module.css';
import { BreakpointView } from '../../ui';
import ListGridItem from '../../list-grid-item/list-grid-item';
import { useArtistLinks } from '../../../artist-link/artist-link';

export interface CrateListItem {
    id: string;
    name: string;
    slug: string;
    coverUrl: string;
    coverColor: string;
    artists: Artist[];
    created_at: string;
    albums: Album[]
}

interface CrateCardProps {
    crates?: CrateListItem[];
    crate?: CrateListItem;
    isGhost?: boolean;
}

export function CrateCard({ crate, crates, isGhost }: CrateCardProps) {
    const generateArtistLinks = useArtistLinks();

    const getDescriptionMarkup = ({ artist, artists }: { artist: string; artists: Artist[]; }) => (
        <div className={styles['crate-card__artist']}>{generateArtistLinks(artist, artists)}</div>
    );

    const getFourUniqueImages = () => {
        if (crate === undefined) return undefined;

        const uniqueUrls: string[] = [];
        crate?.albums.filter((item) => {
            if (!uniqueUrls.includes(item.cover_url)) {
                uniqueUrls.push(item.cover_url);
                return true;
            }
            return false;
        });
        return uniqueUrls.slice(0, 4);
    };

    if (isGhost) {
        return (
            <CardGhostLoading contentType="online-crate" />
        );
    }

    return crate ? (
        <BreakpointView
            mobileChildren={
                <ListGridItem
                    id={crate.id}
                    title={crate.name}
                    imageUrl={appendQueryParams(crate.coverUrl, { key: 'dw', value: 56 })}
                    imageUrl2x={appendQueryParams(crate.coverUrl, { key: 'dw', value: 112 })}
                    subtitle={getDescriptionMarkup({ artists: crate.artists, artist: crate.artists.map((a) => a.name).join(', ') })}
                    link={`/crate/${crate.slug}`}
                />
            }
            desktopChildren={
                <Card
                    isPlayable={false}
                    cardSize="small"
                    id={crate.id}
                    title={crate.name}
                    descriptionProps={{
                        artist: crate.artists.map((a) => a.name).join(', '),
                        artists: crate.artists
                    }}
                    isDescriptionSingleLine
                    imageUrl={appendQueryParams(crate.coverUrl, { key: 'dw', value: 160 })}
                    imageUrl2x={appendQueryParams(crate.coverUrl, { key: 'dw', value: 320 })}
                    link={`/crate/${crate.slug}`}
                    gridImagesUrls={getFourUniqueImages()}
                />
            }
        />
    ) : null;
}
