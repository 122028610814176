import { useMemo } from 'react';
import { useViewport } from '@bpm-web-app/utils';
import ModalSlider from '../modal-slider/modal-slider';
import musicDesktop from '../../../assets/onboarding/desktop/transfer-music.png';
import musicMobile from '../../../assets/onboarding/mobile/transfer-music.png';

export function OnboardingModalAddMusic() {
    const { isMobile } = useViewport();
    const data = useMemo(
        () => [
            {
                image: isMobile ? musicMobile : musicDesktop,
                title: 'Add Music',
                text: 'Get started by importing your existing playlists or liked songs from other music services. Your music will be matched inside BPM Supreme if available.',
                href: 'https://www.youtube.com/watch?v=qHD_1j7w1VQ&list=PLrlMfzKBjbOiofesOOuZM-302p-EpYkZs',
            },
        ],
        [isMobile]
    );

    return <ModalSlider type="ModalAddMusic" sliderItems={data} />;
}

export default OnboardingModalAddMusic;
