import classNames from 'classnames';
import styles from './empty-state.module.css';
import { ListWrapper } from '../list-wrapper/list-wrapper';

interface EmptyStateProps {
    title: string
    icon: React.ReactNode
}

export function EmptyState({ title, icon }: EmptyStateProps) {
    return (
        <ListWrapper>
            <div className={classNames(styles['empty-state'])}>

                <div className={classNames(styles['empty-state__circles'])}>
                    <div className={classNames(styles['empty-state__icon'])}>
                        {icon}
                    </div>
                    <div className={classNames(styles['empty-state__circle'], styles['empty-state__circle-1'])} />
                    <div className={classNames(styles['empty-state__circle'], styles['empty-state__circle-2'])} />
                    <div className={classNames(styles['empty-state__circle'], styles['empty-state__circle-3'])} />
                </div>

                <h2 className={classNames(styles['empty-state__title'])}>{title}</h2>

            </div>
        </ListWrapper>
    );
}
