import { UserPlaylist } from '@bpm-web-app/download-api-sdk';
import { getInitialsFromName } from '@bpm-web-app/utils';
import Card from '../card';

interface MyPlaylistCardProps {
    playlist?: UserPlaylist;
}

export function MyPlaylistCard({ playlist }: MyPlaylistCardProps) {
    return playlist ? (
        <Card
            imageUrl={playlist.custom_image_url || playlist.image_url || ''}
            title={playlist.title}
            descriptionProps={{ artist: playlist.artist, artists: playlist.artists }}
            id={playlist.id}
            isPlayable
            cardSize="user-playlist"
            contentType="user-playlist"
            userPlaylistConfig={{
                initials: getInitialsFromName(playlist.title),
                imageBgColor: playlist.image_color || '#000002',
            }}
            link={`/my-playlist/${playlist.id}`}
        />
    ) : null;
}
