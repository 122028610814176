import { Subscription } from '@bpm-web-app/api-client';
import { CouponUsageCalc } from '@bpm-web-app/supreme-api-sdk';
import { getCouponStorage, showToast } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import styles from './promo-code.module.css';

interface PromoCodeProps {
    setPromoCode: (item: string) => void;
    setCouponInfo: (item: CouponUsageCalc) => void;
    packageId: number;
}

interface Inputs {
    promoCode: string;
}

export function PromoCode({ setPromoCode, setCouponInfo, packageId }: PromoCodeProps) {
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isDirty },
    } = useForm({
        defaultValues: {
            promoCode: getCouponStorage(),
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (formFields) => {
        try {
            const resp = await Subscription.checkCouponCode(packageId, formFields.promoCode);
            setPromoCode(formFields?.promoCode);
            setCouponInfo(resp?.data?.info);
        } catch (error) {
            showToast({ type: 'error', message: 'This promo code is invalid. Please try again.' });
        }
    };

    useEffect(() => {
        if (getCouponStorage()) {
            handleSubmit(onSubmit)();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles['promo-code__title']}>
                <h3> Promo Code </h3>
            </div>
            <div className={styles['promo-code__content']}>
                <input {...register('promoCode', { required: true })} className={classNames(styles['promo-code__input'], 'generic__input')} placeholder="Promo Code" />
                <button disabled={isSubmitting || !isDirty} className="account__button-secondary" type="submit">
                    Apply
                </button>
            </div>
        </form>
    );
}

export default PromoCode;
