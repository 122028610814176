/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    SearchAlbums,
    SearchSongs,
    Toolbar,
    HubSwitch,
    SearchAlbumsCarousel,
    SearchArtists,
    SearchArtistCarousel,
    SearchCuratedSets,
    SearchExclusivePlaylists,
    OnboardingModalSearch,
    NavContext,
} from '@bpm-web-app/components';
import { AnonymousUserBanner, AppLink, EmptyState } from '@bpm-web-app/components/shared';
import classNames from 'classnames';
import { useViewport, useHubSwitch } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styles from './search.module.css';
import { TrackListFilteredTagsCount } from '../../shared/track-list/track-list-filters/track-list-filtered-tags-count';

export function Search() {
    const router = useRouter();
    const {
        query: { type },
    } = router;
    const { isMobile } = useViewport();
    const { showSearchBar } = useContext(NavContext);

    useEffect(() => {
        showSearchBar();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [artistsCount, setArtistsCount] = useState(0);
    const [songsCount, setSongsCount] = useState(0);
    const [albumsCount, setAlbumsCount] = useState(0);
    const [exclusivePlaylistsCount, setExclusivePlaylistsCount] = useState(0);
    const [curatedSetsCount, setCuratedSetsCount] = useState(0);

    const totalCount = useMemo(() => {
        return artistsCount + songsCount + albumsCount + exclusivePlaylistsCount + curatedSetsCount;
    }, [albumsCount, artistsCount, curatedSetsCount, exclusivePlaylistsCount, songsCount]);

    const shouldDisplayNoResults = useMemo(() => {
        if (artistsCount === 0 && songsCount === 0 && albumsCount === 0 && exclusivePlaylistsCount === 0 && curatedSetsCount === 0) {
            return true;
        }

        return false;
    }, [albumsCount, artistsCount, curatedSetsCount, exclusivePlaylistsCount, songsCount]);

    const renderNoResults = useMemo(() => {
        return (
            <div className="spacing__window">
                <EmptyState
                    noPadding
                    title={"Sorry, we couldn't find anything that matches your search."}
                    subtitle="Try checking your spelling, or searching for something else."
                    icon="search-outline-icon"
                    variant="white"
                />
            </div>
        );
    }, []);

    const actualTab = useMemo(() => type || (isMobile ? 'artists' : undefined), [type, isMobile]);

    const { isDownload } = useHubSwitch();

    const tabs = useMemo(
        () =>
            [
                {
                    id: 'artists',
                    label: 'Artists',
                },
                isDownload
                    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      null!
                    : { id: 'songs', label: 'Songs' },
                {
                    id: 'albums',
                    label: isDownload ? 'Songs' : 'Albums',
                },
                {
                    id: 'exclusive-playlists',
                    label: 'Exclusive Playlists',
                },
                {
                    id: 'curated-sets',
                    label: 'Curated Sets',
                },
            ].filter(Boolean),
        [isDownload]
    );

    const [isLoadingArtists, setIsLoadingArtists] = useState(true);
    const [isLoadingSongs, setIsLoadingSongs] = useState(true);
    const [isLoadingPlaylists, setIsLoadingPlaylists] = useState(true);
    const [isLoadingSets, setIsLoadingSets] = useState(true);

    const isEverythingLoaded = useMemo(() => {
        if (!isLoadingArtists && !isLoadingSongs && !isLoadingPlaylists && !isLoadingSets) return true;
        return false;
    }, [isLoadingArtists, isLoadingPlaylists, isLoadingSets, isLoadingSongs]);
    const [hasLoadedEverything, setHasLoadedEverything] = useState(false);

    useEffect(() => {
        if (!hasLoadedEverything && isEverythingLoaded) setHasLoadedEverything(true);
    }, [hasLoadedEverything, isEverythingLoaded]);

    const seeMorePath = useMemo(
        // eslint-disable-next-line @typescript-eslint/dot-notation
        () => `/search?searchTerm=${router.query['searchTerm']}&type=`,
        [router.query]
    );

    const countedPages = ['artists', 'songs', 'exclusive-playlists', 'curated-sets'];

    const renderOtherForCounts = useCallback(
        (key?: 'albums' | 'artists' | 'songs' | 'exclusive-playlists' | 'curated-sets', recursive = false) => {
            if (!key || isMobile || recursive) {
                return null;
            }
            return countedPages.map((page) => {
                if (page === key) {
                    return null;
                }
                return (
                    <div key={page} className="hidden">
                        {getDetailPages(page as any, true)}
                    </div>
                );
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [countedPages, isMobile]
    );

    const getDetailPages = useCallback(
        (key?: 'albums' | 'artists' | 'songs' | 'exclusive-playlists' | 'curated-sets', recursive = false) => {
            switch (key) {
                case 'albums':
                    return (
                        <>
                            {renderOtherForCounts(key, recursive)}
                            <SearchAlbums key="albums" indexPage onCount={setAlbumsCount} hasNoResults={shouldDisplayNoResults} />
                        </>
                    );

                case 'artists':
                    return (
                        <>
                            {renderOtherForCounts(key, recursive)}
                            <SearchArtists key="artists" indexPage onCount={setArtistsCount} hasNoResults={shouldDisplayNoResults} />
                        </>
                    );

                case 'songs':
                    return (
                        <>
                            {renderOtherForCounts(key, recursive)}
                            <SearchSongs key="songs" indexPage onCount={setSongsCount} hasNoResults={shouldDisplayNoResults} />
                        </>
                    );

                case 'exclusive-playlists':
                    return (
                        <>
                            {renderOtherForCounts(key, recursive)}
                            <SearchExclusivePlaylists key="playlsits" indexPage onCount={setExclusivePlaylistsCount} hasNoResults={shouldDisplayNoResults} />
                        </>
                    );

                case 'curated-sets':
                    return (
                        <>
                            {renderOtherForCounts(key, recursive)}
                            <SearchCuratedSets key="curatedsets" indexPage onCount={setCuratedSetsCount} hasNoResults={shouldDisplayNoResults} />
                        </>
                    );

                default:
                    if (isMobile) {
                        return <SearchArtists key="artists" indexPage onCount={setArtistsCount} hasNoResults={shouldDisplayNoResults} />;
                    }
                    return (
                        <>
                            <SearchArtistCarousel
                                key="artists-carousel"
                                onCount={setArtistsCount}
                                hasNoResults={shouldDisplayNoResults}
                                isLoadingData={!hasLoadedEverything}
                                setIsLoadingData={setIsLoadingArtists}
                            />
                            <SearchSongs key="songs" onCount={setSongsCount} hasNoResults={shouldDisplayNoResults} isLoadingData={!hasLoadedEverything} setIsLoadingData={setIsLoadingSongs} />
                            {!isDownload && <SearchAlbumsCarousel key="albums-carousel" onCount={setAlbumsCount} hasNoResults={shouldDisplayNoResults} />}
                            <OnboardingModalSearch />
                            <SearchExclusivePlaylists
                                key="playlsits"
                                onCount={setExclusivePlaylistsCount}
                                hasNoResults={shouldDisplayNoResults}
                                isLoadingData={!hasLoadedEverything}
                                setIsLoadingData={setIsLoadingPlaylists}
                            />
                            <SearchCuratedSets
                                key="curatedsets"
                                onCount={setCuratedSetsCount}
                                hasNoResults={shouldDisplayNoResults}
                                isLoadingData={!hasLoadedEverything}
                                setIsLoadingData={setIsLoadingSets}
                            />
                        </>
                    );
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [shouldDisplayNoResults, isMobile, hasLoadedEverything, isDownload]
    );

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <div className={styles['search']}>
            <Toolbar scrollable={isMobile}>
                {isMobile ? (
                    <div className={classNames(styles['search__mobile-tabs-container'], 'spacing--left-first-child')}>
                        {tabs.map(({ id, label }) => (
                            <AppLink
                                key={id}
                                href={{
                                    pathname: router.pathname,
                                    query: {
                                        ...router.query,
                                        type: id,
                                    },
                                }}
                                overrideAppLinkTransform
                            >
                                <a className={classNames(styles['search__mobile-tab'], { [styles['search__mobile-tab--active']]: actualTab === id })}>{label}</a>
                            </AppLink>
                        ))}
                    </div>
                ) : (
                    <div className={styles['search__filter']}>
                        <HubSwitch />
                    </div>
                )}
            </Toolbar>
            <AnonymousUserBanner addTopPadding addBottomPadding={!!actualTab} centerAlignOnMobile />
            {!isMobile ? (
                <div className="spacing__window--top">
                    <TrackListFilteredTagsCount
                        totalCount={totalCount}
                        artistsCount={artistsCount}
                        songsCount={songsCount}
                        playlistsCount={exclusivePlaylistsCount}
                        curatedSetsCount={curatedSetsCount}
                        seeMorePath={seeMorePath}
                        currentValue={actualTab as string | undefined}
                        isLoading={!isEverythingLoaded}
                    />
                </div>
            ) : null}
            <div className={styles['search__page']}>{getDetailPages(actualTab as any | undefined)}</div>
            {shouldDisplayNoResults && isEverythingLoaded ? renderNoResults : undefined}
        </div>
    );
}

/* eslint-enable @typescript-eslint/no-use-before-define */
