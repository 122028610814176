import { useFollowedArtists } from '@bpm-web-app/swr-hooks';
import { useContext } from 'react';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import ArtistCardImage from '../shared/artist-card-image/artist-card-image';
import CardGrid from '../shared/card-grid/card-grid';
import styles from './favorite-artists.module.css';

export function FavoriteArtists() {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { data, isLoading } = useFollowedArtists(libraryProperty);
    // @TODO LOADING ANIMATION
    if (isLoading) {
        return null;
    }
    return (
        <div className="spacing__window">
            <h3 className={styles['favorite-artists']}>Followed Artists</h3>
            <CardGrid cardType="card-small">
                {data?.data?.map((item) => (
                    <ArtistCardImage
                        key={item.id}
                        artist={item}
                    />
                ))}
            </CardGrid>
        </div>
    );
}
