import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { isValidEmail, isValidURL, showToast } from '@bpm-web-app/utils';
import { toast } from 'react-toastify';
import { joiResolver } from '@hookform/resolvers/joi';
import { SubmitHandler, useForm } from 'react-hook-form';
import Joi from 'joi';
import { ApplicationSectionTitle } from '../../pages/artist-portal/artist-application/artist-application-text/artist-application-text';
import { ArtistApplicationInputField } from '../../pages/artist-portal/artist-application/artist-application-inputfield/artist-application-inputfield';
import { ArtistApplicationButton } from '../../pages/artist-portal/artist-application/artist-application-button/artist-application-button';

export interface DJVerificationInputs {
    name: string;
    email: string;
    link: string;
    additional_info: string;
}

interface AccountDjVerificationFormProps {
    djVerificationData?: DJVerificationInputs;
    djVerificationFormRef?: MutableRefObject<HTMLFormElement>;
    setHasCompletedDJVerification: (data: DJVerificationInputs) => void;
}

export function AccountDjVerificationForm({
    djVerificationData,
    djVerificationFormRef,
    setHasCompletedDJVerification,
}: AccountDjVerificationFormProps) {
    const schemaDJVerification = Joi.object({
        name: Joi.string().required().messages({ 'string.empty': 'Name is required.' }),
        email: Joi.string().required().custom((value, helper) => {
            if (isValidEmail(value)) {
                return value;
            }
            return helper.error('invalid.link');
        }).messages({ 'invalid.link': 'Please try again with a valid email.', 'string.empty': 'Booking email is required.' }),
        link: Joi.string().required().custom((value, helper) => {
            if (isValidURL(value)) {
                return value;
            }
            return helper.error('invalid.link');
        }).messages({ 'invalid.link': 'Please try again with a valid link.', 'string.empty': 'Social link is required.' }),
        additional_info: Joi.string().required().messages({ 'string.empty': 'Additional information is required.' }),
    });

    const {
        register: registerDJVerification,
        handleSubmit: handleSubmitDJVerification,
        formState: { errors: errorsDJVerification },
    } = useForm<DJVerificationInputs>({
        defaultValues: useMemo(
            () => ({
                name: djVerificationData && djVerificationData.name ? djVerificationData.name : '',
                email: djVerificationData && djVerificationData.email ? djVerificationData.email : '',
                link: djVerificationData && djVerificationData.link ? djVerificationData.link : '',
                additional_info: djVerificationData && djVerificationData.additional_info ? djVerificationData.additional_info : '',
            }),
            [djVerificationData]
        ),
        resolver: joiResolver(schemaDJVerification),
    });

    useEffect(() => {
        Object.keys(errorsDJVerification).forEach((err) => {
            showToast({ type: 'error', message: (errorsDJVerification as any)[err].message || 'An error has occurred. Please try again.' });
        });
        if (Object.keys(errorsDJVerification).length === 0) {
            toast.dismiss();
        }
    }, [errorsDJVerification]);

    const handlerDJVerification: SubmitHandler<DJVerificationInputs> = useCallback((data) => {
        setHasCompletedDJVerification({
            name: data.name,
            email: data.email,
            link: data.link,
            additional_info: data.additional_info,
        });
    }, [setHasCompletedDJVerification]);

    const DJVerification = useMemo(() => {
        return (
            <form ref={djVerificationFormRef} onSubmit={handleSubmitDJVerification(handlerDJVerification)}>
                <ApplicationSectionTitle title="Your DJ or DJ Business name" />
                <ArtistApplicationInputField
                    placeholder="DJ or DJ Business name"
                    inputProps={{ ...registerDJVerification('name', { required: true }) }}
                    errorMessage={errorsDJVerification && errorsDJVerification.name ? errorsDJVerification.name.message : undefined}
                />

                <ApplicationSectionTitle title="Your booking email" halfMargin />
                <ArtistApplicationInputField
                    placeholder="example@email.com"
                    inputProps={{ ...registerDJVerification('email', { required: true }) }}
                    errorMessage={errorsDJVerification && errorsDJVerification.email ? errorsDJVerification.email.message : undefined}
                />

                <ApplicationSectionTitle title="Link to active social media profile" halfMargin />
                <ArtistApplicationInputField
                    placeholder="instagram.com/username"
                    inputProps={{ ...registerDJVerification('link', { required: true }) }}
                    errorMessage={errorsDJVerification && errorsDJVerification.link ? errorsDJVerification.link.message : undefined}
                />

                <ApplicationSectionTitle title="Where do you regularly provide DJ services?" halfMargin />
                <ArtistApplicationInputField
                    multiline
                    placeholder="Description..."
                    inputProps={{ ...registerDJVerification('additional_info', { required: true }) }}
                    errorMessage={errorsDJVerification && errorsDJVerification.additional_info ? errorsDJVerification.additional_info.message : undefined}
                />

                <ArtistApplicationButton
                    text="Continue"
                />

            </form>
        );
    }, [djVerificationFormRef, handleSubmitDJVerification, handlerDJVerification, registerDJVerification, errorsDJVerification]);

    return DJVerification;
}
