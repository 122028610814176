import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { UserPlaylistWithUser, UserPlaylistCollaboration } from '@bpm-web-app/download-api-sdk';
import { useUserPlaylists } from '../shared/three-dots-sheet/useUserPlaylists';
import styles from './nav-my-playlist-list.module.css';

// eslint-disable-next-line import/no-cycle
import { LocalSearchSort } from '../nav-my-playlist-categories/utils';
import { ActionModal } from '../shared/action-modal/action-modal';
import { CollaborationModal } from '../shared/collaboration/collaboration-modal';
import NavMyPlaylistListItem from './nav-my-playlist-list-item';

export interface NavMyPlaylistListProps {
    categoryId: number;
    sort: LocalSearchSort | undefined;
    isDragging?: boolean;
    onGetPlaylistsPerCategory?: (categoryId: number, playlistsIds: string[]) => void;
}

export function NavMyPlaylistList({ categoryId, isDragging, sort, onGetPlaylistsPerCategory }: NavMyPlaylistListProps) {
    const router = useRouter();
    const { userPlaylists, reload } = useUserPlaylists(categoryId, true);
    const [playlists, setPlaylists] = useState<UserPlaylistWithUser[]>([]);
    const [isMyPlaylist, setIsMyPlaylist] = useState(false);
    const [currentPlaylist, setCurrentPlaylist] = useState<UserPlaylistWithUser | undefined>();
    const [showAddCollaborators, setShowAddCollaborators] = useState(false);
    const [playlistCollaborators, setPlaylistCollaborators] = useState<UserPlaylistCollaboration[]>();
    const [invitedCollaborators, setInvitedCollaborators] = useState<UserPlaylistCollaboration[]>([]);

    useEffect(() => {
        const listener = () => {
            reload();
        };
        document.addEventListener('playlist-update', listener);
        return () => {
            document.removeEventListener('playlist-update', listener);
        };
    }, [categoryId, reload]);

    useEffect(() => {
        if (userPlaylists && onGetPlaylistsPerCategory && categoryId) {
            const playlistsIds: string[] = [];
            userPlaylists.data?.forEach((playlist) => playlistsIds.push(playlist.id));
            onGetPlaylistsPerCategory(categoryId, playlistsIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPlaylists]);

    const userPlaylistsList = useMemo(() => {
        if (sort) {
            switch (sort) {
                case 'most_recent':
                    return playlists.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
                case 'title_asc':
                    return playlists.sort((a, b) => (a.title > b.title ? 1 : -1));
                case 'title_desc':
                    return playlists.sort((a, b) => (a.title < b.title ? 1 : -1));
                default:
                    return playlists;
            }
        }

        return playlists;
    }, [sort, playlists]);

    useEffect(() => {
        if (userPlaylists && userPlaylists.data) {
            setPlaylists(userPlaylists.data as UserPlaylistWithUser[]);
        }
    }, [userPlaylists]);

    const containsActiveInList = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (router.query['playlistId']) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const hasPlaylist = userPlaylistsList.find((playlistItem) => playlistItem.id === router.query['playlistId']);
            if (hasPlaylist) return true;
        }
        return false;
    }, [router.query, userPlaylistsList]);

    return (
        <div className={containsActiveInList ? styles['nav-my-playlist-list-item--container'] : ''}>
            {userPlaylistsList.map((item) => (
                <NavMyPlaylistListItem key={item.id} item={item} containsActiveInList={containsActiveInList} isDragging={isDragging} />
            ))}

            <ActionModal hideCancel headerTitle={`Share Playlist ‘${currentPlaylist?.title}’`} onClose={() => setShowAddCollaborators(false)} variant="dark" isOpen={showAddCollaborators}>
                {currentPlaylist && showAddCollaborators ? (
                    <CollaborationModal
                        playlistId={currentPlaylist?.id}
                        invitedCollaborators={invitedCollaborators}
                        setInvitedCollaborators={setInvitedCollaborators}
                        setCollaborators={setPlaylistCollaborators}
                        onClose={() => setShowAddCollaborators(false)}
                        collaborators={playlistCollaborators}
                        playlistOwner={currentPlaylist.owner || undefined}
                        isMyPlaylist={isMyPlaylist}
                        invitationLink={currentPlaylist.invitation_link}
                    />
                ) : null}
            </ActionModal>
        </div>
    );
}

export default NavMyPlaylistList;
