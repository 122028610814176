import classNames from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import {
    rebuildReactTooltip,
    State as PlayerStateEnum,
} from '@bpm-web-app/utils';

import { usePlayer } from '../player-context';
import { ThreeDotsSheetContext } from '../shared/three-dots-sheet/three-dots-sheet.context';
import { PlayerBackground } from './parts/background/player-background';
import { Lists } from './parts/lists/lists';
import { MinimizeButton } from './parts/minimize-button/minimize-button';
import { Overlay } from './parts/overlay/overlay';
import styles from './supreme-maxi-player.module.css';
import { PlayingTrack } from './playing-track';
import { VersionsMobileDrawerSupreme } from '../versions-mobile-drawer/versions-mobile-drawer-supreme';

export interface MaxiPlayerProps {
    playerState: PlayerStateEnum;
    setIsVersionMenuOpen: (isOpen: boolean) => void;
}

export function SupremeMaxiPlayer() {
    const { currentTrack, isMaxiPlayer, setIsMaxiPlayer, setShowSimilarTracks } = usePlayer();
    const { actionTypeId, closeThreeDotsModalSheet } = useContext(ThreeDotsSheetContext);

    useEffect(() => {
        setShowSimilarTracks(true);
    }, [setShowSimilarTracks]);

    const [isScrollable, setIsScrollable] = useState(false);
    const [isVersionMenuOpen, setIsVersionMenuOpen] = useState(false);
    const playerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        let ref: HTMLDivElement;

        const handleScroll = () => {
            if (actionTypeId >= 0) {
                closeThreeDotsModalSheet();
            }
        };

        if (playerRef.current) {
            ref = playerRef.current;
            playerRef.current.addEventListener('scroll', handleScroll, { passive: true });
        }

        return () => {
            ref?.removeEventListener('scroll', handleScroll);
        };
    }, [actionTypeId, closeThreeDotsModalSheet]);

    useEffect(() => {
        if (isMaxiPlayer) {
            rebuildReactTooltip();
        }
    }, [isMaxiPlayer]);

    if (!currentTrack) return null;

    return (
        <>
            <Overlay />

            <div
                ref={playerRef}
                className={classNames(styles['maxi-player'], {
                    [styles['maxi-player--open']]: isScrollable,
                })}
            >
                <div
                    className={classNames(styles['maxi-player__top-area'])}
                    aria-hidden="true"
                    onClick={() => {
                        setIsMaxiPlayer(false);
                    }}
                />

                <CSSTransition
                    onEnter={() => setIsScrollable(true)}
                    onExiting={() => setIsScrollable(false)}
                    in={isMaxiPlayer}
                    timeout={400}
                    unmountOnExit
                    classNames={{
                        enter: styles['maxi-player__container--enter'],
                        enterActive: styles['maxi-player__container--enter-active'],
                        enterDone: styles['maxi-player__container--enter-done'],
                        exit: styles['maxi-player__container--exit'],
                        exitActive: styles['maxi-player__container--exit-active'],
                        exitDone: styles['maxi-player__container--exit-done'],
                    }}>
                    <div className={classNames(styles['maxi-player__container'])}>
                        <MinimizeButton playerRef={playerRef} closeMaxiPlayer={() => setIsMaxiPlayer(false)} />
                        <PlayerBackground coverUrl={currentTrack.cover_url} />
                        <div className={styles['maxi-player__content']}>
                            <PlayingTrack onVersionsMenuOpen={() => setIsVersionMenuOpen(true)} />
                            <Lists />
                        </div>
                    </div>
                </CSSTransition>
            </div>

            <VersionsMobileDrawerSupreme isOpen={isVersionMenuOpen} onClose={() => setIsVersionMenuOpen(false)} />
        </>
    );
}
