import { getFollowedCuratedSets } from '@bpm-web-app/swr-hooks';
import CardGrid from '../shared/card-grid/card-grid';
import styles from './favorite-curated-sets.module.css';
import { CuratedSetCard } from '../shared/card/curated-set-card/curated-set-card';

export function FavoriteCuratedSets() {
    const { data, isLoading } = getFollowedCuratedSets();

    // @TODO LOADING ANIMATION
    if (isLoading) {
        return null;
    }
    return (
        <div className="spacing__window">
            <h3 className={styles['favorite-curated-sets']}>Favorite Curated Sets</h3>
            <CardGrid cardType="card-small">
                {data?.data?.map((item,) => (
                    <CuratedSetCard
                        key={item.id}
                        curatedSet={item}
                        curatedSets={data.data} />
                ))}
            </CardGrid>
        </div>
    );
}
