import { Album as DownloadAlbum, CuratedSet, CuratedSetWithAlbum, PlaylistWithAlbum, UserPlaylistWithAlbum } from '@bpm-web-app/download-api-sdk';
import { MediaWithAlbum, CuratedSet as StreamCuratedSet, Artist, Genre, Category, CuratedSetWithMedia, PlaylistWithMedia, UserPlaylistWithMedia, UserPlaylist, Playlist } from '@bpm-web-app/stream-api-sdk';
import { appendQueryParams, downloadAlbumWithMediaToQueueItem, QueueItem, streamMediaWithAlbumToQueueItem, useApiErrorHandler, useUniqueArray } from '@bpm-web-app/utils';
import { Fragment, useCallback } from 'react';
import GhostElement from '@bpm-web-app/components/src/lib/shared/ghost-element/ghost-element';
import { CuratedSets as CuratedSetsApi, Playlist as PlaylistsApi, UserPlaylist as UserPlaylistApi } from 'libs/api-client/src/lib';
import ListGrid from '../shared/ui/list-grid/list-grid';
import { usePlayer } from '../player-context';
import { ActionType } from '../shared/three-dots-sheet/three-dots-sheet.context';
import styles from './see-more-mobile.module.css';
import ListGridItem from '../shared/list-grid-item/list-grid-item';
import { useArtistLinks } from '../artist-link/artist-link';
import { useBpmLink, useGenreLinks, useKeyLinks, usePlaylistCategoryLinks } from '../generate-link/generate-link';

export interface SeeMoreMobileProps {
    isDownload: boolean;
    contentType: ActionType;
    isPlayable?: boolean;
    linkPrefix?: string;
    data: DownloadAlbum[] | MediaWithAlbum[] | CuratedSet[] | Playlist[] | UserPlaylist[] | StreamCuratedSet[];
    isLoading?: boolean;
}

export default function SeeMoreMobile({ data, isDownload, isPlayable = true, linkPrefix, contentType, isLoading = false }: SeeMoreMobileProps) {
    const { setQueue, silenceWorkaround } = usePlayer();
    const generateArtistLinks = useArtistLinks();
    const generateBPMLinks = useBpmLink();
    const generateKeyLinks = useKeyLinks();
    const generateGenreLinks = useGenreLinks();
    const generatePlaylistCategoryLinks = usePlaylistCategoryLinks();
    const errorHandler = useApiErrorHandler();

    const resource: any = null;

    const handlePlayMedia = useCallback(
        async (indexToPlay: number, play: boolean) => {
            switch (contentType) {
                case 'curatedSet': {
                    const { id } = data[indexToPlay];
                    try {
                        // Can't use SWR because it's a hook
                        /*
              TODO: Maybe try to check if SWR cache can help...
              to avoid a bit more fetch without the help of SWR?
            */
                        silenceWorkaround();
                        const { data: media } = await CuratedSetsApi.getCuratedSet(isDownload, +id);
                        if (!data) break;

                        if (play) {
                            const queueMedia = isDownload
                                ? (media as CuratedSetWithAlbum).albums.map((curatedSetAlbum) => downloadAlbumWithMediaToQueueItem(curatedSetAlbum))
                                : (media as CuratedSetWithMedia).media.map((curatedSetMedia) => streamMediaWithAlbumToQueueItem(curatedSetMedia));

                            setQueue(queueMedia, 0, { identifier: `${id}`, resource });
                        }
                        break;
                    } catch (error) {
                        errorHandler({ error });
                        break;
                    }
                }

                case 'exclusive-playlist': {
                    const { id } = data[indexToPlay];
                    try {
                        // Can't use SWR because it's a hook
                        // eslint-disable-next-line max-len
                        // TODO: Maybe try to check if SWR cache can help to avoid a bit more fetch without the help of SWR?
                        silenceWorkaround();
                        const { data: media } = await PlaylistsApi.getPlaylistDetail(isDownload, Number(id));
                        if (!data) break;

                        if (play) {
                            const queueMedia = isDownload
                                ? (media as PlaylistWithAlbum).albums.map((curatedSetAlbum) => downloadAlbumWithMediaToQueueItem(curatedSetAlbum))
                                : (media as PlaylistWithMedia).media.map((curatedSetMedia) => streamMediaWithAlbumToQueueItem(curatedSetMedia));
                            setQueue(queueMedia, 0, { identifier: `${id}`, resource });
                        }

                        break;
                    } catch (error: any) {
                        errorHandler({ error });
                        break;
                    }
                }
                case 'for-you-playlist':
                case 'user-playlist':
                    try {
                        const { id } = data[indexToPlay];
                        // Can't use SWR because it's a hook
                        // eslint-disable-next-line max-len
                        // TODO: Maybe try to check if SWR cache can help to avoid a bit more fetch without the help of SWR?
                        silenceWorkaround();
                        const { data: media } = await UserPlaylistApi.getUserPlaylistDetail(isDownload, `${id}`);
                        if (!data) break;

                        if (play) {
                            const queueMedia = isDownload
                                ? (media as UserPlaylistWithAlbum).albums.map((curatedSetAlbum) => downloadAlbumWithMediaToQueueItem(curatedSetAlbum))
                                : (media as UserPlaylistWithMedia).media.map((curatedSetMedia) => streamMediaWithAlbumToQueueItem(curatedSetMedia));

                            if (queueMedia) setQueue(queueMedia, 0, { identifier: `${id}`, resource });
                        }

                        break;
                    } catch (error) {
                        errorHandler({ error });
                        break;
                    }

                default: {
                    let queueItems: QueueItem[];
                    if (isDownload) {
                        queueItems = (data as DownloadAlbum[]).map((album) => downloadAlbumWithMediaToQueueItem(album));
                    } else {
                        queueItems = (data as MediaWithAlbum[]).map((media) => streamMediaWithAlbumToQueueItem(media));
                    }
                    setQueue(queueItems, indexToPlay);
                }
                    break;
            }
        },
        [contentType, resource, silenceWorkaround, isDownload, setQueue, errorHandler, data]
    );

    const getDescriptionMarkup = ({ artist, artists, bpm, key, actualKey, genre }: { artist: string; artists: Artist[]; bpm?: number; actualKey?: string; key?: string; genre?: Genre; }) => (
        <>
            <div className={styles['see-more-mobile__artist']}>{generateArtistLinks(artist, artists)}</div>
            <div className={styles['see-more-mobile__card-details']}>
                {bpm && <span>{generateBPMLinks(bpm)}</span>}
                {key && <span>{generateKeyLinks(actualKey, key)}</span>}
                {genre && <span>{generateGenreLinks(genre)}</span>}
            </div>
        </>
    );

    // eslint-disable-next-line max-len
    const getDescriptionMarkupPlaylistCategory = ({ artist, artists, bpm, actualKey, key, category }: { artist: string; artists: Artist[]; bpm?: number; actualKey?: string; key?: string; category?: Category; }) => (
        <>
            <div className={styles['see-more-mobile__artist']}>{generateArtistLinks(artist, artists)}</div>
            <div className={styles['see-more-mobile__card-details']}>
                {bpm && <span>{generateBPMLinks(bpm)}</span>}
                {key && <span>{generateKeyLinks(actualKey, key)}</span>}
                {category && <span>{generatePlaylistCategoryLinks(category)}</span>}
            </div>
        </>
    );

    const handleSubtitle = (item: DownloadAlbum | MediaWithAlbum | CuratedSet | Playlist | UserPlaylist | StreamCuratedSet) =>
        (item as DownloadAlbum | Playlist).genre ?? (item as MediaWithAlbum).album?.genre;

    const handleImage = (item: DownloadAlbum | MediaWithAlbum | CuratedSet | Playlist | UserPlaylist | StreamCuratedSet) =>
        (item as DownloadAlbum).cover_url ?? (item as MediaWithAlbum).album?.cover_url ?? (item as CuratedSet | Playlist | UserPlaylist).image_url;

    function renderSubtitle(item: DownloadAlbum | MediaWithAlbum | CuratedSet | Playlist | UserPlaylist | StreamCuratedSet) {
        if (contentType === 'curatedSet') {
            return (item as CuratedSet).curator;
        }
        if (contentType === 'exclusive-playlist') {
            return getDescriptionMarkupPlaylistCategory({ artist: (item as Playlist).artist, artists: (item as Playlist).artists, category: (item as Playlist).category });
        }
        if (contentType === 'user-playlist') {
            return getDescriptionMarkup({ artist: item.artist, artists: item.artists, bpm: undefined });
        }

        return getDescriptionMarkup({
            artist: item.artist,
            artists: item.artists,
            bpm: 'bpm' in item ? item.bpm : undefined,
            key: 'display_key' in item ? item.display_key : undefined,
            actualKey: 'key' in item ? item.key : undefined,
            genre: handleSubtitle(item)
        });
    }

    const ghostLoadingItems = useUniqueArray(20);

    return (
        <div className={styles['see-more-mobile']}>
            <ListGrid>
                {isLoading ? (
                    <div>
                        {ghostLoadingItems.map((uuid) => (
                            <GhostElement key={`see-more-mobile-${uuid}`} linesWidthArray={[90, 70, 40]} isRow squareWidth={56} lineHeight={12} />
                        ))}
                    </div>
                ) : (
                    data?.map((item, index) => (
                        <Fragment key={item.id}>
                            <ListGridItem
                                id={item.id}
                                title={item.title}
                                imageUrl={appendQueryParams(handleImage(item), { key: 'dw', value: 56 })}
                                imageUrl2x={appendQueryParams(handleImage(item), { key: 'dw', value: 112 })}
                                subtitle={renderSubtitle(item)}
                                isPlayable={isPlayable}
                                contentType={contentType}
                                song={isPlayable && (item as DownloadAlbum | MediaWithAlbum)}
                                onPlayItem={() => handlePlayMedia(index, true)}
                                link={!isPlayable && linkPrefix && `${linkPrefix}/${item.id}`}
                            />
                        </Fragment>
                    ))
                )}
            </ListGrid>
        </div>
    );
}
