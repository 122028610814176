import { FC, Fragment, useEffect } from 'react';
import { ArtistWithMediaCount } from '@bpm-web-app/stream-api-sdk';
import { useSearchArtist } from '@bpm-web-app/swr-hooks';
import { appendQueryParams, convertToPluralIfNeeded, useHubSwitch, useSupremeFilterParams, useUniqueArray, useViewport } from '@bpm-web-app/utils';
import { ArtistWithAlbumCount } from '@bpm-web-app/download-api-sdk';
import { CardGhostLoading } from '../../shared/card/card-ghost-loading';
import styles from './search-artists.module.css';
import SecondaryPageTitle from '../../shared/secondary-page-title/secondary-page-title';
import CardGrid from '../../shared/card-grid/card-grid';
import ArtistCardImage from '../../shared/artist-card-image/artist-card-image';
import ListGridItem from '../../shared/list-grid-item/list-grid-item';
import ListGrid from '../../shared/ui/list-grid/list-grid';
import { NoResultsBlock } from '../../shared/ui/no-results-block/no-results-block';
import { ListGridGhostLoading } from '../../shared/ui/list-grid/list-grid-ghost-loading';

export interface SearchArtistsProps {
    indexPage?: boolean;
    onCount: (count: number) => void;
    hasNoResults?: boolean;
}

export const SearchArtists: FC<SearchArtistsProps> = ({ indexPage, onCount, hasNoResults }) => {
    const { isDownload } = useHubSwitch();
    const query = useSupremeFilterParams(false);
    const { data, isLoading } = useSearchArtist(query);
    const searchArtist = data;
    const { isMobile } = useViewport();
    const ghostLoadingItems = useUniqueArray(12);

    useEffect(() => {
        onCount(data?.length || 0);
    }, [data?.length, onCount]);

    if (hasNoResults === true) {
        return null;
    }

    if (searchArtist?.length === 0) {
        return (
            <div className={styles['search-artists__intro-section']}>
                <SecondaryPageTitle title="Artists" counter="0" />
                <NoResultsBlock hasPadding>No Artists Available</NoResultsBlock>
            </div>
        );
    }

    return (
        <div className="spacing__window--horizontal">
            {isMobile && indexPage ? (
                <>
                    {isLoading && <ListGridGhostLoading elementsCount={8} isRow itemClass={styles['search-artists__mobile-loading-item']} lineHeight={10} squareClass={styles['search-artists__mobile-loading-item-round']} />}
                    {!isLoading && (
                        <ListGrid>
                            {searchArtist.map((artist) => (
                                <Fragment key={artist.id}>
                                    <ListGridItem
                                        id={artist.id}
                                        title={artist.name}
                                        subtitle={
                                            isDownload
                                                ? convertToPluralIfNeeded((artist as ArtistWithAlbumCount).album_count, 'Track', 'Tracks')
                                                : convertToPluralIfNeeded((artist as ArtistWithMediaCount).media_count, 'Track', 'Tracks')
                                        }
                                        rounded
                                        imageUrl={appendQueryParams(artist.artwork_url, { key: 'dw', value: 160 })}
                                        imageUrl2x={appendQueryParams(artist.artwork_url, { key: 'dw', value: 320 })}
                                        link={`/artist/${artist.slug}`}
                                        contentType="artist"
                                    />
                                </Fragment>
                            ))}
                        </ListGrid>
                    )}
                </>
            ) : (
                <div className="spacing__window--top">
                    <div className={styles['search-artists__intro-section']}>
                        <SecondaryPageTitle title="Artists" counter={searchArtist?.length.toString()} noPadding />
                    </div>
                    <CardGrid cardType="card-small">
                        {/* TODO: potentially refactor this if we decide to build CardGridGhostLoading */}
                        {isLoading
                            ? ghostLoadingItems.map((uuid) => (
                                <CardGhostLoading
                                    key={`card-ghost-${uuid}`}
                                    cardSize="small"
                                    lineHeight={20}
                                    linesWidthArray={[80, 40]}
                                    imageClass={styles['search-artists__loading-image']}
                                    itemClass={styles['search-artists__loading-item-round']}
                                />))
                            : searchArtist?.map((artist) => {
                                return (
                                    <ArtistCardImage
                                        key={artist.id}
                                        artist={artist}
                                    />
                                );
                            })}
                    </CardGrid>
                </div>
            )}
        </div>
    );
};

export default SearchArtists;
