import { SortByQueryOptions } from '@bpm-web-app/stream-api-sdk';
import { useFeaturedBpmOriginalMedia } from '@bpm-web-app/swr-hooks';
import {
    useHubSwitch,
} from '@bpm-web-app/utils';
import { useContext, useMemo, useEffect } from 'react';
import CardCarousel from '../shared/card-carousel/card-carousel';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import { GhostComponent } from '../shared';
import { AlbumCard } from '../shared/card/album-card/album-card';

export interface FeaturedBpmOriginalProps {
    title?: string;
    isDataLoaded?: boolean;
    setIsDataLoaded?: (loaded: boolean) => void;
}

export function FeaturedBpmOriginal({ title, isDataLoaded, setIsDataLoaded }: FeaturedBpmOriginalProps) {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { isDownload } = useHubSwitch();
    const { data, isLoading } = useFeaturedBpmOriginalMedia({
        limit: 20,
        sort_by: SortByQueryOptions.DateDesc,
        library: libraryProperty,
    });
    const searchFeaturedBpmOriginal = data?.data;

    useEffect(() => {
        if (setIsDataLoaded) {
            if (isLoading === false && searchFeaturedBpmOriginal) {
                setIsDataLoaded(true);
            } else {
                setIsDataLoaded(false);
            }
        }
    }, [isLoading, searchFeaturedBpmOriginal, setIsDataLoaded]);

    const shouldDisplayGhostLoading = useMemo(() => isDataLoaded === false || isLoading, [isDataLoaded, isLoading]);

    if (shouldDisplayGhostLoading) {
        return (<GhostComponent type="cards" elementsCount={10} title="Exclusives" cardSize="small" />);
    }

    return (
        <div>
            <CardCarousel title={title || 'Exclusives'} seeMorePath="/exclusives/all">
                {searchFeaturedBpmOriginal &&
                    searchFeaturedBpmOriginal.map((item) => (
                        <AlbumCard
                            key={item.id}
                            album={item}
                            albumsList={searchFeaturedBpmOriginal}
                            isDownload={isDownload} />
                    ))}
            </CardCarousel>
        </div>
    );
}

export default FeaturedBpmOriginal;
