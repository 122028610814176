import useSWR from 'swr';
import { CuratedSets, SearchCuratedSetsQuery } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';

export function useSearchCuratedSets(query: SearchCuratedSetsQuery) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR<Awaited<ReturnType<typeof CuratedSets['searchCuratedSets']>>>(['curated-sets', query, isDownload], () => CuratedSets.searchCuratedSets(isDownload, query));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}
