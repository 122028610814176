import { getSignupRedirectLink, useUniqueArray } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { GhostComponent } from '../ghost-component/ghost-component';
import styles from './sign-up-tracklist.module.css';
import { SquaredButton } from '../squared-button/squared-button';

const GHOST_ITEM_COUNT = 7;

export function SignUpTrackList() {
    const ghostElementsKeys = useUniqueArray(GHOST_ITEM_COUNT);
    const router = useRouter();

    const returnGhostList = (key: string, index: number,) => {
        return (<div style={index > 0 ? { opacity: 0.9 - (0.12 * index + 0.1), zIndex: 0 } : {}}> <GhostComponent hasShimmer={false} noPadding type="track-list-item" /></div>);
    };
    return (
        <div className={styles['sign-up-tracklist']}>
            <div className={styles['sign-up-tracklist__tracks']}>{ghostElementsKeys.map(returnGhostList)}</div>
            <div className={styles['sign-up-tracklist__button-container']}>
                <div className={styles['sign-up-tracklist__button']}>
                    <SquaredButton type="filled" label="Sign Up to See More" onPress={() => router.replace(getSignupRedirectLink())} />
                </div>
            </div>
        </div>
    );
}
