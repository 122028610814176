import { UserPlaylist as DownloadUserPlaylist } from '@bpm-web-app/download-api-sdk';
import { useGetUserPlaylistCategory, useUserPlaylistSetsByCatId } from '@bpm-web-app/swr-hooks';
import { useCallback, useMemo, useState } from 'react';
import { useHubSwitch } from '@bpm-web-app/utils';
import classNames from 'classnames';
import styles from './my-playlists-by-category-id.module.css';
import Title from '../../title/title';
import { PageHeader } from '../../shared/page-header/page-header';
import { LocalSearchSort } from '../../nav-my-playlist-categories/utils';
import { useUserPlaylists } from '../../shared/three-dots-sheet/useUserPlaylists';
import PlaylistsForm, { PlaylistsFormProps } from '../../playlists-form/playlists-form';
import { EmptyState, GhostComponent } from '../../shared';
import { MyPlaylistCard } from '../../shared/card/my-playlist-card/my-playlist-card';

export interface MyPlaylistsByCategoryIdProps {
    categoryId: number | string;
}

export function MyPlaylistsByCategoryId({ categoryId }: MyPlaylistsByCategoryIdProps) {
    const [searchTerm, setSearchTerm] = useState('');
    const { isDownload, hub } = useHubSwitch();
    const { data, isLoading } = useUserPlaylistSetsByCatId(isDownload, categoryId, true);
    const { createPlaylist } = useUserPlaylists(categoryId, true);
    const { data: currentCategoryData, isLoading: isCategoryLoading } = useGetUserPlaylistCategory(categoryId, isDownload);
    const [sortType, setSortType] = useState<LocalSearchSort>(undefined);
    const [openForm, setOpenForm] = useState<null | PlaylistsFormProps>(null);
    const contextMenuForm = () => <PlaylistsForm {...openForm} />;
    const currentCategory = useMemo(() => currentCategoryData?.data, [currentCategoryData]);
    const userPlaylistList = useMemo(() => {
        let playlistArray: DownloadUserPlaylist[] = [];
        if (!data?.data) {
            return [];
        }
        switch (sortType) {
            case 'most_recent':
                playlistArray = (data?.data as DownloadUserPlaylist[]).slice().sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
                break;
            case 'title_asc':
                playlistArray = (data?.data as DownloadUserPlaylist[]).slice().sort((a, b) => (a.title > b.title ? 1 : -1));
                break;
            case 'title_desc':
                playlistArray = (data?.data as DownloadUserPlaylist[]).slice().sort((a, b) => (a.title < b.title ? 1 : -1));
                break;
            default:
                playlistArray = (data?.data as DownloadUserPlaylist[]);
                break;
        }

        if (searchTerm.trim()) {
            playlistArray = playlistArray.filter(({ title }) => title.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        return playlistArray;
    }, [data, searchTerm, sortType]);

    const hasPlaylists = useMemo(() => userPlaylistList?.length !== 0, [userPlaylistList?.length]);

    const addPlaylist = useCallback(() => {
        setOpenForm({
            type: 'CreateNewPlaylist',
            text: 'Create custom playlists to organize your music.',
            formAction: createPlaylist,
            close: () => setOpenForm(null),
        });
    }, [createPlaylist]);

    if (!isCategoryLoading && !currentCategory) return null;

    return (
        <>
            <Title platform={hub} title="My Playlists" />
            <div className={classNames(styles['my-playlists-by-category'], 'spacing__window')}>
                {hasPlaylists ? (
                    <PageHeader
                        title={userPlaylistList ? `${currentCategory?.name || ''} (${userPlaylistList.length})` : (currentCategory?.name || '')}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        searchPlaceholder={`Search ${currentCategory?.name}`}
                        onSort={setSortType}
                        handleAdd={addPlaylist}
                        selectedSortType={sortType}
                    />
                ) : (
                    <PageHeader
                        title={userPlaylistList ? `${currentCategory?.name || ''} (${userPlaylistList.length})` : (currentCategory?.name || '')}
                    />
                )}
                {openForm && contextMenuForm()}
                {!hasPlaylists && <EmptyState title="Looks like you haven't created any playlists yet. " subtitle="Start building your own personalized playlists by adding your favorite tracks. Your playlists will appear here once you've created them." actionLabel="Create Playlist" noPadding onPress={addPlaylist} verticalMargins={16} />}
                {isLoading ? (
                    <GhostComponent type="cards" elementsCount={12} noPadding cardSize="user-playlist" linesWidthArray={[90, 70]} grid />
                ) : (
                    <div className={styles['my-playlists-by-category__grid']}>
                        {!isLoading &&
                            data?.data &&
                            userPlaylistList.map((item) => (
                                <MyPlaylistCard key={item.id} playlist={item} />
                            ))}
                    </div>)}
            </div>
        </>
    );
}

export default MyPlaylistsByCategoryId;
