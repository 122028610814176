import { ReactNode } from 'react';

import classNames from 'classnames';
import BreakpointView from '../shared/ui/breakpoint-view/breakpoint-view';
import SecondaryPageTitle from '../shared/secondary-page-title/secondary-page-title';

import styles from './see-more-layout.module.css';

type Props = {
    count?: number;
    pageTitle?: string;
    desktopChildren: ReactNode;
    mobileChildren: ReactNode;
};

export function SeeMoreSupremeLayout({ pageTitle, count = undefined, mobileChildren, desktopChildren }: Props) {
    return (
        <article className="spacing__window--top">
            {pageTitle ? (
                <div className={styles['see-more-layout__title']}>
                    <SecondaryPageTitle title={pageTitle} counter={`${count ?? ''}`} />
                </div>
            ) : null}
            <BreakpointView desktopChildren={<div className={classNames('spacing__window--horizontal', 'spacing--top')}>{desktopChildren}</div>} mobileChildren={<div className={classNames('spacing__window--horizontal', 'spacing--top')}>{mobileChildren}</div>} />
        </article>
    );
}

export default SeeMoreSupremeLayout;
