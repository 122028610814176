import { useGetMeSubscription, useGetSubscriptionPackage } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import AccountPlanUpgradeV2 from './change-v2';
import Loader from '../../../shared/ui/loader/loader';

export interface AccountPlanUpgradeProps {
    isCreate?: boolean;
    isContinuePlanMode?: boolean;
}

export function AccountPlanUpgrade({ isCreate, isContinuePlanMode }: AccountPlanUpgradeProps) {
    const router = useRouter();
    const { packageId } = router.query;
    const planId = Number(packageId);

    const { data: newPlan, isLoading: isLoadingNewPlan } = useGetSubscriptionPackage(planId);
    const { data: userData, isLoading: isLoadingUserData, mutate } = useGetMeSubscription(newPlan?.data?.platform.toString());

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    const currentSubscription = useMemo(() => ({ data: userData?.data!, mutate }), [userData, mutate]);

    if (isLoadingNewPlan || isLoadingUserData || !userData || !newPlan) {
        return <Loader />;
    }

    return <AccountPlanUpgradeV2 isCreate={isCreate} isContinuePlanMode={isContinuePlanMode} plan={newPlan.data} currentSubscription={currentSubscription} />;
}

export default AccountPlanUpgrade;
