import { useDownloadAlbumById, useRemixesAlbum } from '@bpm-web-app/swr-hooks';
import { convertToPluralIfNeeded, useHideSwitch, useHubSwitch, useUniqueArray } from '@bpm-web-app/utils';
import { useMemo } from 'react';
import classNames from 'classnames';
import GhostElement from '../../shared/ghost-element/ghost-element';
import styles from './remixes-media-detail.module.css';
import { PageNotFound } from '../page-not-found/page-not-found';
import Title from '../../title/title';
import { PrimaryPageTitle } from '../../shared';
import { TrackListSupreme } from '../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../shared/track-list/supreme/track-list-supreme-helpers';
import { TrackListLoadingSupreme } from '../../shared/track-list/ghost-loading/track-list-loading-supreme';

const SIMILAR_LINES_WIDTH = [90, 70];

export interface RemixesMediaDetailProps {
    mediaId: number;
    isMaxiPlayer?: boolean;
}

export function RemixesMediaDetail({ mediaId: id, isMaxiPlayer = false }: RemixesMediaDetailProps) {
    useHideSwitch(isMaxiPlayer);

    const { isDownload, hub } = useHubSwitch();
    const { data: album, isLoading: isLoadingAlbum, error: albumError } = useDownloadAlbumById(id, isDownload);
    const { data: remixesAlbum, isLoading: isLoadingRemixesAlbum, error: remixesAlbumError, mutate: mutateRemixesAlbums } = useRemixesAlbum(id, isDownload);

    const isLoading = useMemo(() => (isDownload ? isLoadingAlbum || isLoadingRemixesAlbum : false), [isDownload, isLoadingAlbum, isLoadingRemixesAlbum]);

    const error = useMemo(() => (isDownload ? albumError || remixesAlbumError : false), [albumError, isDownload, remixesAlbumError]);

    const remixesMediaDetail = remixesAlbum?.data;
    const originalMediaDetail = album && album.data ? [album?.data] : [];

    if (id === undefined || error) return <PageNotFound />;

    return (
        <>
            <Title platform={hub} title="Find Remixes" />
            <div
                className={classNames('spacing__window', {
                    [styles['remixes-media-detail--is-maxi-player']]: isMaxiPlayer,
                })}
            >
                <PrimaryPageTitle title="Original Track" noPadding />
                {isLoading ? (
                    <div className={styles['remixes-media-detail__loading']}>
                        <TrackListLoadingSupreme amount={1} preset={TrackListPresetSupreme.Download} />
                    </div>
                ) : (
                    <TrackListSupreme
                        list={originalMediaDetail}
                        preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                        isSortable={false}
                        onDownloadRevalidate={(downloadMedia) => {
                            if (downloadMedia) {
                                // eslint-disable-next-line no-param-reassign
                                downloadMedia.download_count += 1;
                                mutateRemixesAlbums(remixesAlbum, { revalidate: false });
                            }
                        }}
                    />
                )}

                <PrimaryPageTitle title={convertToPluralIfNeeded(remixesMediaDetail?.length || 0, 'Remix', 'Remixes', true)} noPadding />

                {remixesMediaDetail && !isLoading ? (
                    <TrackListSupreme
                        list={remixesMediaDetail}
                        preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                        isSortable={false}
                        onDownloadRevalidate={(downloadMedia) => {
                            if (downloadMedia) {
                                // eslint-disable-next-line no-param-reassign
                                downloadMedia.download_count += 1;
                                mutateRemixesAlbums(remixesAlbum, { revalidate: false });
                            }
                        }}
                    />
                ) : (
                    <TrackListLoadingSupreme amount={20} preset={TrackListPresetSupreme.Download} />
                )}
            </div>
        </>
    );
}

export default RemixesMediaDetail;
