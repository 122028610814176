import { useGetFeaturedEditors } from '@bpm-web-app/swr-hooks';
import { useContext, useEffect, useMemo } from 'react';
import { LibraryTabsContext } from 'libs/utils/src/lib/library-tabs.context';
import { useUniqueArray } from '@bpm-web-app/utils';
import { ArtistCardImage, CardCarousel, CardGrid, GhostComponent } from '../shared';
import SeeMoreSupremeLayout from '../see-more-layout/see-more-supreme-layout';
import CardGhostLoading from '../shared/card/card-ghost-loading';

interface FeaturedEditorsProps {
    title?: string;
    isDataLoaded?: boolean;
    setIsDataLoaded?: (loaded: boolean) => void;
    indexPage?: boolean;
}

export function FeaturedEditors({
    title = 'Remixers & Editors',
    isDataLoaded,
    setIsDataLoaded,
    indexPage = false,
}: FeaturedEditorsProps) {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { data, isLoading } = useGetFeaturedEditors(libraryProperty);
    const getFeaturedEditorsData = data?.data;
    const ghostLoadingItems = useUniqueArray(20);

    useEffect(() => {
        if (setIsDataLoaded) {
            if (isLoading === false && getFeaturedEditorsData) {
                setIsDataLoaded(true);
            } else {
                setIsDataLoaded(false);
            }
        }
    }, [isLoading, getFeaturedEditorsData, setIsDataLoaded]);

    const shouldDisplayGhostLoading = useMemo(() => isDataLoaded === false || isLoading, [isDataLoaded, isLoading]);

    if (shouldDisplayGhostLoading && indexPage === false) {
        return (<GhostComponent type="cards" elementsCount={10} title={title} cardSize="small" />);
    }

    if (indexPage) {
        return (
            <div>
                <SeeMoreSupremeLayout
                    pageTitle={title}
                    mobileChildren={
                        <CardGrid cardType="artist-card">
                            {isLoading && ghostLoadingItems.map((uuid) => <CardGhostLoading key={`artist-card-ghost-${uuid}`} cardSize="small" />)}
                            {!isLoading &&
                                getFeaturedEditorsData?.map((item) => (
                                    <ArtistCardImage
                                        key={item.id}
                                        artist={item}
                                    />
                                ))}
                        </CardGrid>
                    }
                    desktopChildren={
                        <CardGrid cardType="artist-card">
                            {isLoading && ghostLoadingItems.map((uuid) => <CardGhostLoading key={`artist-card-ghost-${uuid}`} cardSize="small" />)}
                            {!isLoading &&
                                getFeaturedEditorsData?.map((item) => (
                                    <ArtistCardImage
                                        key={item.id}
                                        artist={item}
                                    />
                                ))}
                        </CardGrid>
                    }
                />
            </div>
        );
    }

    return (
        <div>
            <CardCarousel title={title} seeMorePath="/all-editors">
                {getFeaturedEditorsData &&
                    getFeaturedEditorsData.map((item) => (
                        <ArtistCardImage
                            key={item.id}
                            artist={item}
                        />
                    ))}
            </CardCarousel>
        </div>
    );
}
