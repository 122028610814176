import { ReactNode } from 'react';

import HubSwitch from '../hub-switch/hub-switch';
import Title, { TitleProps } from '../title/title';
import Toolbar from '../toolbar/toolbar';

export const PageLayout = ({ children, platform, title, hasHubSwitch = true }: Partial<TitleProps> & { hasHubSwitch?: boolean; children: ReactNode }) => (
    <>
        {!!platform && <Title platform={platform} title={title} />}
        {hasHubSwitch && (
            <Toolbar stickToHeader>
                <HubSwitch />
            </Toolbar>
        )}
        {children}
    </>
);
