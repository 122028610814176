import useSWR from 'swr';
import { Media } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';

export function useCategories(library: string, withGenres?: boolean) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR(
        [
            'categories',
            {
                isDownload,
                persist: true,
                withGenres,
                library,
            },
        ],
        () => Media.getCategories(isDownload, library, withGenres)
    );

    return {
        categories: data?.data,
        isLoading: !error && !data,
        error,
    };
}
