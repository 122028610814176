import 'intersection-observer';

import ReactTooltip from 'react-tooltip';
import '@bpm-web-app/components/src/styles/index.css';
import '../styles/index.css';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Analytics, HistoryProvider, HubSwitchProvider, useWindowResizing, ViewportProvider, startBugsnag, useViewport, setGiveawayCookie, UserSettingsProvider, setCouponStorage, showToast } from '@bpm-web-app/utils';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/grid';
import {
    FiltersProvider,
    LibraryTabsProvider,
    NavProvider,
    AppToast,
    SupremeHeader,
    FiltersContext,
    Nav,
    NavContext,
    SupremePlayer,
    AppNotifications,
    BPMSupremeQ31Update,
    PlayerProvider
} from '@bpm-web-app/components';
import {
    ThreeDotsSheet,
    ThreeDotsSheetProvider,
    ThreeDotsSheetContext,
    SessionProvider,
    ActionModalProvider,
    ThreeDotsSheetVersionsProvider,
    ThreeDotsSheetVersions,
    ThreeDotsSheetVersionsContext,
    ContextMenuContext,
    ContextMenu,
    ContextMenuProvider,
    DeviceVerification,
    SignUpModal,
    SubscriptionInfoModal,
    Loader,
    ArtistTooltip,
} from '@bpm-web-app/components/shared';

import 'focus-visible/dist/focus-visible.min';
import { SWRConfigProvider } from '@bpm-web-app/swr-hooks';

import { useEffect } from 'react';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import Script from 'next/script';
import classNames from 'classnames';
import { ErrorMessage } from '@bpm-web-app/components/src/lib/pages';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { DraggableComponent } from '@bpm-web-app/components/src/lib/shared/draggable-component/draggable-component';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import TiktokPixel from 'tiktok-pixel';
import { CreatePlaylistFormProvider } from '@bpm-web-app/components/src/lib/playlists-form/create-playlist-form-context';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/dot-notation
const ErrorBoundary = startBugsnag(process.env['NEXT_PUBLIC_BUGSNAG_SUPREME_API_KEY']!);

function App({ Component, pageProps }: AppProps) {
    useWindowResizing(
        () => {
            document.body.classList.add('isResizing');
        },
        () => {
            document.body.classList.remove('isResizing');
        },
        []
    );

    useEffect(() => {
        Analytics.initialize();
        TiktokPixel.init('CMNE1ARC77UF4ULPFOTG', {}, { debug: false });
        setGiveawayCookie();
        const code = setCouponStorage();
        if (code) {
            showToast({
                type: 'success',
                message: `Promo code ${code} has been applied! Your discount will be reflected at checkout.` as any
            });
        }
    }, []);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Modal.setAppElement(document.getElementById('__next')!);
    }, []);

    const { isMobile } = useViewport();

    const { isReady } = useRouter();
    if (!isReady) {
        return <Loader />;
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorMessage}>
            <Head>
                <title>BPM Supreme</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#f73e00" />
                <meta name="msapplication-TileColor" content="#f73e00" />
                <meta name="theme-color" content="#121212" />
                <script type="text/javascript" async src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Ynf4t6" />
            </Head>
            <HistoryProvider>
                <ActionModalProvider>
                    <UserSettingsProvider>
                        <SWRConfigProvider>
                            <SessionProvider>
                                <HubSwitchProvider>
                                    <LibraryTabsProvider>
                                        <PlayerProvider>
                                            <ContextMenuProvider>
                                                <ThreeDotsSheetProvider>
                                                    <ThreeDotsSheetVersionsProvider>
                                                        <ViewportProvider>
                                                            <NavProvider>
                                                                <FiltersProvider>
                                                                    <DndProvider backend={HTML5Backend}>
                                                                        <DraggableComponent />
                                                                        <CreatePlaylistFormProvider>
                                                                            <DeviceVerification platform="supreme" />
                                                                            <SignUpModal platform="supreme" />
                                                                            <SubscriptionInfoModal showPriceIncreaseIfAvailable />
                                                                            <BPMSupremeQ31Update />
                                                                            <SupremeHeader />
                                                                            <Nav />
                                                                            <ContextMenuContext.Consumer>
                                                                                {(value) => {
                                                                                    return (value.renderLocation === 'app' || value.renderLocation === 'action-modal' || value.renderLocation === 'sidebar' ? <ContextMenu /> : null);
                                                                                }}
                                                                            </ContextMenuContext.Consumer>
                                                                            <ThreeDotsSheetContext.Consumer>
                                                                                {(value) => (value.actionTypeId !== -1 && value.renderLocation === 'app' ? <ThreeDotsSheet /> : null)}
                                                                            </ThreeDotsSheetContext.Consumer>
                                                                            <ThreeDotsSheetVersionsContext.Consumer>
                                                                                {(value) => (value.show ? <ThreeDotsSheetVersions {...value} /> : null)}
                                                                            </ThreeDotsSheetVersionsContext.Consumer>
                                                                            <SupremePlayer />
                                                                            <FiltersContext.Consumer>
                                                                                {(filterCtx) => (
                                                                                    <NavContext.Consumer>
                                                                                        {(navCtx) => (
                                                                                            <main
                                                                                                id="main"
                                                                                                className={classNames('main', {
                                                                                                    'main--nav-open': navCtx.isNavOpen,
                                                                                                    'main--nav-hidden': navCtx.isNavHidden,
                                                                                                    'main--filters-open': filterCtx.isFiltersOpen,
                                                                                                })}
                                                                                            >
                                                                                                <Script
                                                                                                    src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
                                                                                                    strategy="afterInteractive"
                                                                                                />
                                                                                                <Script strategy="afterInteractive">
                                                                                                    {`window.dataLayer = window.dataLayer || [];
                                                                                                    function gtag(){dataLayer.push(arguments);}
                                                                                                    gtag('js', new Date());
                                                                                                    gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}');
                                                                                                `.trim()}
                                                                                                </Script>
                                                                                                <Script id="facebook-analytics" strategy="afterInteractive">
                                                                                                    {`
                                                                                                    !function (f, b, e, v, n, t, s) {
                                                                                                        if (f.fbq)
                                                                                                        return;
                                                                                                        n = f.fbq = function () {
                                                                                                        n.callMethod ?
                                                                                                            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                                                                                                        };
                                                                                                        if (!f._fbq)
                                                                                                        f._fbq = n;
                                                                                                        n.push = n;
                                                                                                        n.loaded = !0;
                                                                                                        n.version = '2.0';
                                                                                                        n.queue = [];
                                                                                                        t = b.createElement(e);
                                                                                                        t.async = !0;
                                                                                                        t.src = v;
                                                                                                        s = b.getElementsByTagName(e)[0];
                                                                                                        s.parentNode.insertBefore(t, s)
                                                                                                    }(window,
                                                                                                        document, 'script', ' https://connect.facebook.net/en_US/fbevents.js');

                                                                                                    fbq('init', '${process.env.NEXT_PUBLIC_FB_ID}');
                                                                                                    fbq('track', "PageView");
                                                                                                `.trim()}
                                                                                                </Script>
                                                                                                <Component {...pageProps} />
                                                                                            </main>
                                                                                        )}
                                                                                    </NavContext.Consumer>
                                                                                )}
                                                                            </FiltersContext.Consumer>
                                                                        </CreatePlaylistFormProvider>
                                                                    </DndProvider>
                                                                </FiltersProvider>
                                                            </NavProvider>
                                                        </ViewportProvider>
                                                    </ThreeDotsSheetVersionsProvider>
                                                </ThreeDotsSheetProvider>
                                            </ContextMenuProvider>
                                        </PlayerProvider>
                                        <ArtistTooltip />
                                        {!isMobile ? <ReactTooltip
                                            place="top"
                                            effect="solid"
                                            delayShow={400}
                                        /> : null
                                        }
                                        <AppToast />
                                        <AppNotifications />
                                    </LibraryTabsProvider>
                                </HubSwitchProvider>
                            </SessionProvider>
                        </SWRConfigProvider>
                    </UserSettingsProvider>
                </ActionModalProvider>
            </HistoryProvider>
        </ErrorBoundary>
    );
}

export default dynamic(() => Promise.resolve(App), { ssr: false });
