import { useCallback, useEffect, useState } from 'react';

export function useIsTouchDevice() {
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    const checkTouchDevice = useCallback(() => {
        if (('ontouchstart' in window) || (navigator.maxTouchPoints > 0)) {
            setIsTouchDevice(true);
        }
    }, [setIsTouchDevice]);

    useEffect(() => {
        checkTouchDevice();
    }, [checkTouchDevice]);

    return isTouchDevice;
}
