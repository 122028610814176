import { SortByQueryOptions } from '@bpm-web-app/stream-api-sdk';
import { useFeatured } from '@bpm-web-app/swr-hooks';
import { DEFAULT_BROWSE_CARD_LIMIT, useHubSwitch } from '@bpm-web-app/utils';
import { useContext, useEffect, useMemo } from 'react';
import CardCarousel from '../shared/card-carousel/card-carousel';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import { GhostComponent } from '../shared';
import { AlbumCard } from '../shared/card/album-card/album-card';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FeaturedProps {
    isDataLoaded?: boolean;
    seeMorePath?: string
    setIsDataLoaded?: (loaded: boolean) => void;
}

export function Featured({ isDataLoaded, setIsDataLoaded, seeMorePath }: FeaturedProps) {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { isDownload } = useHubSwitch();

    /* note: limit is NOT supported by the Stream api call - we need to slice the array manually! */
    const { data, isLoading } = useFeatured({
        limit: 20,
        sort_by: SortByQueryOptions.DateDesc,
        library: libraryProperty,
    });

    const featured = data?.data.slice(0, DEFAULT_BROWSE_CARD_LIMIT);

    useEffect(() => {
        if (setIsDataLoaded) {
            if (isLoading === false) {
                setIsDataLoaded(true);
            } else {
                setIsDataLoaded(false);
            }
        }
    }, [isLoading, setIsDataLoaded]);

    const shouldDisplayGhostLoading = useMemo(() => isDataLoaded === false || isLoading, [isDataLoaded, isLoading]);

    return (
        <div>
            {shouldDisplayGhostLoading ? (
                <GhostComponent type="cards" elementsCount={10} title="New Music" contentType={isDownload ? 'download:album' : 'track'} />
            ) : featured && featured?.length > 0 ? (
                <CardCarousel title="New Music" seeMorePath={seeMorePath || '/new-music/all'}>
                    {featured.map((album) => (
                        <AlbumCard
                            key={album.id}
                            isDownload={isDownload}
                            album={album}
                            albumsList={featured}
                        />
                    ))
                    }
                </CardCarousel>
            ) : null}
        </div>
    );
}

export default Featured;
