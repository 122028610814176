import { TrackListLoadingSupreme } from '@bpm-web-app/components/src/lib/shared/track-list/ghost-loading/track-list-loading-supreme';
import { TrackListPresetSupreme } from '@bpm-web-app/components/src/lib/shared/track-list/supreme/track-list-supreme-helpers';
import { ListWrapper } from '../list-wrapper/list-wrapper';

export function ListLoading() {
    return (
        <ListWrapper>
            <TrackListLoadingSupreme amount={10} preset={TrackListPresetSupreme.DownloadMaxi} />
        </ListWrapper>
    );
}
