import { parseSeconds, HLSPlayer } from '@bpm-web-app/utils';
import { usePlayer, usePlayerDuration } from '../../player-context';
import { PlayerWaveSupreme } from '../../shared/player-wave/player-wave-supreme';
import { VolumeControl } from '../../volume-control/volume-control';
import styles from './wave.module.css';

export const Wave = () => {
    const { currentTrack, isPreviewTrack } = usePlayer();
    const { elapsed } = usePlayerDuration();
    const remainingTime = isPreviewTrack ? currentTrack?.estimated_duration : HLSPlayer.getDuration();

    return (
        <>
            <div className={styles['mini-player__wave']}>
                <p className={styles['mini-player__elapsed']}>{parseSeconds(elapsed)}</p>
                <PlayerWaveSupreme variant="mini" />
                <p className={styles['mini-player__duration']}>{!!remainingTime && parseSeconds(remainingTime)}</p>
            </div>
            <div className={styles['mini-player__volume-control']}>
                <VolumeControl />
            </div>
        </>
    );
};
