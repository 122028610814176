import ReactTooltip from 'react-tooltip';
import { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { useIsTouchDevice } from '@bpm-web-app/utils';
import styles from './artist-tooltip.module.css';
import { ArtistTooltipContent } from './artist-tooltip-content';

export function ArtistTooltip() {
    const ref = useRef<ReactTooltip>(null);
    const [isHidden, setIsHidden] = useState<boolean>(false);
    const [ready, setReady] = useState<boolean>(false);
    const isTouchDevice = useIsTouchDevice();

    const handleHideMomentarily = useCallback(() => {
        setIsHidden(true);
        setTimeout(() => {
            setIsHidden(false);
        }, 200);
    }, []);

    if (isHidden || isTouchDevice) return null;

    return (
        <ReactTooltip
            ref={ref}
            clickable
            effect="solid"
            delayHide={250}
            delayShow={300}
            delayUpdate={500}
            afterShow={() => setReady(false)}
            // afterHide={handleResetTooltipValues}
            // eslint-disable-next-line react/no-unstable-nested-components
            getContent={(contentObj) => {
                if (contentObj) {
                    return <ArtistTooltipContent
                        key={contentObj}
                        contentObj={contentObj}
                        onHide={handleHideMomentarily}
                        onReady={setReady} />;
                }
                return null;
            }}
            id="artist-tooltip"
            className={classNames(styles['artist-tooltip-reset-padding'], { [styles['artist-tooltip--hidden']]: !ready })}
            place="top"
        />
    );
}
