/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { QueueItem } from '@bpm-web-app/utils';
import classNames from 'classnames';
import styles from '../../supreme-maxi-player.module.css';
import { AppLink } from '../../../shared';

export function TrackTitle({ currentTrack }: { currentTrack: QueueItem }) {
    const startIndex = currentTrack.artist_remixer ? currentTrack.title.indexOf(currentTrack.artist_remixer.name) : -1;

    if (currentTrack.artist_remixer && startIndex > -1) {
        const endIndex = startIndex + currentTrack.artist_remixer!.name.length;

        // Extract parts of the title
        const beforeLink = currentTrack.title.substring(0, startIndex);
        const linkText = currentTrack.title.substring(startIndex, endIndex);
        const afterLink = currentTrack.title.substring(endIndex);
        const artistUrl = `/artist/${currentTrack.artist_remixer!.slug}`;
        const artist = currentTrack.artist_remixer;
        return (
            <h1 className={styles['maxi-player__desktop-player-title']}>
                <span>{beforeLink}</span>
                <AppLink href={artistUrl}>
                    <a
                        data-for="artist-tooltip"
                        data-tip={`${artist.name}|${artist.slug}|${artist.id}|${artist.artwork_url}`}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className={classNames('underline-link')}
                        role="link"
                        tabIndex={-5}>
                        {linkText}
                    </a>
                </AppLink>
                <span>{afterLink}</span>
            </h1>
        );
    }

    return (
        <h1 className={styles['maxi-player__desktop-player-title']} data-tip={currentTrack.title} data-tip-show>
            {currentTrack.title}
        </h1>
    );
}
/* eslint-enable jsx-a11y/anchor-is-valid */
/* eslint-enable jsx-a11y/click-events-have-key-events */
