import { useInfiniteSearchAlbum } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import { Artist } from '@bpm-web-app/stream-api-sdk';
import { appendQueryParams, useHubSwitch, useSupremeFilterParams, useUniqueArray, useViewport } from '@bpm-web-app/utils';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { Album } from '@bpm-web-app/download-api-sdk';
import styles from './search-albums.module.css';
import SecondaryPageTitle from '../../shared/secondary-page-title/secondary-page-title';
import CardGrid from '../../shared/card-grid/card-grid';
import Dropdown from '../../dropdown/dropdown';
import ListGrid from '../../shared/ui/list-grid/list-grid';
import ListGridItem from '../../shared/list-grid-item/list-grid-item';
import GhostElement from '../../shared/ghost-element/ghost-element';
import { NoResultsBlock } from '../../shared/ui/no-results-block/no-results-block';
import { APISortingKeys, apiSortOptionsWithRelevance, useApiSort } from '../../sort-options-sheet/sort-options-sheet';
import { useArtistLinks } from '../../artist-link/artist-link';
import { AlbumCard } from '../../shared/card/album-card/album-card';
import SeeMore from '../../shared/see-more-button/see-more-btn';
import { GhostComponent } from '../../shared';

const ALBUMS_LINES_WIDTH = [90, 70];

export interface SearchAlbumsProps {
    indexPage?: boolean;
    onCount: (count: number) => void;
    hasNoResults?: boolean;
}

export function SearchAlbums({ indexPage, onCount, hasNoResults }: SearchAlbumsProps) {
    const dropdownOptions = useMemo(() => apiSortOptionsWithRelevance.map((option) => ({ label: option.label, value: option.actionType as string })), []);

    const apiSortHandler = useApiSort();
    const router = useRouter();
    const generateArtistLinks = useArtistLinks();
    const { sortBy } = router.query;
    const { isDownload } = useHubSwitch();
    const query = useSupremeFilterParams(true, { limit: 10 });

    const { data, isLoadingInitialData, isLoadingMore, isLastPage, setSize } = useInfiniteSearchAlbum(query, true);

    const paginatedAlbumsList = data?.map(({ data: paginatedResponse }) => paginatedResponse);
    const flatAlbumsList = useMemo(() => (paginatedAlbumsList ? [].concat(...paginatedAlbumsList) : []) as Album[], [paginatedAlbumsList]);
    const pagination = data?.[0]?.pagination;

    const { isMobile } = useViewport();

    const loadMoreAlbums = useCallback(() => {
        if (isLoadingMore) return;
        setSize((prevSize) => prevSize + 1);
    }, [isLoadingMore, setSize]);

    const ghostLoadingItems = useUniqueArray(20);

    useEffect(() => {
        onCount(pagination?.total || 0);
    }, [flatAlbumsList, onCount, pagination?.total]);

    if (isLoadingInitialData || !flatAlbumsList) {
        if (isMobile) {
            return (
                <div className="spacing__window">
                    <div className={styles['search-albums__mobile-loading']}>
                        {ghostLoadingItems.map((uuid) => (
                            <GhostElement isRow key={`search-albums-${uuid}`} itemClass={styles['search-albums__mobile-loading-item']} linesWidthArray={ALBUMS_LINES_WIDTH} squareWidth={56} />
                        ))}
                    </div>
                </div>
            );
        }

        return (
            <div className={indexPage ? 'spacing__window--horizontal' : ''}>
                <GhostComponent
                    type="cards"
                    key="search-albums-ghost-carousel"
                    elementsCount={10}
                    cardSize="small"
                    grid={indexPage}
                    noPadding={indexPage}
                    lineHeight={17}
                    title="Albums"
                />
            </div>
        );
    }

    if (hasNoResults) {
        return null;
    }

    if (flatAlbumsList.length === 0) {
        return (
            <div className={styles['search-albums__no-results-section']}>
                <SecondaryPageTitle title="Albums" counter="0" />
                <NoResultsBlock hasPadding>No Albums Available</NoResultsBlock>
            </div>
        );
    }

    const getDescriptionMarkupMobile = (artist: string, artists: Artist[]) => (
        <div className={styles['search-albums__artist']}>{generateArtistLinks(artist, artists)}</div>
    );

    return (
        <article>
            {isMobile && indexPage ? (
                <div className="spacing__window--horizontal">
                    <ListGrid>
                        {flatAlbumsList.map((album) => (
                            <Fragment key={album.id}>
                                <ListGridItem
                                    id={album.id}
                                    title={album.title}
                                    subtitle={getDescriptionMarkupMobile(album.artist, album.artists)}
                                    imageUrl={appendQueryParams(album.cover_url, { key: 'dw', value: 160 })}
                                    imageUrl2x={appendQueryParams(album.cover_url, { key: 'dw', value: 320 })}
                                    link={`/album/${album.id}`}
                                    contentType={isDownload ? 'download:album' : 'stream:album'}
                                />
                            </Fragment>
                        ))}
                    </ListGrid>
                </div>
            ) : (
                <div className="spacing__window">
                    <div className={styles['search-albums__header']}>
                        <SecondaryPageTitle title="Albums" counter={`${pagination?.total || 0}`} noPadding />
                        <Dropdown<string> value={(sortBy || 'trending') as string} options={dropdownOptions} onClick={(k) => apiSortHandler(k as APISortingKeys)} />
                    </div>
                    <CardGrid cardType="card-small">
                        {flatAlbumsList.map((album) => (
                            <AlbumCard
                                key={album.id}
                                isDownload={isDownload}
                                album={album}
                                albumsList={flatAlbumsList}
                            />
                        ))}
                    </CardGrid>
                </div>
            )}
            {indexPage && !isLastPage && !isLoadingMore ? (
                <SeeMore expand={false} variant="text" onClick={loadMoreAlbums} />
            ) : null}
        </article>
    );
}

export default SearchAlbums;
