import { appendQueryParams } from '@bpm-web-app/utils';
import styles from './player-background.module.css';

interface PlayerBackgroundProps {
    coverUrl: string;

}

export function PlayerBackground({ coverUrl }: PlayerBackgroundProps) {
    return (
        <div className={styles['player-background__container']}>
            <div
                className={styles['player-background__image']}
                style={{
                    '--image-url': `url(${appendQueryParams(coverUrl, {
                        key: 'dw',
                        value: 480,
                    })} )`,
                } as React.CSSProperties}
            />
        </div>
    );
}
