import { SubscriptionPackage } from '@bpm-web-app/supreme-api-sdk';

export function trackKlaviyoProduct(plan: SubscriptionPackage) {
    if (plan.is_current) {
        return;
    }
    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
    const learnq = (window as any)._learnq || [];
    const item = {
        ProductName: plan.name,
        ProductID: plan.id,
        Platform: plan.platform,
        Library: plan.library,
        SKU: `${plan.platform}-${plan.name}-${plan.id}`
    };
    learnq.push(['track', 'Viewed Product', item]);
}
