import { CuratedSet, Album, MediaWithAlbum, ArtistWithMediaCount } from '@bpm-web-app/stream-api-sdk';
import { Album as DownloadAlbum, ArtistWithAlbumCount, CuratedSet as DownloadCuratedSet, Media as DownloadMedia, Playlist } from '@bpm-web-app/download-api-sdk';
import { useHubSwitch } from '@bpm-web-app/utils';
import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import CardCarousel from '../../../shared/card-carousel/card-carousel';
import ArtistCardImage from '../../../shared/artist-card-image/artist-card-image';
import TrackList, { TrackListPreset } from '../../../shared/track-list/track-list';
import styles from './favorites-desktop.module.css';
import { SecondaryPageTitle } from '../../../shared/secondary-page-title/secondary-page-title';
import { AppLink } from '../../../shared/app-link/app-link';
import { EmptyState, PrimaryPageTitle } from '../../../shared';
import { PlaylistCard } from '../../../shared/card/playlist-card/playlist-card';
import { AlbumCard } from '../../../shared/card/album-card/album-card';
import { CuratedSetCard } from '../../../shared/card/curated-set-card/curated-set-card';
import SeeMore from '../../../shared/see-more-button/see-more-btn';
import { EmptyStateProps } from '../../../shared/empty-state/types';
import { TrackListSupreme } from '../../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../../shared/track-list/supreme/track-list-supreme-helpers';

interface FavoritesDesktopProps {
    favAlbums: Album[] | null;
    favMedia: MediaWithAlbum[] | DownloadAlbum[];
    favPlaylists: Playlist[];
    favCuratedSets: CuratedSet[] | DownloadCuratedSet[];
    favArtists: ArtistWithAlbumCount[] | ArtistWithMediaCount[];
    onDownloadAlbumsRevalidate?: (downloadMedia?: DownloadMedia) => void;
}

export interface FavoriteEmptySpaceProps {
    text: string;
    emptyStateProps: EmptyStateProps;
}
export const FAVORITE_EMPTY_STATES: FavoriteEmptySpaceProps[] = [
    {
        text: 'Artists',
        emptyStateProps: {
            title: "Looks like you haven't followed any artists yet.",
            subtitle: 'Follow artists to be notified as soon as they release new music and improve recommendations.',
            icon: 'add-person',
            actionLabel: 'View Top Artists',
            href: 'trending'
        },
    },
    {
        text: 'Songs',
        emptyStateProps: {
            title: "Looks like you haven't saved any songs yet.",
            subtitle: 'Save songs to reference later, organize your favorites, and improve recommendations.',
            icon: 'heart-outline-icon',
            actionLabel: 'Explore Trending Tracks',
            href: 'trending'
        },
    },
    {
        text: 'Albums',
        emptyStateProps: {
            title: "Looks like you haven't saved any albums yet.",
            subtitle: 'Save albums to reference later, organize your favorites, and improve recommendations.',
            icon: 'heart-outline-icon',
            actionLabel: 'Explore Trending Tracks',
            href: 'trending'
        },
    },
    {
        text: 'Saved Playlists',
        emptyStateProps: {
            title: "Looks like you haven't saved any playlists yet.",
            subtitle: 'Save playlists to get notified when they’re updated with new music.',
            icon: 'heart-outline-icon',
            actionLabel: 'Explore Exclusive Playlists',
            href: 'exclusive-playlists'
        },
    },
    {
        text: 'Saved Curated Sets',
        emptyStateProps: {
            title: "Looks like you haven't saved any curated sets yet.",
            subtitle: 'Save Curated Sets and get notified when they’re updated with new music.',
            icon: 'heart-outline-icon',
            actionLabel: 'Explore Curated Sets',
            href: 'curated-sets'
        },
    }
];

export default function FavoritesDesktop({ favAlbums, favMedia, favPlaylists, favCuratedSets, favArtists, onDownloadAlbumsRevalidate }: FavoritesDesktopProps) {
    const { isDownload } = useHubSwitch();
    const previewFavAlbums = useMemo(() => favAlbums?.slice(0, 10), [favAlbums]);
    const previewFavMedia = useMemo(() => favMedia?.slice(0, 10), [favMedia]);
    const previewFavPlaylists = useMemo(() => favPlaylists?.slice(0, 10), [favPlaylists]);
    const previewFavCuratedSets = useMemo(() => favCuratedSets?.slice(0, 10), [favCuratedSets]);
    const previewFavArtists = useMemo(() => favArtists?.slice(0, 10), [favArtists]);

    const getNoResultsSection = useCallback(
        ({ text, emptyStateProps }: FavoriteEmptySpaceProps) => (
            <div className={classNames(styles['favorites-desktop__no-results-section'])}>
                <SecondaryPageTitle title={text} counter="0" />
                <EmptyState {...emptyStateProps} actionIcon="chevron-right-icon" />
            </div>
        ),
        []
    );

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <div className={styles['favorites-desktop']}>
            <div className={styles['favorites-desktop__header']}>
                <PrimaryPageTitle title="Favorites" />
            </div>

            {previewFavArtists.length > 0 ? (
                <CardCarousel title="Followed Artists" titleCounter={favArtists.length.toString()} seeMorePath="/favorites/artists/">
                    {previewFavArtists.map((item) => (
                        <ArtistCardImage
                            key={item.id}
                            artist={item}
                        />
                    ))}
                </CardCarousel>
            ) : (
                getNoResultsSection(FAVORITE_EMPTY_STATES[0])
            )}
            {previewFavMedia?.length > 0 ? (
                <div className={classNames('spacing__window--horizontal')}>
                    <SecondaryPageTitle title="Favorite Songs" counter={favMedia.length.toString()} noPadding />
                    <TrackListSupreme preset={!isDownload ? TrackListPresetSupreme.Stream : TrackListPresetSupreme.Download} list={previewFavMedia} onDownloadRevalidate={onDownloadAlbumsRevalidate} />
                    <AppLink href="/favorites/songs/">
                        <SeeMore expand={false} variant="text" />
                    </AppLink>
                </div>
            ) : (
                getNoResultsSection(FAVORITE_EMPTY_STATES[1])
            )}
            {previewFavAlbums && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {previewFavAlbums.length > 0 ? (
                        <CardCarousel title="Favorite Albums" titleCounter={favAlbums?.length.toString()} seeMorePath="/favorites/albums/">
                            {previewFavAlbums.map((item) => (
                                <AlbumCard
                                    key={item.id}
                                    isDownload={isDownload}
                                    album={item as unknown as DownloadAlbum}
                                    albumsList={previewFavAlbums as unknown as DownloadAlbum[]}
                                />
                            ))}
                        </CardCarousel>
                    ) : (
                        getNoResultsSection(FAVORITE_EMPTY_STATES[2])
                    )}
                </>
            )}
            {previewFavPlaylists.length > 0 ? (
                <CardCarousel title="Followed Playlists" titleCounter={favPlaylists?.length.toString()} seeMorePath="/favorites/playlists/">
                    {previewFavPlaylists.map((item) => (
                        <PlaylistCard
                            key={item.id}
                            playlist={item}
                            playlists={previewFavPlaylists}
                        />
                    ))}
                </CardCarousel>
            ) : (
                getNoResultsSection(FAVORITE_EMPTY_STATES[3])
            )}
            {previewFavCuratedSets.length > 0 ? (
                <CardCarousel title="Favorite Curated Sets" titleCounter={favCuratedSets.length.toString()} seeMorePath="/favorites/curated-sets/">
                    {previewFavCuratedSets.map((item) => (
                        <CuratedSetCard
                            key={item.id}
                            curatedSet={item}
                            curatedSets={previewFavCuratedSets} />
                    ))}
                </CardCarousel>
            ) : (
                getNoResultsSection(FAVORITE_EMPTY_STATES[4])
            )}
        </div>
    );
}
