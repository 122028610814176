import { useContext, useEffect, useMemo } from 'react';
import { DownloadSearchSuggestResponse, StreamSearchSuggestResponse, useSearchSuggest } from '@bpm-web-app/swr-hooks';
import { useDebounce, useHubSwitch, useOnClickOutside } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { LibraryTabsContext } from '@bpm-web-app/components';
import styles from './predictive-search-mobile.module.css';
import Artists from './artists';
import Media from './media';
import { EmptyState } from '../shared';

export interface PredictiveSearchProps {
    term: string;
    setTerm: (term: string) => void
}

type StreamSearchData = StreamSearchSuggestResponse['data'];
type DownloadSearchData = DownloadSearchSuggestResponse['data'];

export default function PredictiveSearch({ term, setTerm }: PredictiveSearchProps) {
    const router = useRouter();
    const debouncedSearchTerm = useDebounce(term, 100);
    const { ref, showComponent, setShowComponent } = useOnClickOutside(false);
    const { searchTerm } = router.query;
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { isDownload } = useHubSwitch();

    const { data } = useSearchSuggest({
        term: debouncedSearchTerm,
        library: libraryProperty,
    });

    const searchResults = useMemo(() => data?.data || ({} as DownloadSearchData | StreamSearchData), [data?.data]);

    const hasTracks = useMemo(() => {
        if (isDownload) {
            if ((searchResults as DownloadSearchData).albums?.length > 0) return true;
        } else if ((searchResults as StreamSearchData).media?.length > 0) return true;
        return false;
    }, [isDownload, searchResults]);

    const shouldDisplayNoResults = useMemo(() => {
        if (searchResults.artist_total === 0 &&
            !hasTracks) return true;
        return false;
    }, [hasTracks, searchResults.artist_total]);

    useEffect(() => {
        if (term && searchTerm !== term) {
            setShowComponent(true);
        } else {
            setShowComponent(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term, searchTerm]);

    if (!term) return null;

    if (showComponent) {
        return (
            <div ref={ref} className={styles['predictive-search__container']}>
                {shouldDisplayNoResults ? (
                    <EmptyState title={"Sorry, we couldn't find anything that matches your search."} subtitle="Try checking your spelling, or searching for something else." noPadding icon="search-outline-icon" variant="white" hasBackground={false} />
                ) : (
                    <>
                        <Artists artists={searchResults.artists?.slice(0, 3)} />
                        <Media media={isDownload ? (searchResults as DownloadSearchData).albums?.slice(0, 3) : (searchResults as StreamSearchData).media?.slice(0, 3)} setTerm={setTerm} />
                    </>
                )}
            </div>
        );
    }
    return null;
}
