import { getFollowedPlaylists } from '@bpm-web-app/swr-hooks';
import CardGrid from '../shared/card-grid/card-grid';
import styles from './favorite-playlists.module.css';
import { PlaylistCard } from '../shared/card/playlist-card/playlist-card';

export function FavoritePlaylists() {
    const { data, isLoading } = getFollowedPlaylists();

    // @TODO LOADING ANIMATION
    if (isLoading) {
        return null;
    }
    return (
        <div className="spacing__window">
            <h3 className={styles['favorite-playlists']}>Favorite Playlists</h3>
            <CardGrid cardType="card-small">
                {data?.data?.map((item) => (
                    <PlaylistCard
                        key={item.id}
                        playlist={item}
                        playlists={data?.data}
                    />
                ))}
            </CardGrid>
        </div>
    );
}
