import { TrendingByPeriodQuery, TrendingPeriod } from '@bpm-web-app/api-client';
import { Album } from '@bpm-web-app/download-api-sdk';
import { Category, MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { DownloadTrendingResponse, StreamTrendingResponse, useCategories, useTrending } from '@bpm-web-app/swr-hooks';
import { DEFAULT_GENRE_DROPDOWN_CATEGORY, DEFAULT_PERIOD, convertPeriodIntoTimeframes, useHubSwitch } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useState } from 'react';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import Dropdown from '../dropdown/dropdown';
import { TrackListLoadingSupreme } from '../shared/track-list/ghost-loading/track-list-loading-supreme';
import { TrackListSupreme } from '../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../shared/track-list/supreme/track-list-supreme-helpers';
import styles from './trending-media.module.css';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface TrendingMediaProps {}

export function TrendingMedia() {
    const { isDownload } = useHubSwitch();
    const router = useRouter();

    const { period } = router.query;
    const { libraryProperty } = useContext(LibraryTabsContext);
    const [activeCategory, setActiveCategory] = useState<string>(DEFAULT_GENRE_DROPDOWN_CATEGORY.value);
    const allCategories = useCategories(libraryProperty)?.categories || ([] as Category[]);

    const allPeriods: TrendingPeriod[] = ['daily', 'weekly', 'monthly'];
    const [activePeriod, setActivePeriod] = useState<TrendingPeriod>(allPeriods.includes(period as unknown as TrendingPeriod) ? (period as unknown as TrendingPeriod) : DEFAULT_PERIOD);

    const query: TrendingByPeriodQuery = {
        period: activePeriod,
        limit: 50,
        category: activeCategory || undefined,
        library: libraryProperty,
    };
    const { data, isLoading, mutate } = useTrending(isDownload ? 'download' : 'stream', query);

    const trending = useMemo(() => data?.data || [], [data?.data]);

    const trendingStatusToShow = useMemo(() => {
        switch (activePeriod) {
            case 'daily':
                return 'trending_status_daily';
            case 'monthly':
                return 'trending_status_monthly';
            case 'weekly':
            default:
                return 'trending_status_weekly';
        }
    }, [activePeriod]);

    useEffect(() => {
        setActiveCategory(DEFAULT_GENRE_DROPDOWN_CATEGORY.value);
    }, [libraryProperty]);

    const onDownloadRevalidate = (downloadMedia: any) => {
        // eslint-disable-next-line no-param-reassign
        downloadMedia.download_count += 1;
        mutate({ data: trending } as StreamTrendingResponse | DownloadTrendingResponse, false);
    };

    return (
        <div className="spacing__window">
            <div className={styles['trending-media__intro']}>
                <h1 className={styles['trending-media__title']}>Top 50 Songs</h1>
            </div>
            <div className={classNames(styles['trending-media__sub-intro'])}>
                <Dropdown<string>
                    onClick={(option) => {
                        setActiveCategory(option);
                    }}
                    value={activeCategory}
                    options={[
                        DEFAULT_GENRE_DROPDOWN_CATEGORY,
                        ...(allCategories?.map((category) => ({
                            label: category.name,
                            value: category.slug,
                        })) || []),
                    ]}
                    layoutType="border-input"
                    isDefaultWidth
                />
                <Dropdown<TrendingPeriod>
                    onClick={(option) => {
                        setActivePeriod(option);
                    }}
                    value={activePeriod}
                    options={[
                        ...allPeriods.map((p) => ({
                            label: convertPeriodIntoTimeframes(p),
                            value: p,
                        })),
                    ]}
                    layoutType="border-input"
                />
            </div>
            {isLoading && <TrackListLoadingSupreme amount={50} preset={TrackListPresetSupreme.Download} />}
            {!isLoading && (
                <div className={styles['trending-media__list']}>
                    <TrackListSupreme
                        onDownloadRevalidate={onDownloadRevalidate}
                        list={trending as Album[] | MediaWithAlbum[]}
                        isSortable={false}
                        preset={isDownload ? TrackListPresetSupreme.DownloadTrending : TrackListPresetSupreme.StreamTrending}
                        trendingStatusToShow={trendingStatusToShow}
                    />
                </div>
            )}
        </div>
    );
}

export default TrendingMedia;
