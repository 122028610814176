import { usePlaylists, usePlaylistCategories, useFeaturedPlaylists } from '@bpm-web-app/swr-hooks';
import { DEFAULT_RELEASES_DROPDOWN_CATEGORY, useUniqueArray } from '@bpm-web-app/utils';
import { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Playlist, PlaylistCategory } from '@bpm-web-app/download-api-sdk';
import CardCarousel from '../shared/card-carousel/card-carousel';
import styles from './exclusive-playlists.module.css';
import Dropdown from '../dropdown/dropdown';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import { BreakpointView, CardGrid, GhostComponent, PrimaryPageTitle, SearchInput } from '../shared';
import { PlaylistCard } from '../shared/card/playlist-card/playlist-card';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExclusivePlaylistsProps { }

export function ExclusivePlaylists(props: ExclusivePlaylistsProps) {
    const [activeCategory, setActiveCategory] = useState<string>(DEFAULT_RELEASES_DROPDOWN_CATEGORY.value);
    const { libraryProperty, library } = useContext(LibraryTabsContext);
    const { data: playlists, isLoading: isLoadingPlaylist } = usePlaylists({ library: libraryProperty });
    const { data: featuredPlaylists, isLoading: isLoadingFeaturedPlaylists } = useFeaturedPlaylists({ library: libraryProperty, });
    const { data: playlistsCategories, isLoading: isLoadingCategories } = usePlaylistCategories(libraryProperty);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const filterCategories = useMemo(() => {
        if (isLoadingCategories) return [];
        if (!activeCategory) return playlistsCategories?.data;
        return playlistsCategories?.data?.filter((item) => item.slug === activeCategory);
    }, [activeCategory, isLoadingCategories, playlistsCategories?.data]);

    const dataFiltered = useMemo(
        () =>
            filterCategories?.map((category) => ({
                category,
                media: playlists?.data?.filter((item) => Number(item.category_id) === Number(category.id)).slice(0, 20),
            })) || [],
        [filterCategories, playlists]
    );

    const searchPlaylistData = useMemo(
        () => {
            if (searchQuery !== '' && library === 'artist' && dataFiltered[0]) {
                const filteredMedia = dataFiltered[0].media?.filter((item) => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
                return [{ category: dataFiltered[0].category, media: filteredMedia }];
            }
            return dataFiltered;
        },
        [dataFiltered, searchQuery, library]
    );

    useEffect(() => {
        setActiveCategory(DEFAULT_RELEASES_DROPDOWN_CATEGORY.value);
    }, [libraryProperty]);

    const isEverythingLoaded = useMemo(() => {
        if ((
            (isLoadingPlaylist === false && isLoadingFeaturedPlaylists === false) || (isLoadingCategories === false || isLoadingFeaturedPlaylists === false))
            && dataFiltered[0] && dataFiltered[0].media) return true;
        return false;
    }, [dataFiltered, isLoadingCategories, isLoadingFeaturedPlaylists, isLoadingPlaylist]);

    const renderDropdown = () => (
        <Dropdown<string>
            onClick={(option) => {
                setActiveCategory(option);
            }}
            layoutType="border-input"
            isDefaultWidth
            value={activeCategory}
            options={[
                DEFAULT_RELEASES_DROPDOWN_CATEGORY,
                ...(playlistsCategories?.data.map((category) => ({
                    label: category.name,
                    value: category.slug,
                })) || []),
            ]}
        />
    );

    const ghostElementsKeys = useUniqueArray(5);

    const renderGrid = (category: { category: PlaylistCategory; media: Playlist[] | undefined; }) => {
        return (
            <div className={classNames('spacing--top-double', 'spacing__window--horizontal')}>
                <CardGrid cardType="card-small">
                    {(category.media || []).map((set) => (
                        <PlaylistCard
                            key={set.id}
                            playlist={set}
                            playlists={category.media} />
                    ))}
                </CardGrid>
            </div>
        );
    };

    if (!isEverythingLoaded) {
        return (
            <>
                <PrimaryPageTitle title="Exclusive Playlists" />

                {library !== 'artist' && ghostElementsKeys.map((keyId) => {
                    return (
                        <>
                            <div className="spacing--top-double" />
                            <GhostComponent type="secondary-title" key={`secondary-title-pack-${keyId}`} />
                            <GhostComponent type="cards" key={`ghost-pack-${keyId}`} elementsCount={10} cardSize="small" linesWidthArray={[60, 90]} lineHeight={16} />
                        </>
                    );
                })}

                {library === 'artist' && (
                    <>
                        <div className="spacing--top" />
                        <GhostComponent type="custom" key="secondary-title-pack-artist" height={40} width={245} />
                        <GhostComponent type="cards" key="ghost-pack-artist" elementsCount={10} cardSize="small" linesWidthArray={[60, 90]} lineHeight={17} />
                    </>
                )}
            </>
        );
    }

    return (
        <div className={classNames(styles['exclusive-playlists'], 'spacing__window--top')}>
            <div className={classNames(styles['exclusive-playlists__header'], 'spacing__window--horizontal')}>
                <PrimaryPageTitle title="Exclusive Playlists" noPadding />
                {library !== 'artist' && <BreakpointView
                    mobileChildren={null}
                    desktopChildren={isEverythingLoaded ? renderDropdown() : null}
                />}
            </div>
            {library !== 'artist' && <BreakpointView
                mobileChildren={<div className={classNames('spacing--top', 'spacing__window--horizontal')}>{renderDropdown()}</div>}
                desktopChildren={null}
            />}
            {filterCategories && filterCategories.length === 1 ?
                null :
                featuredPlaylists && (
                    <CardCarousel title="New & Noteworthy">
                        {featuredPlaylists.data.map((playlist) => (
                            <PlaylistCard
                                key={playlist.id}
                                playlist={playlist}
                                playlists={featuredPlaylists.data}
                            />
                        ))}
                    </CardCarousel>
                )
            }
            {library === 'artist' && (
                <div className={classNames('spacing--top', 'spacing__window--horizontal')}>
                    <SearchInput
                        placeholder="Search Playlists"
                        value={searchQuery}
                        variant="small"
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                        onClear={() => setSearchQuery('')}
                    />
                </div>
            )}
            {
                searchPlaylistData.map((item) => (
                    filterCategories && filterCategories.length === 1 ?
                        renderGrid(item) : (
                            <CardCarousel key={item.category.id} title={item.category.name} seeMorePath={`/exclusive-playlists/category/${item.category.id}`}>
                                {(item.media || []).map((playlist) => (
                                    <PlaylistCard
                                        key={playlist.id}
                                        playlist={playlist}
                                        playlists={item.media}
                                    />
                                ))}
                            </CardCarousel>
                        )
                ))
            }
        </div>
    );
}
