import { useMemo } from 'react';
import { useViewport } from '@bpm-web-app/utils';
import ModalSlider from '../modal-slider/modal-slider';
import allResultsDesktop from '../../../assets/onboarding/desktop/search-all-results.gif';
import allResultsMobile from '../../../assets/onboarding/mobile/search-all-results.gif';

export function OnboardingModalSearch() {
    const { isMobile } = useViewport();

    const data = useMemo(
        () => [
            {
                image: isMobile ? allResultsMobile : allResultsDesktop,
                title: 'Filter Your Search Results',
                text: 'Use advanced filters to narrow down your search by genre, version, bpm, or key.',
                href: 'https://www.youtube.com/watch?v=asvFZNU_GYs&list=PLrlMfzKBjbOiofesOOuZM-302p-EpYkZs&index=2',
            },
        ],
        [isMobile]
    );

    return <ModalSlider type="ModalSearch" sliderItems={data} />;
}

export default OnboardingModalSearch;
