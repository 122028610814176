import { useCuratedSets, useCuratedSetsByCategories } from '@bpm-web-app/swr-hooks';
import { DEFAULT_RELEASES_DROPDOWN_CATEGORY } from '@bpm-web-app/utils';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { CuratedSet } from '@bpm-web-app/stream-api-sdk';
import { CuratedSet as DownloadCuratedSet } from '@bpm-web-app/download-api-sdk';
import classNames from 'classnames';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import CardCarousel from '../../shared/card-carousel/card-carousel';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import styles from './curated-sets.module.css';
import Dropdown from '../../dropdown/dropdown';
import { BreakpointView, CardGrid, GhostComponent } from '../../shared';
import { CardCarouselGhostLoading } from '../../shared/card-carousel/card-carousel-ghost-loading';
import { CuratedSetCard } from '../../shared/card/curated-set-card/curated-set-card';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CuratedSetsProps { }
interface CuratedSetsCategory {
    id: string;
    name: string;
    slug: string;
    order: number;
    sets: Array<CuratedSet> | Array<DownloadCuratedSet>;
}

export const CuratedSets: FC<CuratedSetsProps> = () => {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { data: curatedSets, isLoading } = useCuratedSets({ library: libraryProperty });
    const { data: curatedSetsCategories, isLoading: isLoadingCategories } = useCuratedSetsByCategories(libraryProperty);

    const [activeCategory, setActiveCategory] = useState<string>(DEFAULT_RELEASES_DROPDOWN_CATEGORY.value);

    const setsByCategory: Array<CuratedSetsCategory> = useMemo(() => {
        let sets = curatedSets?.data || [];

        if (activeCategory) {
            sets = (sets as DownloadCuratedSet[]).filter((set) => set.category.slug === activeCategory);
        }

        const result = (sets as DownloadCuratedSet[])?.reduce((prevSet, curSet) => {
            const catId = curSet.category_id;
            const cat = prevSet.find(({ id }) => id === catId);

            if (!cat) {
                const catTitle = curSet.category.name;
                const catOrder = curSet.category.order;
                const catSlug = curSet.category.slug;

                prevSet.push({
                    id: catId,
                    name: catTitle,
                    order: catOrder,
                    slug: catSlug,
                    sets: [curSet],
                });

                return prevSet;
            }

            if (cat.sets.length < 20) {
                cat.sets.push(curSet);
                return prevSet;
            }

            return prevSet;
        }, []);

        return result;
    }, [curatedSets, activeCategory]);

    useEffect(() => {
        setActiveCategory(DEFAULT_RELEASES_DROPDOWN_CATEGORY.value);
    }, [libraryProperty]);

    const isEverythingLoaded = useMemo(() => {
        if ((!isLoading && !isLoadingCategories) && curatedSets) return true;
        return false;
    }, [isLoading, isLoadingCategories, curatedSets]);

    if (!isEverythingLoaded) {
        return (
            <>
                <PrimaryPageTitle title="Curated Sets" />
                <div className="spacing--top" />
                <div className="spacing--top" />

                {[1, 2, 3, 4, 5].map(() => {
                    return (
                        <>
                            <GhostComponent type="secondary-title" />
                            <CardCarouselGhostLoading elementsCount={10} cardSize="small" linesWidthArray={[90, 60]} lineHeight={18} />
                        </>
                    );
                })}
            </>
        );
    }

    const renderDropdown = () => (
        <Dropdown<string>
            onClick={(option) => {
                setActiveCategory(option);
            }}
            layoutType="border-input"
            isDefaultWidth
            value={activeCategory}
            options={[
                DEFAULT_RELEASES_DROPDOWN_CATEGORY,
                ...(curatedSetsCategories?.map((category) => ({
                    label: category.name,
                    value: category.slug,
                })) || []),
            ]}
        />
    );

    const renderGrid = (category: CuratedSetsCategory) => {
        return (
            <div className={classNames('spacing--top-double', 'spacing__window--horizontal')}>
                <CardGrid cardType="card-small">
                    {category.sets.map((set) => (
                        <CuratedSetCard
                            key={set.id}
                            curatedSet={set}
                            curatedSets={category.sets} />
                    ))}
                </CardGrid>
            </div>
        );
    };

    return (
        <article className="spacing__window--top">
            <div className={classNames(styles['curated-sets__heading'], 'spacing__window--horizontal')}>
                <PrimaryPageTitle title="Curated Sets" noPadding />
                <BreakpointView
                    mobileChildren={null}
                    desktopChildren={isEverythingLoaded ? renderDropdown() : null}
                />
            </div>
            <BreakpointView
                mobileChildren={<div className={classNames('spacing--top', 'spacing__window--horizontal')}>{renderDropdown()}</div>}
                desktopChildren={null}
            />
            {setsByCategory.map((data) => (
                activeCategory ?
                    renderGrid(data) : (
                        <CardCarousel key={data.slug} title={data.name} seeMorePath={`/curated-sets/category/${data.id}`}>
                            {data.sets.map((set) => (
                                <CuratedSetCard
                                    key={set.id}
                                    curatedSet={set}
                                    curatedSets={data.sets} />
                            ))}
                        </CardCarousel>
                    )

            ))}
        </article>
    );
};

export default CuratedSets;
