import { Album } from '@bpm-web-app/download-api-sdk';
import { getFavoriteAlbums, useFavoriteMedia } from '@bpm-web-app/swr-hooks';
import { useHubSwitch } from '@bpm-web-app/utils';
import { useContext, useMemo } from 'react';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import { TrackListSupreme } from '../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../shared/track-list/supreme/track-list-supreme-helpers';
import { LocalSortingKeys, sortMediaLocally, useLocalSort } from '../sort-options-sheet/sort-options-sheet';
import styles from './favorite-songs.module.css';

export function FavoriteSongs() {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { isDownload } = useHubSwitch();
    const [sortType, setSortType] = useLocalSort();

    const { data: songsData, isLoading: isLoadingSongs } = useFavoriteMedia(libraryProperty);
    const { data: albumsData, isLoading: isLoadingAlbums, mutate: mutateAlbums } = getFavoriteAlbums(libraryProperty, isDownload);

    const mediaList = useMemo(() => (isDownload ? (albumsData?.data as Album[]) : songsData?.data), [albumsData?.data, isDownload, songsData?.data]);

    const sortedMedia = useMemo(() => sortMediaLocally(mediaList, sortType), [mediaList, sortType]);

    // @TODO LOADING ANIMATION
    if ((isDownload && isLoadingAlbums) || (!isDownload && isLoadingSongs)) {
        return null;
    }

    return (
        <div className="spacing__window">
            <h3 className={styles['favorite-songs']}>{`Favorite Songs (${mediaList?.length || 0})`}</h3>
            {mediaList && (
                <TrackListSupreme
                    preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                    list={sortedMedia}
                    isSortable
                    onSort={(nextSort) => setSortType(nextSort as LocalSortingKeys)}
                    selectedSortType={sortType}
                    onDownloadRevalidate={(downloadMedia) => {
                        if (downloadMedia) {
                            // eslint-disable-next-line no-param-reassign
                            downloadMedia.download_count += 1;
                            mutateAlbums({ data: mediaList as Album[] }, false);
                        }
                    }} />
            )}
        </div>
    );
}
