import useSWR from 'swr';
import { Media, MediaCategoryQuery } from '@bpm-web-app/api-client';

export function useMediaCategory(query: MediaCategoryQuery, isEnabled = true) {
    const { data, error } = useSWR(
        [
            'stream-media-category',
            {
                persist: true,
                isEnabled,
                ...query,
            },
        ],
        () => Media.getMediaForCategory(query),
        {
            isPaused: () => !isEnabled,
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}
