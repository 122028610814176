import { UserPlaylistCategory } from '@bpm-web-app/download-api-sdk';
import { convertToPluralIfNeeded, getCurrentPlatformLink, useApiErrorHandler } from '@bpm-web-app/utils';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Card from '../card';
import CardGhostLoading from '../card-ghost-loading';
import { CustomIcon } from '../../custom-icon/custom-icon';
import styles from './folder-card.module.css';
import { useUserPlaylistCategories } from '../../three-dots-sheet/useUserPlaylistCategories';
import { ContextMenuContext } from '../../context-menu/context-menu.context';
import PlaylistsForm, { PlaylistsFormProps } from '../../../playlists-form/playlists-form';
import { useActionModal } from '../../action-modal/action-modal.context';
import { ContextMenuItemProps } from '../../context-menu/types';
import { useUserPlaylists } from '../../three-dots-sheet/useUserPlaylists';

interface FolderCardProps {
    folder?: UserPlaylistCategory;
    isGhost?: boolean;
    onClick?: () => void;
}

export function FolderCard({ folder, isGhost, onClick }: FolderCardProps) {
    const router = useRouter();
    const [openForm, setOpenForm] = useState<null | PlaylistsFormProps>(null);
    const errorHandler = useApiErrorHandler(false);
    const contextMenuForm = () => <PlaylistsForm {...openForm} />;
    const contextMenuRef = useRef<HTMLDivElement>(null);

    const { deleteFolder, editFolderName } = useUserPlaylistCategories();

    const { openContextMenu, closeOptions, isOpen } = useContext(ContextMenuContext);

    const { createPlaylistOnFolder } = useUserPlaylists(folder ? folder.id : 0);

    const addPlaylist = useCallback(() => {
        setOpenForm({
            type: 'CreateNewPlaylist',
            text: 'Create custom playlists to organize your music.',
            formAction: (name) => createPlaylistOnFolder(folder ? folder.id : 0, name),
            close: () => setOpenForm(null),
        });
    }, [createPlaylistOnFolder, folder]);

    const updateFolderById = useCallback((id: number, name: string) => {
        setOpenForm({
            type: 'EditMyFolder',
            text: `Enter a new name for ${name} below.`,
            formAction: (newName) => editFolderName(id, newName),
            close: () => setOpenForm(null),
        });
    }, [editFolderName]);

    const createNewPlaylist = useCallback(() => {
        addPlaylist();
        closeOptions();
    }, [addPlaylist, closeOptions]);

    const { openModal: openActionModal, closeModal: closeActionModal } = useActionModal();

    const handleDeleteFolderById = useCallback(async (id?: number) => {
        openActionModal({
            headerTitle: 'Delete Folder',
            title: 'Are you sure you want to delete this folder?',
            confirmButtonText: "I'm sure",
            cancelButtonText: 'Cancel',
            shouldCloseOnOverlayClick: true,
            onConfirm: async () => {
                closeActionModal();
                try {
                    await deleteFolder(id as number);
                    closeOptions();
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    if (router.query['categoryId'] === `${id}`) {
                        router.replace(getCurrentPlatformLink('/my-playlists'));
                    }
                } catch (error) {
                    errorHandler({ error });
                }
            },
            onClose: closeActionModal,
        });
    }, [closeActionModal, deleteFolder, errorHandler, openActionModal, router, closeOptions]);

    const contextMenuOptions = useCallback((item: UserPlaylistCategory) => {
        const menuOptions: ContextMenuItemProps[] = [
            {
                leftIcon: <CustomIcon type="new-window-icon" hasIconHover />,
                label: 'Go to Folder',
                onClick: () => {
                    closeOptions();
                    router.push(getCurrentPlatformLink(`/my-playlists/${item.id}`));
                },
            },
            {
                label: 'Create Playlist',
                leftIcon: <CustomIcon type="add-circle-icon" hasIconHover />,
                onClick: () => {
                    closeOptions();
                    if (createNewPlaylist) createNewPlaylist();
                }
            },
            {
                label: 'Edit Folder Name',
                leftIcon: <CustomIcon type="edit-pencil-icon" hasIconHover />,
                onClick: () => {
                    closeOptions();
                    if (updateFolderById) updateFolderById(item.id, item.name);
                }
            },
            {
                label: 'Delete Folder',
                leftIcon: <CustomIcon type="delete-filled-icon" hasIconHover color="delete" />,
                onClick: () => {
                    closeOptions();
                    if (handleDeleteFolderById) handleDeleteFolderById(item.id);
                },
                type: 'delete-item'
            }
        ];
        return item.slug === 'shared' ? [menuOptions[0], menuOptions[menuOptions.length - 1]] : menuOptions;
    }, [closeOptions, createNewPlaylist, handleDeleteFolderById, router, updateFolderById]);

    const handleOpenThreeDots = (e: React.MouseEvent, item: UserPlaylistCategory) => {
        const { left, top, width } = e.currentTarget.getBoundingClientRect();
        if (isOpen(item.id)) {
            closeOptions();
        } else {
            openContextMenu({ id: item.id, options: contextMenuOptions(item), left, top, renderLocationPosition: 'app', align: 'right', buttonWidth: width });
        }
    };

    const onThreeDotsPressed = (e: React.MouseEvent) => {
        if (folder) {
            handleOpenThreeDots(e, folder);
        }
    };

    useEffect(() => {
        const handleDocClick = (e: MouseEvent) => {
            if (!contextMenuRef?.current || !closeOptions) return;
            if (contextMenuRef.current !== e.target && !contextMenuRef.current.contains(e.target as Node)) {
                e.preventDefault();
                closeOptions();
            }
        };

        document.addEventListener('click', handleDocClick);
        return () => {
            document.removeEventListener('click', handleDocClick);
        };
    }, [closeOptions]);

    if (isGhost) {
        return (
            <CardGhostLoading contentType="for-you-playlist" />
        );
    }

    return folder ? (
        <>
            {/* // eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <Card
                onOpenContextMenu={onThreeDotsPressed}
                cardSize="small"
                contentType="user-playlist"
                imageUrl=""
                id={folder.id}
                title={folder.name}
                description={convertToPluralIfNeeded(folder.playlist_count, 'Playlist', 'Playlists')}
                fullHeight
                isPlayable={false}
                link={`/my-playlists/${folder.id}`}
                isDescriptionSingleLine
                imageComponent={
                    <div className={styles['folder-card__shared-folder']}>
                        <CustomIcon type={folder.slug === 'shared' ? 'shared-folder' : 'folder-icon'} size="large" hasIconHover />
                    </div>
                } />
            {openForm && contextMenuForm()}

        </>
    ) : null;
}
