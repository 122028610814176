import { Album as DownloadAlbum, Media } from '@bpm-web-app/download-api-sdk';
import { useDownloadMedia } from '@bpm-web-app/swr-hooks';
import { useApiErrorHandler, useUserSettings } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { Album } from 'libs/api-client/src/lib/handlers/album';
import { LibraryTabsContext } from 'libs/utils/src/lib/library-tabs.context';
import { useCallback, useContext } from 'react';
import { VersionPill } from '../../../shared/version-pill/version-pill';
import styles from './versions-download.module.css';

interface VersionsDownloadProps {
    media: Album;
    isPremiumOnly?: boolean;
    hasPremiumOnlyAccess?: boolean;
    setCurrentMediaInContext?: () => void;
    onDownloadRevalidate?: (downloadMedia?: Media) => void;
}

export function VersionsDownload({ media, isPremiumOnly, hasPremiumOnlyAccess, setCurrentMediaInContext, onDownloadRevalidate }: VersionsDownloadProps) {
    const { isAnonymous } = useUserSettings();
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { downloadSingleMedia, handleOpenDownloadUrl, setIsRequestInProgress } = useDownloadMedia(libraryProperty, false);
    const errorHandler = useApiErrorHandler();

    const handleSingleMediaDownload = useCallback(
        async (downloadMedia: Media) => {
            if (isAnonymous || (isPremiumOnly && !hasPremiumOnlyAccess)) {
                if (setCurrentMediaInContext) setCurrentMediaInContext();
                return;
            }
            try {
                setIsRequestInProgress(true);
                const url = (await downloadSingleMedia(downloadMedia.id)).data?.url || '';
                // TODO: what analytics event?
                // Analytics.trackClick('one_click_download', downloadMedia.id.toString(), { location: '1-click' });

                handleOpenDownloadUrl(url);
                if (onDownloadRevalidate) onDownloadRevalidate(downloadMedia);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                errorHandler({ error, message: 'Error downloading files. Please try again.' });
            } finally {
                setIsRequestInProgress(false);
            }
        },
        [downloadSingleMedia, errorHandler, handleOpenDownloadUrl, hasPremiumOnlyAccess, isAnonymous, isPremiumOnly, onDownloadRevalidate, setCurrentMediaInContext, setIsRequestInProgress]
    );

    const downloadAlbum = media as unknown as DownloadAlbum;

    if (isPremiumOnly && !hasPremiumOnlyAccess) {
        return (
            <button
                type="button"
                className={classNames(styles['versions-download__upgrade-to-unlock'])}
                onClick={(e) => {
                        e.stopPropagation();
                        if (setCurrentMediaInContext) setCurrentMediaInContext();
                }}>
                UPGRADE TO UNLOCK
            </button>
        );
    }

    return (
        <div className={classNames(styles['versions-download'])}>
            {downloadAlbum.media.map((version) => (
                <VersionPill key={version.id} onClick={() => handleSingleMediaDownload(version)} media={version} />
            ))}
        </div>
    );
}
