import { usePlaylistsByCategoryId } from '@bpm-web-app/swr-hooks';
import { useContext, useEffect, useMemo } from 'react';
import { Playlist } from '@bpm-web-app/stream-api-sdk';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import { PlaylistCard } from '../shared/card/playlist-card/playlist-card';
import { CardCarousel, GhostComponent } from '../shared';

export interface PlaylistsBrowseProps {
    categoryId: number
    isDataLoaded?: boolean;
    title?: string;
    setIsDataLoaded?: (loaded: boolean) => void;
}

export function PlaylistsBrowse({ setIsDataLoaded, isDataLoaded, categoryId, title = 'Collections' }: PlaylistsBrowseProps) {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { data, isLoading } = usePlaylistsByCategoryId(categoryId, libraryProperty);

    const playlists = data?.data as Playlist[];

    useEffect(() => {
        if (setIsDataLoaded) {
            if (isLoading === false) {
                setIsDataLoaded(true);
            } else {
                setIsDataLoaded(false);
            }
        }
    }, [isLoading, setIsDataLoaded]);

    const shouldDisplayGhostLoading = useMemo(() => isDataLoaded === false || isLoading, [isDataLoaded, isLoading]);

    return (
        <div>
            {shouldDisplayGhostLoading ? (
                <GhostComponent type="cards" title={title} elementsCount={8} contentType="exclusive-playlist" />
            ) : (
                <CardCarousel title={playlists?.[0]?.category?.name || title} seeMorePath={`/exclusive-playlists/category/${categoryId}`}>
                    {playlists &&
                        playlists.map((playlist) => (
                            <PlaylistCard
                                key={playlist.id}
                                playlist={playlist}
                                playlists={playlists}
                            />
                        ))}
                </CardCarousel>
            )}
        </div>
    );
}
