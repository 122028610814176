import { useCategories } from '@bpm-web-app/swr-hooks';
import { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Category } from '@bpm-web-app/download-api-sdk';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import styles from './genres.module.css';
import { SearchInput } from '../../shared/search-input/search-input';
import { AppLink } from '../../shared';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GenresProps { }

export function Genres(props: GenresProps) {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { categories } = useCategories(libraryProperty, true);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const findAndMergeClassics = useCallback((arr: Category[]) => {
        const classics: Category = {
            name: 'Classics',
            genres: [],
            id: 0,
            created_at: '',
            updated_at: '',
            slug: '',
            order: 0
        };

        const remainingCategories: Category[] = [];

        let classicsFoundCount = 0;

        arr.forEach((item) => {
            if (/\d{2}s/.test(item.name)) {
                classicsFoundCount += 1;
                classics.genres?.push({
                    id: item.id,
                    name: item.name,
                    slug: item.slug,
                    created_at: '',
                    updated_at: ''
                });
            } else {
                remainingCategories.push(item);
            }
        });
        // Insert classics into array without any of the merged objects.
        remainingCategories.splice(2, 0, classics);

        if (classicsFoundCount >= 1) return remainingCategories;
        return arr;
    }, []);

    const moveExclusivesToEnd = useCallback((arr: Category[]) => {
        // Find the index of the object with the "exclusives" slug property
        const exclusiveIndex = arr.findIndex((obj) => obj.slug === 'exclusives');

        // If "exclusives" is found, move it to the end of the array
        if (exclusiveIndex !== -1) {
            const exclusiveObj = arr.splice(exclusiveIndex, 1)[0]; // Remove the object
            arr.push(exclusiveObj); // Push it to the end
        }

        return findAndMergeClassics(arr); // Return the modified array
    }, [findAndMergeClassics]);

    const filteredCategories = useMemo(() => findAndMergeClassics(moveExclusivesToEnd(categories || [])), [categories, findAndMergeClassics, moveExclusivesToEnd]);

    const searchObjects = useCallback((categoriesData: Category[], searchText?: string,) => {
        // Use Array.filter to filter objects that match the search criteria
        const filteredObjects = categoriesData.filter((category) => {
            // Check if the object's name matches the search text
            if (category.name.toLowerCase().includes((searchText || '').toLowerCase())) {
                return true;
            }

            // Check if any object in the array property matches the search text
            if (Array.isArray(category.genres) && category.genres.some((item) => item.name.toLowerCase().includes((searchText || '').toLowerCase()))) {
                return true;
            }

            // If neither condition is met, exclude the object
            return false;
        });

        return filteredObjects;
    }, []);

    const renderGenreList = useCallback((category: Category, searchText?: string) => {
        const hasSearch = searchText !== undefined && searchText !== '';

        return (
            <div className={styles['genres__list']}>
                {category.slug ? (
                    <AppLink href={`/genres?category=${category.slug}`}>
                        <div className={styles['genres__list--genre']}>{category.name}</div>
                    </AppLink>
                ) : <div className={styles['genres__list--genre-no-underline']}>{category.name}</div>}
                {category.genres?.filter((subgenre) => hasSearch ? subgenre.name.toLowerCase().includes((searchText).toLowerCase()) : true).map((subgenre) => (
                    <AppLink href={/\d{2}s/.test(subgenre.name) ? `/genres?category=${subgenre.slug}` : `/genres?genre=${subgenre.slug}`}>
                        <div className={styles['genres__list--subgenre']}>{subgenre.name}</div>
                    </AppLink>
                ))}
            </div>
        );
    }, []);

    const renderGenresColumns = useCallback((categoriesData: Category[]) => {
        const hasSearch = searchQuery !== undefined && searchQuery !== '';
        const categoriesArray = hasSearch ? searchObjects(filteredCategories, searchQuery) : filteredCategories;
        return (
            <div className={styles['genres__grid']}>
                {categoriesArray.map((category) => renderGenreList(category, searchQuery))}
            </div>
        );
    }, [filteredCategories, renderGenreList, searchObjects, searchQuery]);

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <section className={classNames(styles['genres'], 'spacing__window')}>
            <div className={styles['genres__header']}>
                <div className={styles['genres__title']}>
                    <PrimaryPageTitle title="Genres" noPadding />
                </div>
                <SearchInput
                    placeholder="Search Genres"
                    value={searchQuery}
                    variant="small"
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                    }}
                    onClear={() => setSearchQuery('')}
                />
            </div>
            {renderGenresColumns(categories || [])}
        </section>
    );
}

export default Genres;
