import { useUserSettings } from '@bpm-web-app/utils';
import { CustomIcon } from '../custom-icon/custom-icon';

interface AddToPlaylistButtonProps {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    hasTooltip?: boolean;
}

export function AddToPlaylistButton({ onClick, hasTooltip }: AddToPlaylistButtonProps) {
    const { isAnonymous, setShowSignUpModal } = useUserSettings();

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        if (isAnonymous) {
            setShowSignUpModal({ type: 'track' });
            return;
        }
        if (onClick) onClick(e);
    };

    return (
        <CustomIcon
            type="add-to-playlist-icon-lg"
            hasIconHover
            onClick={handleOnClick}
            {...(hasTooltip
                ? {
                    tooltip: 'Add to Playlist',
                    hasBackgroundHover: true,
                    container: 'round',
                    containerSize: 'regular',
                }
                : null)}
        />
    );
}
