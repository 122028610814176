import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { getCurrentPlatformLink } from '@bpm-web-app/utils';
import Checkboxes, { CheckboxesProps } from '../../checkboxes/checkboxes';

interface TrackListFilteredTagsCountProps {
    totalCount?: number;
    artistsCount?: number;
    songsCount?: number;
    playlistsCount?: number;
    curatedSetsCount?: number;
    noPadding?: boolean;
    seeMorePath?: string;
    currentValue?: string;
    isLoading?: boolean;
}

export function TrackListFilteredTagsCount({
    totalCount = 0,
    artistsCount = 0,
    songsCount = 0,
    playlistsCount = 0,
    curatedSetsCount = 0,
    noPadding,
    seeMorePath,
    currentValue,
    isLoading,
}: TrackListFilteredTagsCountProps) {
    const router = useRouter();

    const handleOnTagClick = (value: string[]) => {
        if (value[1] !== 'All' && value[1] !== undefined) router.push(getCurrentPlatformLink(`${seeMorePath}${value[1]}`));
        else router.push(getCurrentPlatformLink(`${seeMorePath}`));
    };

    const availableOptions: CheckboxesProps['options'] = useMemo(() => [
        {
            value: 'All',
            label: 'All',
            count: totalCount,
        },
        {
            value: 'artists',
            label: 'Artists',
            count: artistsCount,
        },
        {
            value: 'songs',
            label: 'Songs',
            count: songsCount,
        },
        {
            value: 'exclusive-playlists',
            label: 'Playlists',
            count: playlistsCount,
        },
        {
            value: 'curated-sets',
            label: 'Curated Sets',
            count: curatedSetsCount,
        },
    ], [artistsCount, curatedSetsCount, playlistsCount, songsCount, totalCount]);

    return null;
    return (
        <div className={noPadding ? '' : 'spacing__window--horizontal'}>
            <Checkboxes
                hasRectangularCheckboxes
                options={availableOptions}
                value={[currentValue || 'All']}
                onChange={handleOnTagClick}
                sortOptions={false}
                hasMaxHeight={24}
                isLoading={isLoading}
            />
        </div>
    );
}
