export const monthlyPlanText = (text: string | number, isOneTime: boolean, trial_period?: number) => {
    const isYearly = trial_period === 12;
    if (!Number.isNaN(text) && text < 0) {
        const newText = (text as number) * -1;
        if (isOneTime) return `-$${Number(newText).toFixed(2)}`;
        return isYearly ? `-$${newText * 12}/yr` : `-$${newText}/mo`;
    }
    if (isOneTime) return `$${Number(text).toFixed(2)}`;
    if (isYearly && Number(text)) return `$${Number(text) * 12}/yr`;
    if (trial_period && Number(trial_period) && Number(trial_period) < 0) return `${String(trial_period)} month${trial_period > 1 ? 's' : ''} for $${text}`;
    return `$${text}/mo`;
};

export default monthlyPlanText;
