/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import { MouseEvent as ReactMouseEvent, useCallback, useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import styles from './timestamp-slider.module.css';
import { usePlayer, usePlayerDuration } from '../player-context';
import { getDisplayTime } from './utils';
import { PlayerWaveVariant } from '../shared/player-wave/types';

type Props = {
    variant: PlayerWaveVariant;
    durationProps: {
        estimatedDuration: number;
        duration: number;
        startOffsetPercentage: number;
        lockedPercentage: number;
    }

};

export function TimestampSlider({ variant, durationProps }: Props) {
    const [sliderLeft, setSliderLeft] = useState(0);
    const [time, setTime] = useState(0);
    const [hidden, setHidden] = useState(true);
    const { onSeek, isPreviewTrack } = usePlayer();
    const { startOffset } = usePlayerDuration();

    const onMouseMove = useCallback((event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();

        const { left } = event.currentTarget.getBoundingClientRect();
        const width = event.currentTarget.clientWidth;
        const mouseX = event.clientX - left;
        const percentage = (mouseX / width) * 100;

        if (percentage >= 0) {
          setHidden(false);
          setSliderLeft(mouseX);
          const t = (isPreviewTrack ? durationProps.estimatedDuration : durationProps.duration) * (percentage / 100);
          setTime(t);
        }
    }, [isPreviewTrack, durationProps.estimatedDuration, durationProps.duration]);

    const onClick = useCallback(
        (e) => {
            e.stopPropagation();
            onSeek(isPreviewTrack && startOffset && time < startOffset ? startOffset : time);
        },
        [onSeek, time, startOffset, isPreviewTrack]
    );

    const onDrag = useCallback((event) => {
        const { left } = event.currentTarget.getBoundingClientRect();
        const width = event.currentTarget.clientWidth;
        const mouseX = event.clientX - left;
        const percentage = (mouseX / width) * 100;

        if (percentage >= 0) {
            setHidden(false);
            setSliderLeft(mouseX);
            const t = (isPreviewTrack ? durationProps.estimatedDuration : durationProps.duration) * (percentage / 100);
            setTime(t);
            onSeek(isPreviewTrack && startOffset && t < startOffset ? startOffset : t);
        }
    }, [isPreviewTrack, durationProps.estimatedDuration, durationProps.duration, onSeek, startOffset, setTime, setSliderLeft]);

    const gestures = useGesture({
        onMouseMove: ({ event, }) => {
            onMouseMove(event as ReactMouseEvent<HTMLDivElement, MouseEvent>);
        },
        onDrag: ({ event, down }) => {
            event.stopPropagation();
            if (!down) {
                setHidden(true);
                return;
            }
            onDrag(event);
        },
        onMouseEnter: () => {
            setHidden(false);
        },
        onMouseLeave: () => {
            setHidden(true);
        }
    }, {
        drag: {
            threshold: 10,
        }
    });

    return (
        <div
            className={classNames(styles['timestamp-slider__container'], styles[`timestamp-slider__container--${variant}`])}
            onClick={onClick}
            {...gestures()}
        >
            <div className={classNames(styles['timestamp-slider__inner-container'])}>
                <div
                    onClick={onClick}
                    className={classNames(styles['timestamp-slider__cursor'], styles[`timestamp-slider__cursor--${variant}`], hidden && styles['timestamp-slider__cursor--hidden'],)}
                    style={{ '--timestamp-slider-left': `${sliderLeft}px` } as React.CSSProperties}
                >
                    <span className={styles['timestamp-slider__time']}>{getDisplayTime(time)}</span>
                </div>

            </div>

        </div>
    );
}
/* eslint-enable jsx-a11y/no-static-element-interactions */
/* eslint-enable jsx-a11y/click-events-have-key-events */
