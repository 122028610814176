import { UserPlaylistCategory } from '@bpm-web-app/stream-api-sdk';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserPlaylist as DownloadUserPlaylist } from '@bpm-web-app/download-api-sdk';
import classNames from 'classnames';
import { getSignupRedirectLinkAccountPlans, useHubSwitch, useUserSettings } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useUserPlaylistSets } from '@bpm-web-app/swr-hooks';
import { useUserPlaylistCategories } from '../../shared/three-dots-sheet/useUserPlaylistCategories';
import styles from './my-playlists.module.css';
import { NavContext } from '../../nav/nav.context';
import { PageHeader } from '../../shared/page-header/page-header';
import PlaylistsForm, { PlaylistsFormProps } from '../../playlists-form/playlists-form';
import { LocalSearchSort } from '../../nav-my-playlist-categories/utils';
import { EmptyState, GhostComponent } from '../../shared';
import SecondaryPageTitle from '../../shared/secondary-page-title/secondary-page-title';
import { FolderCard } from '../../shared/card/folder-card/folder-card';
import { MyPlaylistCard } from '../../shared/card/my-playlist-card/my-playlist-card';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface MyPlaylistsProps { }

export function MyPlaylists(props: MyPlaylistsProps) {
    const [searchTerm, setSearchTerm] = useState('');
    const { isDownload } = useHubSwitch();
    const { userPlaylistCategories, isLoading, userPlaylistSets, isLoadingPlaylistSets } = useUserPlaylistCategories(true);
    const { data: allPlaylists, isLoading: isLoadingAll } = useUserPlaylistSets(isDownload, true);
    const { hideSearchBar } = useContext(NavContext);
    const [sortType, setSortType] = useState<LocalSearchSort>();
    const { createFolder } = useUserPlaylistCategories();
    const [openForm, setOpenForm] = useState<null | PlaylistsFormProps>(null);
    const { isAnonymous } = useUserSettings();
    const router = useRouter();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const contextMenuForm = () => <PlaylistsForm {...openForm!} />;

    const addFolder = useCallback(() => {
        if (isAnonymous) {
            router.replace(getSignupRedirectLinkAccountPlans());
            return;
        }
        setOpenForm({
            type: 'CreateNewFolder',
            text: 'Create folders to organize your playlists by genre, event, or more.',
            formAction: createFolder,
            close: () => setOpenForm(null),
        });
    }, [createFolder, isAnonymous, router]);

    const userFoldersList = useMemo(() => {
        let playlistArray: UserPlaylistCategory[] = [];
        if (userPlaylistCategories && userPlaylistCategories.data) {
            switch (sortType) {
                case 'most_recent':
                    playlistArray = userPlaylistCategories?.data.slice().sort((a, b) => {
                        if (a.slug === 'shared') {
                            return -1;
                        }
                        if (b.slug === 'shared') {
                            return 1;
                        }
                        return (a.created_at < b.created_at ? 1 : -1);
                    });
                    break;
                case 'title_asc':
                    playlistArray = userPlaylistCategories?.data.slice().sort((a, b) => {
                        if (a.slug === 'shared') {
                            return -1;
                        }
                        if (b.slug === 'shared') {
                            return 1;
                        }
                        return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
                    });
                    break;
                case 'title_desc':
                    playlistArray = userPlaylistCategories?.data.slice().sort((a, b) => {
                        if (a.slug === 'shared') {
                            return -1;
                        }
                        if (b.slug === 'shared') {
                            return 1;
                        }
                        return (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1);
                    });
                    break;
                default:
                    playlistArray = userPlaylistCategories?.data.slice();
                    break;
            }

            if (searchTerm.trim()) {
                playlistArray = playlistArray.filter(({ name, playlists }) => {
                    if (name.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return true;
                    }
                    return playlists?.find((p) => p.title.toLowerCase().includes(searchTerm.toLowerCase())) !== undefined;
                });
            }
        }

        return playlistArray;
    }, [searchTerm, sortType, userPlaylistCategories]);

    const hasFolder = useMemo(() => userFoldersList?.length !== 0, [userFoldersList?.length]);

    const userPlaylistsList = useMemo(() => {
        let playlistArray: DownloadUserPlaylist[] = [];
        if (allPlaylists && allPlaylists.data) {
            switch (sortType) {
                case 'most_recent':
                    playlistArray = (allPlaylists?.data as DownloadUserPlaylist[]).slice().sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
                    break;
                case 'title_asc':
                    playlistArray = (allPlaylists?.data as DownloadUserPlaylist[]).slice().sort((a, b) => (a.title > b.title ? 1 : -1));
                    break;
                case 'title_desc':
                    playlistArray = (allPlaylists?.data as DownloadUserPlaylist[]).slice().sort((a, b) => (a.title < b.title ? 1 : -1));
                    break;
                default:
                    playlistArray = (allPlaylists?.data as DownloadUserPlaylist[]).slice();
                    break;
            }

            if (searchTerm.trim()) {
                playlistArray = playlistArray.filter(({ title }) => title.toLowerCase().includes(searchTerm.toLowerCase()));
            }
        }
        return playlistArray;
    }, [allPlaylists, sortType, searchTerm]);

    useEffect(() => {
        hideSearchBar();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classNames(styles['my-playlists'], 'spacing__window')}>
            {hasFolder ? (
                <PageHeader
                    title="My Playlists"
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    searchPlaceholder="Search Playlists"
                    onSort={setSortType}
                    handleAdd={addFolder}
                    selectedSortType={sortType}
                />
            ) : (
                <PageHeader
                    title="My Playlists"
                />
            )}

            {openForm && contextMenuForm()}

            {!isLoading ? (
                <>
                    {hasFolder && <SecondaryPageTitle title={`Folders (${userFoldersList.length})`} noPadding />}
                    {!hasFolder && <EmptyState title={isAnonymous ? "Looks like you haven't signed in." : "Looks like you haven't created any folders yet."} subtitle={isAnonymous ? 'Sign in to start building your own personalized folders. Your folders are here to help you organize your playlists' : 'Start building your own personalized folders. Your folders are here to help you organize your playlists'} actionLabel={isAnonymous ? 'Sign In' : 'Create Folder'} noPadding verticalMargins={16} onPress={addFolder} />}
                    <div className={styles['my-playlists__grid']}>
                        {!isLoading &&
                            userPlaylistCategories?.data &&
                            userFoldersList.map((item) => (
                                <FolderCard
                                    key={item.id}
                                    folder={item} />
                            ))
                        }
                    </div>
                </>
            ) : (
                <>
                    <GhostComponent type="secondary-title" title="Folders" noPadding />
                    <div className="spacing--top-double" />
                    <GhostComponent type="cards" elementsCount={6} noPadding contentType="user-playlist" grid />
                </>
            )}
            {!isLoadingAll ? (
                <div className="spacing--top">
                    {userPlaylistsList?.length !== 0 && <SecondaryPageTitle title={userPlaylistsList?.length === 0 ? 'All Playlists' : `All Playlists (${userPlaylistsList.length})`} noPadding />}
                    <div className={styles['my-playlists__grid']}>
                        {!isLoadingPlaylistSets &&
                            userPlaylistSets?.data &&
                            userPlaylistsList.map((item) => (
                                <MyPlaylistCard key={item.id} playlist={item} />
                            ))}
                    </div>
                </div>
            ) : (
                <GhostComponent type="cards" contentType="user-playlist" elementsCount={6} grid title="All Playlists" noPadding />
            )}
        </div>
    );
}

export default MyPlaylists;
