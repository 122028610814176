import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import styles from './lists-button.module.css';

interface ListButtonProps {
    isActive: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>;
    children: React.ReactNode;
}

export const ListsButton = ({ isActive, onClick, children }: ListButtonProps) => {
    return (
        <button type="button" className={classNames(styles['lists-button'], { [styles['lists-button--active']]: isActive })} onClick={onClick}>
            {children}
        </button>
    );
};
