import {
    isServerSide,
} from '@bpm-web-app/utils';
import { SupremeMaxiPlayer } from '../supreme-maxi-player/supreme-maxi-player';
import { SupremeMiniPlayer } from '../supreme-mini-player/supreme-mini-player';
import { ElapsedTimeWatcher } from './elapsed-time-watcher';

export function SupremePlayer() {
    if (isServerSide()) return null;

    return (
        <>
            <ElapsedTimeWatcher />
            <SupremeMiniPlayer />
            <SupremeMaxiPlayer />
        </>
    );
}
