import { useRouter } from 'next/router';
import { useState } from 'react';
import { getCurrentPlatformLink, useApiErrorHandler } from '@bpm-web-app/utils';
import { apiKey } from '@bpm-web-app/api-client';
import styles from './account-dj-verification.module.css';
import Title from '../../title/title';
import { ApplicationHeader } from '../../pages/artist-portal/artist-application/artist-application-header/artist-application-header';
import { AccountDjVerificationForm, DJVerificationInputs } from './account-dj-verification-form';

export function AccountDJVerification() {
    const router = useRouter();
    const errorHandler = useApiErrorHandler();
    const { planId } = router.query;
    const [djVerificationData, setDJVerificationData] = useState<DJVerificationInputs>();

    async function submitHandler(formData: DJVerificationInputs) {
        const formPayload = new FormData();

        formPayload.append('354ba285-520a-11ea-81be-0a4af3221e5f', formData.name);

        formPayload.append('35828001-520a-11ea-81be-0a4af3221e5f', formData.email);

        formPayload.append('35cb1ef3-520a-11ea-81be-0a4af3221e5f', formData.link);

        formPayload.append('3594ba04-520a-11ea-81be-0a4af3221e5f', formData.additional_info);

        try {
            const key = apiKey();
            const headers = {} as { [key: string]: string };
            if (key) {
                // eslint-disable-next-line @typescript-eslint/dot-notation
                headers['Authorization'] = key;
            }
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const response = await fetch(`${process.env['NEXT_PUBLIC_SUPREME_API_BASE_PATH']}/dj-verification/application/submit`, {
                method: 'POST',
                credentials: 'include',
                body: formPayload,
                headers
            });
            if (!response.ok) {
                errorHandler({ error: response });
                throw new Error(response.statusText);
            } else {
                router.push(getCurrentPlatformLink(`/account/plan/change/${planId}?dj-verification=true`), getCurrentPlatformLink(`/account/plan/change/${planId}`));
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            errorHandler({ error });
        }
    }

    return (
        <div className={styles['account-dj-verification']}>
            <Title platform="download" title="DJ Verification" />
            <div className={styles['account-dj-verification__container']}>
                <ApplicationHeader noPadding title="Let’s complete your profile." redLogo subtitle="You’re two short steps away from downloading DJ-ready music. In order to start your subscription, we just need a little more information from you." />
                <AccountDjVerificationForm
                    djVerificationData={djVerificationData || undefined}
                    setHasCompletedDJVerification={(data) => {
                        if (data) {
                            submitHandler(data);
                            setDJVerificationData(data);
                        }
                    }}
                />
            </div>
        </div>
    );
}
