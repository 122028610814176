import { useCategories, useSearchGenres, } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FiltersContext } from '@bpm-web-app/components';
import { useSupremeFilterParams } from '@bpm-web-app/utils';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import styles from './genres-detail.module.css';
import { MusicView } from '../../music-view/music-view';
import { MusicViewType } from '../../music-view/music-view-props';
import { TagsView } from '../../tags-view/tags-view';
import { GhostComponent } from '../../shared';

export interface GenresDetailProps {
    category?: string | string[];
    genre?: string | string[];
}

export function GenresDetail({ category, genre }: GenresDetailProps) {
    const router = useRouter();

    const { type } = router.query;
    const { libraryProperty, setIsSwitcherVisible } = useContext(LibraryTabsContext);

    // Category or Genre
    const { categories, isLoading: isLoadingCategories } = useCategories(libraryProperty);
    const genreQuery = useMemo(() => {
        if (category) {
            return { library: libraryProperty, category };
        }
        return { library: libraryProperty };
    }, [category, libraryProperty]);

    const { genres, isLoading: isLoadingGenres } = useSearchGenres(genreQuery);

    const [pageTitle, setPageTitle] = useState('');

    const [isLoadingNewReleases, setIsLoadingNewReleases] = useState(true);
    const [isLoadingPlaylists, setIsLoadingPlaylists] = useState(true);
    const [isLoadingTrending, setIsLoadingTrending] = useState(true);
    const [isLoadingTrendingArtists, setIsLoadingTrendingArtists] = useState(true);
    const [isEverythingLoaded, setIsEverythingLoaded] = useState(false);

    useEffect(() => {
        if (
            !isLoadingCategories &&
            !isLoadingGenres &&
            !isLoadingNewReleases &&
            !isLoadingPlaylists &&
            !isLoadingTrending &&
            !isLoadingTrendingArtists
        ) setIsEverythingLoaded(true);
    }, [isLoadingCategories, isLoadingGenres, isLoadingNewReleases, isLoadingPlaylists, isLoadingTrending, isLoadingTrendingArtists]);

    useEffect(() => {
        const currentItem = category ? categories?.find(({ slug }) => slug === category) : genres?.find(({ slug }) => slug === genre);
        setPageTitle(currentItem?.name || pageTitle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories, category, genre, genres]);

    // Filters
    const [selectedGenres, setSelectedGenres] = useState(['All']);

    const {
        genres: genresFilterContext,
        setGenres: setGenresFilterContext
    } = useContext(FiltersContext);

    const currentGenres = useMemo(() => {
        if ((genres?.length || 0) < 2) {
            return [];
        }
        const allItem = 'All';

        const allGenres = [allItem];

        genres?.slice(0, 10).forEach((genreItem) => {
            allGenres.push(genreItem.name);
        });

        return allGenres;
    }, [genres]);

    useEffect(() => {
        const allSelectedGenresSlugs = genresFilterContext;

        const allSelectedGenresName: string[] = [];
        allSelectedGenresSlugs.forEach((selectedGenreSlug) => {
            genres?.forEach((genreItem) => {
                if (genreItem.slug === selectedGenreSlug) {
                    allSelectedGenresName.push(genreItem.name);
                }
            });
        });

        if (allSelectedGenresName.length > 0) setSelectedGenres(allSelectedGenresName);
    }, [genres, genresFilterContext]);

    useEffect(() => {
        setIsSwitcherVisible(false);

        return () => {
            setIsSwitcherVisible(true);
        };
    }, [setIsSwitcherVisible]);

    const selectGenreFilter = useCallback((tag: string, toggleOn: boolean) => {
        if (tag === 'All' && !selectedGenres.includes('All')) {
            setSelectedGenres(['All']);
            setGenresFilterContext([]);
        } else if (toggleOn) {
            const newSelectedGenres = selectedGenres;
            newSelectedGenres.push(tag);
            const updatedContext: string[] = [];
            selectedGenres.forEach((selectedGenre) => {
                genres?.forEach((genreItem) => {
                    if (genreItem.name === selectedGenre) {
                        updatedContext.push(genreItem.slug);
                    }
                });
            });
            setGenresFilterContext(updatedContext);
        } else {
            const updatedContext: string[] = [];
            selectedGenres.forEach((selectedGenre) => {
                genres?.forEach((genreItem) => {
                    if (genreItem.name === selectedGenre) {
                        if (selectedGenre !== tag) updatedContext.push(genreItem.slug);
                    }
                });
            });
            setSelectedGenres(selectedGenres.filter((v) => v !== tag));
            setGenresFilterContext(updatedContext);
        }
    }, [genres, selectedGenres, setGenresFilterContext]);

    const query = useSupremeFilterParams(true, category ? { category } : { genre });

    const renderMusic = useCallback((renderType?: MusicViewType) => {
        switch (renderType) {
            case 'tracks':
                return (
                    <MusicView title={`New ${pageTitle} Releases`} type={renderType} query={query} variant="fullscreen" />
                );

            case 'playlists':
                return (
                    <MusicView title={`${pageTitle} Playlists`} type={renderType} variant="fullscreen" query={query} />
                );
            case 'artists':
                return (
                    <MusicView title="Trending Artists" type={renderType} query={query} />
                );
            default:
                return (
                    <div className="spacing__window--top">
                        <div className={classNames(styles['genres-detail'], 'spacing__window--horizontal')}>
                            <div className={styles['genres-detail__title']}>
                                {isEverythingLoaded ? (
                                    <PrimaryPageTitle title={pageTitle} noPadding />
                                ) : (
                                    <GhostComponent type="custom" noPadding height={38} width={260} />
                                )}
                            </div>
                            {category !== undefined && currentGenres.length > 0 && isEverythingLoaded ? (
                                <TagsView large selected={selectedGenres} tags={currentGenres} onToggleTag={selectGenreFilter} orderBySelected={false} />
                            ) : null}
                            {category !== undefined && !isEverythingLoaded ? (
                                <GhostComponent type="tags" largeTags noPadding />
                            ) : null}
                            <div className={classNames(styles['genres-detail__divider'], {
                                [styles['genres-detail__divider--no-margin']]: category === undefined
                            })} />
                        </div>
                        <MusicView title="New Releases" type="tracks" query={{ ...query, limit: 10 }} isLoading={!isEverythingLoaded} setIsLoading={setIsLoadingNewReleases} />
                        <MusicView title={`Playlists Featuring ${pageTitle}`} type="playlists" query={query} isLoading={!isEverythingLoaded} setIsLoading={setIsLoadingPlaylists} />
                        <MusicView title={`Trending ${pageTitle}`} type="trending" query={query} isLoading={!isEverythingLoaded} setIsLoading={setIsLoadingTrending} />
                        <MusicView title="Trending Artists" type="artists" query={query} isLoading={!isEverythingLoaded} setIsLoading={setIsLoadingTrendingArtists} />
                    </div>

                );
        }
    }, [pageTitle, query, category, currentGenres, selectedGenres, selectGenreFilter, isEverythingLoaded]);

    return (
        <article>
            {renderMusic(type as MusicViewType)}
        </article>
    );
}

export default GenresDetail;
