import { ReactNode } from 'react';

import { TitleProps } from '../title/title';
import { PageLayout } from './page-layout';

export const DownloadPageLayout = ({ title, hasHubSwitch, children }: { title: TitleProps['title']; hasHubSwitch?: boolean; children: ReactNode }) => (
    <PageLayout platform="download" title={title} hasHubSwitch={hasHubSwitch}>
        {children}
    </PageLayout>
);
