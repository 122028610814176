import { useAlbumForCategory, useCategories, useMediaCategory } from '@bpm-web-app/swr-hooks';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { MediaWithAlbum, SortByQueryOptions } from '@bpm-web-app/stream-api-sdk';
import { DEFAULT_RELEASES_DROPDOWN_CATEGORY, useDebouncedCallback, useHubSwitch, useSupremeFilterParams } from '@bpm-web-app/utils';
import { Category, Album as DownloadAlbum } from '@bpm-web-app/download-api-sdk';
import classNames from 'classnames';
import { uniqBy } from 'lodash';
import styles from './new-releases-listing.module.css';
import { TrackListPreset } from '../../shared/track-list/track-list';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import Dropdown from '../../dropdown/dropdown';
import { AppLink } from '../../shared/app-link/app-link';
import Featured from '../../featured/featured';
import { allApiSortOptions } from '../../sort-options-sheet/sort-options-sheet';
import { BreakpointView, GhostComponent, PrimaryPageTitle } from '../../shared';
import SeeMore from '../../shared/see-more-button/see-more-btn';
import { FiltersContext } from '../../filters/filters.context';
import { TrackListSupreme } from '../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../shared/track-list/supreme/track-list-supreme-helpers';
import { TrackListLoadingSupreme } from '../../shared/track-list/ghost-loading/track-list-loading-supreme';

type StreamCategory = {
    [key: string]: MediaWithAlbum[];
};
type DownloadCategory = {
    [key: string]: DownloadAlbum[];
};

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface NewReleasesProps {}

export function NewReleasesListing(props: NewReleasesProps) {
    const [sortType, setSortType] = useState<SortByQueryOptions>(SortByQueryOptions.DateDesc);
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { isDownload } = useHubSwitch();

    const [activeCategory, setActiveCategory] = useState<string>(DEFAULT_RELEASES_DROPDOWN_CATEGORY.value);
    const router = useRouter();
    const [activeFilterCategory, setActiveFilterCategory] = useState<string>();
    const library = isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream;

    const query = useSupremeFilterParams(true);

    const { data: streamData, isLoading: isLoadingStreamData } = useMediaCategory(query, !isDownload);

    const { data: downloadData, isLoading: isLoadingDownloadData, mutate: mutateDownloadData } = useAlbumForCategory(query, isDownload);

    const data = useMemo(() => (isDownload ? downloadData : streamData), [downloadData, isDownload, streamData]);

    const { categories: allCategories } = useCategories(libraryProperty);

    const filtersContext = useContext(FiltersContext);

    const categoriesList = useMemo(() => {
        const newCategories: StreamCategory | DownloadCategory = {};

        if (data?.data) {
            data.data.forEach((media) => {
                const { name } = !isDownload ? (media as MediaWithAlbum).album.category : (media as DownloadAlbum).category;

                if (newCategories[name]) {
                    newCategories[name].push(media as any);
                } else {
                    newCategories[name] = [media as any];
                }
            });
        }
        return newCategories;
    }, [isDownload, data]);

    const categories = useMemo(() => {
        const newCategories: Category[] = [];

        if (data?.data) {
            data.data.forEach((media) => {
                const category = !isDownload ? (media as MediaWithAlbum).album?.category : (media as DownloadAlbum).category;

                newCategories.push(category!);
            });
        }
        return uniqBy(newCategories, ({ id }) => id);
    }, [isDownload, data]);

    const displayedCategories = useMemo(
        () => (activeCategory ? { [activeCategory]: categoriesList[activeCategory]?.length ? [...categoriesList[activeCategory]] : [] } : categoriesList),
        [activeCategory, categoriesList]
    );

    const isLoading = useMemo(() => (isDownload ? isLoadingDownloadData : isLoadingStreamData), [isDownload, isLoadingDownloadData, isLoadingStreamData]);

    const getCategorySlugByName = useCallback((categoryName: string) => encodeURIComponent(allCategories?.find((category) => category.name === categoryName)?.slug || ''), [allCategories]);
    const [isFeaturedLoaded, setIsFeaturedLoaded] = useState(false);

    const isEverythingLoaded = useMemo(() => {
        if (isFeaturedLoaded && !isLoading) return true;
        return false;
    }, [isFeaturedLoaded, isLoading]);

    useEffect(() => {
        setActiveCategory(DEFAULT_RELEASES_DROPDOWN_CATEGORY.value);
        filtersContext.resetFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [libraryProperty]);

    const handleSetSort = (selectedSortType: SortByQueryOptions, category: string) => {
        setSortType(selectedSortType);
        router.replace({ pathname: '/d/releases', query: { ...router.query, category: getCategorySlugByName(category), sortBy: selectedSortType } });
    };

    const handleSelectedFilter = useCallback(
        (category: string) => {
            setActiveFilterCategory(getCategorySlugByName(category));
        },
        [getCategorySlugByName]
    );

    const updateSearchParams = useCallback(() => {
        router.replace({
            pathname: '/d/releases',
            query: {
                category: activeFilterCategory || activeCategory,
                genre: filtersContext.genres,
                bpm: filtersContext.bpm,
                key: filtersContext.key,
                hideExclusives: filtersContext.hideExclusives,
                hideExplicit: filtersContext.hideExplicit,
                hidePrevDownloaded: filtersContext.hidePrevDownloaded,
                hideRemixes: filtersContext.hideRemixes,
            },
        });
    }, [activeCategory, activeFilterCategory, filtersContext, router]);

    const updateSearchParamsDebounced = useDebouncedCallback(updateSearchParams, 500);

    useEffect(() => {
        if (activeFilterCategory && filtersContext) {
            updateSearchParamsDebounced();
        }
    }, [activeCategory, activeFilterCategory, filtersContext, filtersContext.activeFilters, query, router, updateSearchParamsDebounced]);

    const renderDropdown = () => (
        <Dropdown<string>
            layoutType="border-input"
            isDefaultWidth
            onClick={(option) => {
                if (option) {
                    router.push({
                        pathname: router.pathname,
                        query: {
                            ...router.query,
                            category: option,
                        },
                    });
                } else {
                    router.push({
                        pathname: router.pathname,
                    });
                }
            }}
            value={activeCategory}
            options={[
                DEFAULT_RELEASES_DROPDOWN_CATEGORY,
                ...(categories || []).map(({ name, slug }) => ({
                    label: name,
                    value: slug,
                })),
            ]}
        />
    );

    return (
        <div className={styles['new-releases']}>
            <div className={styles['new-releases__intro']}>
                <PrimaryPageTitle title="New Releases" noPadding />
                <BreakpointView mobileChildren={null} desktopChildren={isEverythingLoaded ? renderDropdown() : null} />
            </div>
            <BreakpointView mobileChildren={<div className="spacing__window--horizontal">{renderDropdown()}</div>} desktopChildren={null} />
            {!activeCategory && <Featured isDataLoaded={isEverythingLoaded} setIsDataLoaded={setIsFeaturedLoaded} />}
            <div className={classNames('spacing__window--horizontal')}>
                {isEverythingLoaded ? (
                    Object.keys(displayedCategories).map((category) => (
                        <div className={styles['new-releases__category-block']} key={category}>
                            <AppLink href={{ pathname: '/releases', query: { ...router.query, category: getCategorySlugByName(category) } }}>
                                <a className="underline-link">
                                    <h2 className={styles['new-releases__subtitle']}>{category}</h2>
                                </a>
                            </AppLink>
                            <div>
                                <TrackListSupreme
                                    isSortable
                                    hasFilters
                                    hasColumnSorting
                                    preset={library}
                                    list={categoriesList[category]}
                                    isLoading={!isEverythingLoaded}
                                    onSort={(nextSort) => handleSetSort(nextSort as SortByQueryOptions, category)}
                                    sortOptions={allApiSortOptions}
                                    selectedSortType={sortType}
                                    onDownloadRevalidate={(downloadMedia) => {
                                        if (downloadMedia) {
                                            // eslint-disable-next-line no-param-reassign
                                            downloadMedia.download_count += 1;
                                            mutateDownloadData({ data: downloadData!.data }, false);
                                        }
                                    }}
                                    onFiltersSelected={() => handleSelectedFilter(category)}
                                    onFilterApplied={(filters) => {
                                        if (filters.bpm) {
                                            router.replace({
                                                pathname: router.pathname,
                                                query: {
                                                    ...router.query,
                                                    bpm: [filters.bpm, filters.bpm],
                                                },
                                            });
                                        }
                                        if (filters.genre) {
                                            router.replace({
                                                pathname: router.pathname,
                                                query: {
                                                    ...router.query,
                                                    genre: [filters.genre],
                                                },
                                            });
                                        }
                                        if (filters.key) {
                                            router.replace({
                                                pathname: router.pathname,
                                                query: {
                                                    ...router.query,
                                                    key: [filters.key],
                                                },
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <AppLink href={{ pathname: '/releases', query: { ...router.query, category: getCategorySlugByName(category) } }}>
                                <SeeMore expand={false} variant="text" />
                            </AppLink>
                        </div>
                    ))
                ) : (
                    <>
                        <div className={styles['new-releases__category-block']}>
                            <GhostComponent type="secondary-title" noPadding />
                            <div className="spacing--top-double" />
                            <GhostComponent type="custom" noPadding height={25} />
                            <div className="spacing--top" />
                            <TrackListLoadingSupreme amount={10} preset={TrackListPresetSupreme.Download} />
                        </div>
                        <div className={styles['new-releases__category-block']}>
                            <GhostComponent type="secondary-title" noPadding />
                            <div className="spacing--top-double" />
                            <GhostComponent type="custom" noPadding height={25} />
                            <div className="spacing--top" />
                            <TrackListLoadingSupreme amount={10} preset={TrackListPresetSupreme.Download} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default NewReleasesListing;
