import { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { getDownloadedSounds, useGetDownloadedSoundPackages, useUserDownloadHistory } from '@bpm-web-app/swr-hooks';
import { DEFAULT_SOUNDS_LIMIT, getSignupRedirectLinkAccountPlans, useDebounce, useUserSettings } from '@bpm-web-app/utils';
import { SearchInput } from '@bpm-web-app/components/src/lib/shared/search-input/search-input';
import router from 'next/router';
import Table from './table/table';
import { HorizontalTabs } from '../../shared/ui/horizontal-tabs/horizontal-tabs';
import { PackItemList } from '../../pack-item-list/pack-item-list';
import { AccountsDownloadsCreate } from './account-downloads.create';

import styles from './account-downloads.module.css';

export interface AccountDownloadsProps {
    isCreate?: boolean;
}
export function AccountDownloads({ isCreate = false }: AccountDownloadsProps) {
    const pageSize = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const debouncedSearchQuery = useDebounce(searchQuery, 300);

    const [checkedMediaIds, setCheckedMediaIds] = useState<(string | number)[]>([]);

    const [mainState, setMainState] = useState({
        activePage: 0,
        searchQuery: '',
    });

    const { data: dataSupreme, mutate: mutateSupreme } = useUserDownloadHistory(
        {
            limit: pageSize,
            skip: mainState.activePage * pageSize,
            term: mainState.searchQuery,
        },
        !isCreate
    );

    const { data: soundPackages } = useGetDownloadedSoundPackages(
        {
            limit: DEFAULT_SOUNDS_LIMIT,
        },
        isCreate
    );

    const { data: dataCreate } = getDownloadedSounds(
        {
            limit: pageSize,
            skip: mainState.activePage * pageSize,
            search: mainState.searchQuery,
        },
        isCreate
    );

    const { isAnonymous } = useUserSettings();

    useEffect(() => {
        if (isAnonymous) {
            router.replace(getSignupRedirectLinkAccountPlans());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);
    /* on any search query change, reset the active page and checked media */
    useEffect(() => {
        setMainState({
            activePage: 0,
            searchQuery: debouncedSearchQuery,
        });
        setCheckedMediaIds([]);
    }, [debouncedSearchQuery]);

    const { data: mediaList, pagination } = (isCreate ? dataCreate : dataSupreme) || {};

    const onPageChange = useCallback(
        (page: number) => {
            setMainState({
                ...mainState,
                activePage: page,
            });
        },
        [mainState]
    );

    const mediaListTable = useMemo(
        () => (
            <Table
                isCreate={isCreate}
                mediaList={mediaList}
                pagination={pagination}
                mutateSupreme={mutateSupreme}
                checkedMediaIds={checkedMediaIds}
                setCheckedMediaIds={setCheckedMediaIds}
                mainState={mainState}
                onPageChange={onPageChange}
                pageSize={pageSize}
                dataSupreme={dataSupreme}
            />
        ),
        [checkedMediaIds, dataSupreme, isCreate, mainState, mediaList, mutateSupreme, onPageChange, pagination]
    );

    const mobileTabs = useMemo<ComponentProps<typeof HorizontalTabs>['tabs']>(
        () => [
            {
                id: 'packs',
                title: 'Packs',
                count: soundPackages?.data?.length ?? 0,
                activeTabComponent: <PackItemList data={soundPackages?.data} />,
            },
            {
                id: 'Songs',
                title: 'Songs',
                count: mediaList?.length ?? 0,
                activeTabComponent: mediaListTable,
            },
        ],
        [mediaList?.length, soundPackages?.data, mediaListTable]
    );

    return (
        <div className={styles['account-downloads']}>
            <div className={styles['account-downloads__top-section']}>
                <h2 className={styles['account-downloads__desktop-section-title']}>Downloads</h2>
                <SearchInput
                    isAlternateMobileLayout
                    placeholder="Search Downloads"
                    value={searchQuery}
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                    }}
                    onClear={() => setSearchQuery('')}
                />
            </div>

            {isCreate ? <AccountsDownloadsCreate mobileTabs={mobileTabs} listComponent={mediaListTable} /> : mediaListTable}
        </div>
    );
}

export default AccountDownloads;
