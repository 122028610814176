import { useSearchAlbum } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import { SortByQueryOptions } from '@bpm-web-app/stream-api-sdk';
import { useHubSwitch, useUniqueArray } from '@bpm-web-app/utils';
import { useEffect, useMemo } from 'react';
import styles from './search-albums-carousel.module.css';
import CardCarousel from '../../shared/card-carousel/card-carousel';
import { SecondaryPageTitle } from '../../shared/secondary-page-title/secondary-page-title';
import GhostElement from '../../shared/ghost-element/ghost-element';
import { NoResultsBlock } from '../../shared/ui/no-results-block/no-results-block';
import { AppLink } from '../../shared/app-link/app-link';
import { AlbumCard } from '../../shared/card/album-card/album-card';

interface SearchAlbumsCarouselProps {
    onCount: (count: number) => void;
    hasNoResults?: boolean;
}

export function SearchAlbumsCarousel({ onCount, hasNoResults }: SearchAlbumsCarouselProps) {
    const router = useRouter();
    const { isDownload } = useHubSwitch();
    const query = {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        term: router.query['searchTerm'] && router.query['searchTerm'].toString(),
        sort_by: SortByQueryOptions.DateDesc,
    };
    const { data, isLoading } = useSearchAlbum(query);
    const searchAlbums = data?.data;
    const pagination = data?.pagination;

    const seeMorePath = useMemo(
        // eslint-disable-next-line @typescript-eslint/dot-notation
        () => `/search?searchTerm=${router.query['searchTerm']}&type=albums`,
        [router.query]
    );

    const ghostLoadingItems = useUniqueArray(20);

    useEffect(() => {
        onCount(pagination?.total || 0);
    }, [onCount, pagination?.total]);

    if (isLoading || !searchAlbums) {
        return (
            <div className="ghost-loading__loading-container">
                <AppLink href={seeMorePath}>
                    <a className="heading--h6">
                        <SecondaryPageTitle title="Albums" />
                    </a>
                </AppLink>

                <div className={styles['search-albums-carousel__loading']}>
                    {ghostLoadingItems.map((uuid) => (
                        <GhostElement key={`search-albums-carousel-${uuid}`} itemClass={styles['search-albums-carousel__loading-item']} />
                    ))}
                </div>
            </div>
        );
    }

    if (hasNoResults) {
        return null;
    }

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={styles['search-albums-carousel']}>
            {searchAlbums.length > 0 ? (
                <CardCarousel title="Albums" titleCounter={`${pagination?.total || 0}`} seeMorePath={seeMorePath}>
                    {searchAlbums.map((album) => (
                        <AlbumCard
                            key={album.id}
                            isDownload={isDownload}
                            album={album}
                            albumsList={searchAlbums}
                        />
                    ))}
                </CardCarousel>
            ) : (
                <div className={styles['search-albums-carousel__no-results-section']}>
                    <SecondaryPageTitle title="Albums" counter="0" />
                    <NoResultsBlock hasPadding>No Albums Available</NoResultsBlock>
                </div>
            )}
        </div>
    );
}

export default SearchAlbumsCarousel;
