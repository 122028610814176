import { getFavoriteAlbums } from '@bpm-web-app/swr-hooks';
import { Album } from '@bpm-web-app/download-api-sdk';
import CardGrid from '../shared/card-grid/card-grid';
import styles from './favorite-albums.module.css';
import { AlbumCard } from '../shared/card/album-card/album-card';

export function FavoriteAlbums() {
    const { data, isLoading } = getFavoriteAlbums();

    // @TODO LOADING ANIMATION
    if (isLoading) {
        return null;
    }
    return (
        <div className={styles['favorite-albums']}>
            <h3>Favorite Albums</h3>

            <CardGrid cardType="card-small">
                {data?.data?.map((item) => (
                    <AlbumCard
                        key={item.id}
                        album={item as Album}
                        albumsList={data.data as Album[]} />
                ))}
            </CardGrid>
        </div>
    );
}
