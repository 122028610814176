import { useLibraryTabs } from '@bpm-web-app/components';
import { useDownloadAlbumById, useMedia, useSimilarAlbum, useSimilarMedia } from '@bpm-web-app/swr-hooks';
import { useHubSwitch } from '@bpm-web-app/utils';
import { useMemo } from 'react';
import { TrackListSupreme } from '../../shared/track-list/supreme/track-list-supreme';
import Title from '../../title/title';
import { PageNotFound } from '../page-not-found/page-not-found';
import styles from './similar-media-detail-supreme.module.css';
import { TrackListPresetSupreme } from '../../shared/track-list/supreme/track-list-supreme-helpers';
import { TrackListLoadingSupreme } from '../../shared/track-list/ghost-loading/track-list-loading-supreme';

export interface SimilarMediaDetailSupremeProps {
    mediaId: number;
}

export function SimilarMediaDetailSupreme({ mediaId: id }: SimilarMediaDetailSupremeProps) {
    const { isDownload, hub } = useHubSwitch();
    const { libraryProperty } = useLibraryTabs();
    const { media, isLoading: isLoadingMedia, error: mediaError } = useMedia(id, isDownload);
    const { data: album, isLoading: isLoadingAlbum, error: albumError } = useDownloadAlbumById(id, isDownload);

    const { data: similarMedia, isLoading: isLoadingSimilarMedia, error: similarMediaError } = useSimilarMedia(id, !isDownload);

    const { data: similarAlbums, isLoading: isLoadingSimilarAlbum, error: similarAlbumError, mutate: mutateSimilarAlbums } = useSimilarAlbum(id, libraryProperty, isDownload);

    const isLoading = useMemo(
        () => (isDownload ? isLoadingAlbum || isLoadingSimilarAlbum : isLoadingMedia || isLoadingSimilarMedia),
        [isDownload, isLoadingAlbum, isLoadingMedia, isLoadingSimilarAlbum, isLoadingSimilarMedia]
    );

    const error = useMemo(() => (isDownload ? albumError || similarAlbumError : mediaError || similarMediaError), [albumError, isDownload, mediaError, similarAlbumError, similarMediaError]);

    const mediaName = useMemo(() => (isDownload ? album?.data?.name : media?.data?.name), [album?.data?.name, isDownload, media?.data?.name]);

    const similarMediaDetail = isDownload ? similarAlbums?.data : similarMedia?.data;

    if (id === undefined || error) return <PageNotFound />;

    return (
        <>
            <Title platform={hub} title="Similar Media" />
            <div className="spacing__window">
                {mediaName && <div className={styles['similar-media-detail__title']}>Similar Tracks to {mediaName}</div>}
                {similarMediaDetail && !isLoading ? (
                    <TrackListSupreme
                        list={similarMediaDetail}
                        preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                        isSortable={false}
                        onDownloadRevalidate={(downloadMedia) => {
                            if (downloadMedia) {
                                // eslint-disable-next-line no-param-reassign
                                downloadMedia.download_count += 1;
                                mutateSimilarAlbums(similarAlbums, { revalidate: false });
                            }
                        }}
                    />
                ) : (
                    <div className={styles['similar-media-detail__loading']}>
                        <TrackListLoadingSupreme preset={TrackListPresetSupreme.Download} amount={20} />
                    </div>
                )}
            </div>
        </>
    );
}

export default SimilarMediaDetailSupreme;
