import { FeaturedQuery, Media } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';
import useSWR from 'swr';

type FeaturedResponse = Awaited<ReturnType<typeof Media.getFeatured>>;

export function useFeatured(query: FeaturedQuery) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR<FeaturedResponse>(
        [
            'get-featured',
            {
                persist: true,
                isDownload,
                ...query,
            },
        ],
        () => Media.getFeatured(isDownload, query)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}
