import { Album as DownloadAlbum } from '@bpm-web-app/download-api-sdk';
import { useFeatured } from '@bpm-web-app/swr-hooks';
import { useHubSwitch } from '@bpm-web-app/utils';
import { useContext, useState } from 'react';

import { MediaWithAlbum, SortByQueryOptions } from '@bpm-web-app/stream-api-sdk';
import { useRouter } from 'next/router';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import styles from './featured-all.module.css';
// eslint-disable-next-line import/no-named-as-default-member
import SeeMoreMobile from '../../see-more-mobile/see-more-mobile';

import SeeMoreSupremeLayout from '../../see-more-layout/see-more-supreme-layout';
import { TrackListSupreme } from '../../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../../shared/track-list/supreme/track-list-supreme-helpers';
import { allApiSortOptions } from '../../sort-options-sheet/sort-options-sheet';

export function FeaturedAll() {
    const [sortType, setSortType] = useState<SortByQueryOptions>(SortByQueryOptions.DateDesc);
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { data, isLoading } = useFeatured({ library: libraryProperty, sort_by: sortType });
    const { isDownload } = useHubSwitch();
    const router = useRouter();

    const featured = data?.data;

    const downloadAlbumsList = featured as DownloadAlbum[];
    const streamMediaList = featured?.slice(0, 100) as MediaWithAlbum[];

    return (
        <div className={styles['featured-all']}>
            <SeeMoreSupremeLayout
                pageTitle="New Music"
                mobileChildren={<SeeMoreMobile data={downloadAlbumsList ?? streamMediaList} isDownload={isDownload} contentType={isDownload ? 'download:album' : 'track'} isLoading={isLoading} />}
                desktopChildren={
                    !isDownload ? (
                        <TrackListSupreme
                            isSortable
                            hasColumnSorting
                            onSort={(nextSort) => setSortType(nextSort as SortByQueryOptions)}
                            sortOptions={allApiSortOptions}
                            selectedSortType={sortType}
                            preset={TrackListPresetSupreme.Stream}
                            list={streamMediaList}
                            onFilterApplied={(filters) => {
                                if (filters.bpm) {
                                    router.replace({
                                        pathname: router.pathname,
                                        query: {
                                            ...router.query,
                                            bpm: [filters.bpm, filters.bpm]
                                        },
                                    });
                                }
                                if (filters.genre) {
                                    router.replace({
                                        pathname: router.pathname,
                                        query: {
                                            ...router.query,
                                            genre: [filters.genre]
                                        },
                                    });
                                }
                                if (filters.key) {
                                    router.replace({
                                        pathname: router.pathname,
                                        query: {
                                            ...router.query,
                                            key: [filters.key]
                                        },
                                    });
                                }
                            }}
                        />
                    ) : (
                        <TrackListSupreme
                            isSortable
                            hasColumnSorting
                            onSort={(nextSort) => setSortType(nextSort as SortByQueryOptions)}
                            sortOptions={allApiSortOptions}
                            selectedSortType={sortType}
                            preset={TrackListPresetSupreme.Download}
                            list={downloadAlbumsList}
                            onFilterApplied={(filters) => {
                                if (filters.bpm) {
                                    router.replace({
                                        pathname: router.pathname,
                                        query: {
                                            ...router.query,
                                            bpm: [filters.bpm, filters.bpm]
                                        },
                                    });
                                }
                                if (filters.genre) {
                                    router.replace({
                                        pathname: router.pathname,
                                        query: {
                                            ...router.query,
                                            genre: [filters.genre]
                                        },
                                    });
                                }
                                if (filters.key) {
                                    router.replace({
                                        pathname: router.pathname,
                                        query: {
                                            ...router.query,
                                            key: [filters.key]
                                        },
                                    });
                                }
                            }}
                        />
                    )
                }
            />
        </div>
    );
}

export default FeaturedAll;
