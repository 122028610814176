import { DownloadSection } from '@bpm-web-app/api-client';
import useSWR from 'swr';

export function useGetSectionsDownload(location: string, library: string) {
    const { data, error } = useSWR(
        [
            'download-sections',
            {
                persist: true,
                location,
                library
            },
        ],
        () => DownloadSection.getSections(location, library)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}
